.createShipment {
  min-height: 100vh;
  background-color: var(--background);

  .createShipment-stepper {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
  }

  .createShipment-wrapper {
    .create-shipment-stepper-buttons {
      max-width: 1400px;
      width: 100%;
      margin: var(--gap2x) auto;
    }

    .shipmentInfo {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      background-color: var(--lighterBg);
      padding: 20px;
      border-radius: var(--inputBorderRadius);

      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      .shipmentInfo-form-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--gap2x);

        .form-document-info {
          border: 1px solid var(--info);
          border-radius: 5px;
          padding: 7px 10px;
          margin-top: 5px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: var(--info);
          gap: 5px;

          .form-document-info-text {
            color: var(--info);
            font-size: 12px;
          }

          .form-document-info-detail {
            font-weight: 700;
            color: var(--error);
            font-size: 12px;
            margin-left: 3px;
            cursor: pointer;

            &:hover {
              color: var(--info);
              transition: all 150ms linear;
            }
          }
        }

        .shipmentInfo-form {
          display: flex;
          //justify-content: center;
          gap: var(--gap2x);

          .form-document-button {
            background-color: var(--info);
            color: white;
            transition: all 150ms linear;

            &:hover {
              background-color: var(--blueBrand);
              transition: all 150ms linear;
            }
          }
        }
      }
    }

    .shipmentDimension {
      background: var(--lighterBg);
      padding: 20px;
      border-radius: 10px;

      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      .shipmentDimension-form {
        margin-top: var(--gap3x);

        .form-dimensions {
          display: grid;
          gap: var(--gap2x);
          margin-top: var(--gap3x);

          .form-dimensions-wrapper {
            display: grid;
            gap: var(--gap2x);

            .form-dimensions-contain {
              display: grid;
              gap: var(--gap2x);

              .dimension-delete {
                width: fit-content;
                background: var(--pastelRed);
                width: 33px;
                height: 33px;
                border: none;
                outline: none;
                color: var(--shipmentRed);
                cursor: pointer;
                transition: all 150ms linear;

                &:hover {
                  color: var(--notRed);
                  transition: all 150ms linear;
                }
              }

              .form-dimensions-content-container {
                display: flex;
                flex-direction: column;
                gap: var(--gap2x);
                padding: 20px;
                box-shadow: 0 0 7px #e0dfde;
                border-radius: 10px;

                .form-dimensions-content {
                  .form-dimensions-content-header {
                    display: flex;
                    justify-content: space-between;
                    gap: var(--gap2x);
                  }
                }

                .form-dimensions-container {
                  display: grid;
                  grid-template-columns: repeat(6, minmax(160px, 1fr));
                  gap: var(--gap);

                  .dimension-loading {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    span {
                      width: 21px !important;
                      height: 21px !important;
                    }
                  }
                }
              }

              .form-dimensions-weight {
                display: flex;
                gap: var(--gap);
                div {
                  width: 100%;
                  border: 1px solid var(--grayText);
                  border-radius: var(--buttonBorderRadius);
                  padding: 5px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  span {
                    font-size: 10px;
                    color: var(--grayText);
                  }
                }
              }

              .form-add-dimension-button-wrapper {
                display: flex;
                width: 100%;
                justify-content: center;

                .add-dimension-button {
                  cursor: pointer;
                  color: var(--info);
                  font-size: 30px;
                  opacity: 1;
                  border: none;
                  background-color: transparent;
                  outline: none;

                  &:disabled {
                    opacity: 0.2;
                    cursor: auto;
                  }
                }
              }

              .total-weight {
                display: grid;
                gap: var(--gap);
                grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

                div {
                  border: 1px solid var(--grayText);
                  border-radius: var(--buttonBorderRadius);
                  padding: 5px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  span {
                    font-size: 10px;
                    color: var(--grayText);
                  }
                }
              }
            }

            .dimension-wrapper-button-container {
              display: flex;
              justify-content: flex-end;
              width: 100%;
              gap: var(--gap);

              .button-container-cw {
                padding: 10px;
                box-shadow: var(--shadow);
                border-radius: var(--inputBorderRadius);
                min-width: 250px;

                .cw-headline {
                  font-size: inherit;
                  font-weight: 700;
                  color: var(--btnBgBlue);
                }

                .cw-amount {
                  font-size: inherit;
                  font-weight: 700;
                  color: var(--priceText);
                }
              }
            }

            @media (max-width: 500px) {
              .dimension-wrapper-button-container {
                flex-direction: column;
                justify-content: baseline;
              }
            }
          }
        }
      }
    }

    .shipmentPrice {
      margin-top: var(--gap3x);
      display: flex;
      flex-direction: column;
      gap: var(--gap3x);

      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      .shipmentPrice-info {
        background-color: var(--pastelBlue);
        padding: 20px;
        border-radius: var(--wrapperBorderRadius);
        box-shadow: var(--shadow);
        color: var(--btnBgBlue);
        display: flex;
        align-items: center;
        gap: var(--gap);
      }

      .shipmentPrice-exchange-rate {
        display: flex;
        justify-content: flex-end;
      }

      .shipmentPrice-header {
        display: flex;
        align-items: center;
        gap: var(--gap2x);
        justify-content: center;
        padding: 20px 20px 40px;
        border-radius: var(--inputBorderRadius);
        background-color: var(--lighterBg);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

        .shipmentPrice-header-sides-wrapper {
          display: flex;
          align-items: center;
          gap: var(--gap);

          .shipmentPrice-header-sides {
            display: grid;
            justify-items: center;
            gap: 5px;

            .shipmentPrice-header-sides-top {
              font-size: 12px;
              font-weight: 700;
              color: var(--priceText);
            }

            .shipmentPrice-header-sides-bottom {
              font-size: var(--smallTextSize);
              font-weight: 700;
              color: var(--priceText);
            }
          }
        }

        .shipmentPrice-header-connection {
          display: grid;
          justify-items: center;
          gap: 15px;
          align-self: flex-end;

          .shipmentPrice-header-connection-gross {
            background-color: rgba(106, 112, 126, 0.5);
            border-radius: 5px;
            color: var(--lighterBg);
            padding: 4px 6px;
            font-size: var(--smallTextSize);
          }
        }
      }

      .shipmentPrice-get {
        background-color: var(--lighterBg);
        padding: 20px;
        border-radius: var(--inputBorderRadius);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

        .supplier-id-warning {
          display: flex;
          gap: 5px;
          align-items: center;
          margin-top: 20px;
          background: var(--notRed);
          padding: 10px;
          border-radius: var(--inputBorderRadius);
          box-shadow: 0 0 5px var(--background);
          color: var(--toastify-color-light);
          font-size: 13px;
        }

        .shipmentPrice-get-wrapper {
          display: grid;
          gap: var(--gap5x);
          grid-template-columns: repeat(4, 1fr);
          align-items: center;
          justify-items: center;
          max-width: 1100px;
          width: 100%;
          height: 100%;
          margin: 0 auto;

          &.shipmentPriceCalculation-get-wrapper {
            grid-template-columns: repeat(3, 1fr);
          }

          .shipmentPrice-get-logo {
            display: flex;
            //flex-direction: column;
            align-items: center;
            gap: var(--gap);
            width: 100%;

            .get-logo {
              width: 80px;
            }

            .get-logo-warning {
              display: flex;
              align-items: center;
              background-color: var(--pastelBlue);
              padding: 5px;
              font-size: 10px;
              border-radius: var(--buttonBorderRadius);
              width: 100%;
              gap: var(--gap);
              box-shadow: var(--shadow);

              .logo-warning {
                width: 18px;
                color: var(--info);
              }
            }
          }

          .shipmentPrice-get-delivery {
            display: flex;
            flex-direction: column;
            gap: var(--gap2x);
            width: 100%;

            .get-delivery-status {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: var(--gap4x);

              .delivery-time-wrapper {
                display: grid;
                gap: var(--gap);
                font-size: 10px;
                text-align: center;
                color: var(--priceText);

                .delivery-time {
                  width: 130px;
                  height: 13px;
                  background: linear-gradient(
                    90deg,
                    #ef5400 0%,
                    #fc9900 22.94%,
                    #d6c00a 48.79%,
                    #02b81f 99.27%
                  );
                  border: 1px solid #e9ecef;
                  border-radius: 20px;
                }
              }

              .delivery-status {
                font-size: var(--largeTextSize);
                color: var(--priceText);
                font-weight: 700;
              }
            }

            .get-delivery-return {
              border-radius: 5px;
              padding: 5px;
              text-align: center;
              font-size: 11px;
              color: var(--priceText);
              font-weight: 500;

              span {
                color: var(--priceText);
                font-weight: 700;
                font-size: 11px;
              }
            }
          }

          .shipmentPrice-get-price {
            display: flex;
            align-items: center;
            gap: var(--gap2x);

            .get-price-currency {
              display: grid;
              gap: var(--gap);

              .price-foreign-currency {
                background: var(--shipmentYellow);
                border-radius: var(--inputBorderRadius);
                padding: 5px 10px;
                text-align: center;
                color: var(--lighterBg);
                min-width: 100px;
              }

              .price-local {
                background: var(--background);
                border-radius: var(--inputBorderRadius);
                padding: 5px 10px;
                color: var(--priceText);
                min-width: 100px;
              }
            }

            button {
              background-color: var(--info);
              color: var(--lighterBg);
              border: none;
              outline: none;
              height: fit-content;
              width: fit-content;
              padding: 7px 15px;
              border-radius: var(--buttonBorderRadius);
              cursor: pointer;
              transition: all 150ms linear;

              &:hover {
                background-color: var(--blueBrand);
                transition: all 150ms linear;
              }
            }
          }

          .shipmentPrice-get-insurance {
            display: grid;
            justify-items: start;
            width: 100%;

            label > span {
              font-size: 14px;
              font-weight: 500;
              color: var(--priceText);
            }
          }
        }
      }

      .shipmentPrice-get-card {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
        gap: var(--gap2x);
        margin-bottom: var(--gap2x);

        .get-card-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: var(--lighterBg);
          box-shadow: var(--shadow);
          padding: 10px;
          border-radius: var(--inputBorderRadius);
          transition: all 150ms linear;

          &.selected {
            box-shadow: var(--blueShadow);
          }

          &:hover {
            transition: all 150ms linear;
            box-shadow: var(--blueShadow);
          }

          .get-card {
            display: grid;
            align-content: space-between;
            color: var(--priceText);
            gap: var(--gap);

            .get-card-header {
              display: flex;
              flex-direction: column;
              gap: 5px;

              .get-card-header-headline {
                display: flex;
                align-items: center;
                gap: var(--gap);
                font-weight: 600;
              }
              .get-card-header-subtext {
                display: flex;
                align-items: center;
                font-size: 13px;
                font-weight: 500;

                span {
                  font-weight: 700;
                }
              }
            }

            .get-card-main {
              .get-card-main-price-wrapper {
                display: flex;
                flex-direction: column;
                gap: var(--gap);

                .get-card-main-price {
                  color: var(--info);

                  .main-price-out {
                    font-size: 36px;
                    font-weight: 500;
                  }

                  .main-price-in {
                    font-size: inherit;
                    color: var(--priceText);
                  }
                }
              }

              .shipmentPrice-get-insurance {
                display: flex;
                flex-direction: column;
                padding: 10px 0 10px 10px;

                label {
                  span {
                    padding: 3px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .shipmentAddress {
      background-color: var(--lighterBg);
      padding: 20px;
      border-radius: var(--inputBorderRadius);

      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      .new-address-adder-wrapper {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: var(--gap);

        .new-address-adder {
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          color: var(--adderBtn);
          display: flex;
          align-items: center;
          justify-self: flex-end;
          gap: 10px;
          font-weight: 700;
        }
      }

      .shipmentAddress-list {
        display: grid;
        gap: var(--gap4x);

        .shipmentAddress-list-board {
          display: grid;
          gap: var(--gap);

          .list-board-items {
            display: grid;
            gap: var(--gap2x);
            grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

            .list-board-item {
              border: 1px solid var(--priceText);
              border-radius: var(--inputBorderRadius);
              color: var(--priceText);
              padding: var(--padding) 30px var(--padding) var(--padding);
              position: relative;

              div {
                font-size: 12px;
              }

              .item-select {
                position: absolute;
                top: 0;
                right: 0;
                padding: 5px;
              }

              .item-delete {
                position: absolute;
                bottom: 5px;
                right: 5px;
                color: var(--notRed);
                transition: all 150ms linear;
                cursor: pointer;

                &:hover {
                  color: var(--error);
                  transition: all 150ms linear;
                }
              }
            }
          }
        }
      }
    }

    .shipmentProforma {
      display: grid;
      width: 100%;
      gap: var(--gap2x);
      background-color: var(--lighterBg);
      padding: 20px;
      border-radius: var(--inputBorderRadius);

      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      .proforma-number {
        display: grid;
        gap: var(--gap);
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }

      .proforma-info {
        display: flex;
        align-items: flex-start;
        width: 100%;
        border: 1px solid var(--pastelBlue);
        background-color: var(--pastelBlue);
        padding: var(--padding);
        border-radius: var(--buttonBorderRadius);
        box-shadow: 0 0 10px var(--lighterBg);
        gap: 5px;

        .info-text {
          display: grid;
          gap: 4px;

          .text-header {
            font-weight: 700;
            font-size: 12px;
            color: var(--priceText);
          }

          .text-content {
            font-weight: 500;
            font-size: 12px;
            color: var(--priceText);
          }
        }
      }

      .proforma-detail-headline {
        color: var(--priceText);
      }

      .proforma-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--gap);

        .details-columns {
          display: grid;
          width: 100%;
          gap: var(--gap2x);

          .details-column {
            display: flex;
            align-items: center;

            .column {
              display: grid;
              width: 100%;
              grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
              gap: var(--gap);
            }

            .display {
              margin-left: 10px;
              color: var(--notRed);
              cursor: pointer;
              transition: all 150ms linear;

              &:hover {
                color: var(--error);
                transition: all 150ms linear;
              }
            }

            .displayHide {
              display: none;
            }
          }
        }

        .proforma-add {
          color: var(--btnBgBlue);
          transition: all 150ms linear;
          cursor: pointer;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: var(--lighterBg);

          &.active {
            &:hover {
              color: var(--lighterBg);
              background-color: var(--btnBgBlue);
              transition: all 150ms linear;
            }
          }

          &.disabled {
            cursor: auto;
            opacity: 0.3;
          }
        }
      }

      .proforma-send {
        width: fit-content;
        margin-left: auto;
        background: var(--info);
        border: none;
        outline: none;
        color: var(--lighterBg);
        font-size: 14px;
        padding: 5px 10px;
        cursor: pointer;
        border-radius: 5px;
        transition: all 150ms linear;

        &:hover {
          background: var(--blueBrand);
          transition: all 150ms linear;
        }
      }
    }

    .shipmentPayment {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      background-color: var(--lighterBg);
      padding: 20px;
      border-radius: var(--inputBorderRadius);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--gap2x);

      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      svg {
        color: var(--greenBrand);
        width: 150px;
        height: 150px;
      }

      div {
        color: var(--priceText);
      }

      .button-wrapper {
        display: flex;
        align-items: center;
        gap: var(--gap2x);

        .button {
          padding: 8px 20px;
          text-align: center;
          background-color: var(--info);
          color: var(--lighterBg);
          border-radius: var(--buttonBorderRadius);
          border: none;
          outline: none;
          cursor: pointer;
          transition: all 150ms linear;
          text-transform: none;
          text-decoration: none;
          height: 40px;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background-color: var(--blueBrand);
            transition: all 150ms linear;
          }
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .createShipment {
    .createShipment-wrapper {
      .shipmentDimension {
        .shipmentDimension-form {
          .form-dimensions {
            .form-dimensions-wrapper {
              .form-dimensions-content-container {
                .form-dimensions-container {
                  grid-template-columns: repeat(3, minmax(150px, 1fr)) !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .createShipment {
    .createShipment-wrapper {
      .shipmentPrice {
        .shipmentPrice-get {
          .shipmentPrice-get-wrapper {
            grid-template-columns: repeat(2, 1fr);
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .createShipment {
    .createShipment-stepper {
      display: none;
    }

    .createShipment-wrapper {
      transition: all 150ms linear;

      .shipmentInfo {
        .shipmentInfo-form {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .shipmentPrice {
        .shipmentPrice-get {
          .shipmentPrice-get-wrapper {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .createShipment {
    .createShipment-wrapper {
      .shipmentDimension {
        .shipmentDimension-form {
          .form-dimensions {
            .form-dimensions-wrapper {
              .form-dimensions-content-container {
                .form-dimensions-container {
                  grid-template-columns: repeat(2, minmax(150px, 1fr)) !important;
                }
              }
            }
          }
        }
      }

      .shipmentPayment {
        .button-wrapper {
          flex-direction: column;

          .button {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .createShipment {
    .createShipment-wrapper {
      .shipmentDimension {
        .shipmentDimension-form {
          .form-dimensions {
            .form-dimensions-wrapper {
              .form-dimensions-content-container {
                .form-dimensions-container {
                  grid-template-columns: repeat(1, minmax(150px, 1fr)) !important;
                }

                .form-dimensions-weight {
                  grid-template-columns: repeat(1, 1fr);
                  gap: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .createShipment {
    .createShipment-wrapper {
      transition: all 150ms linear;

      .shipmentDimension {
        .shipmentDimension-form {
          .total-weight {
            grid-template-columns: repeat(1, 1fr) !important;
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .createShipment {
    .createShipment-wrapper {
      .shipmentDimension
        .shipmentDimension-form
        .form-dimensions
        .form-dimensions-wrapper
        .form-dimensions-contain
        .form-dimensions-weight
        div {
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 360px) {
  .createShipment {
    .createShipment-wrapper {
      .shipmentPrice {
        .shipmentPrice-header {
          flex-direction: column;

          .shipmentPrice-header-connection {
            align-self: center;
          }
        }

        .shipmentPrice-get-delivery {
          .get-delivery-status {
            flex-direction: column;
          }
        }

        .shipmentPrice-get-card {
          display: flex;
          flex-direction: column;

          .get-card-wrapper {
            .get-card {
              .get-card-main {
                .get-card-main-price-wrapper {
                  .get-card-main-price {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
