.payment-basket {
  z-index: 1201;
}
.payment-basket button {
  padding: 16px;
  width: 40px !important;
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.to-basket-link {
  text-transform: none;
  background-color: var(--info);
  color: var(--lighterBg);
  transition: all 150ms linear 0s;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  border-radius: var(--buttonBorderRadius);
  padding-left: 7px;
  padding-right: 7px;
  transition: all 150ms linear;
}
.to-basket-link:hover {
  background-color: var(--blueBrand);
  transition: all 150ms linear;
}/*# sourceMappingURL=PaymentBasket.css.map */