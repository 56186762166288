.registered-dimensions {
  background-color: var(--background);
  width: 100%;
  border-radius: var(--wrapperBorderRadius);
  min-height: 100vh;
}
.registered-dimensions .card-spring-in .default-card {
  display: grid;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: var(--gap3x) auto 0;
}
.registered-dimensions .card-spring-in .default-card .card-info-wrapper {
  display: flex;
  width: 100%;
  gap: var(--gap2x);
}
.registered-dimensions .card-spring-in .default-card .card-info-wrapper .card-info-text {
  display: grid;
  align-content: flex-start;
  width: 100%;
  gap: var(--gap2x);
}
.registered-dimensions .card-spring-in .default-card .card-info-wrapper .card-info-text .card-info-text-date {
  display: flex;
  gap: var(--gap);
}
.registered-dimensions .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper {
  display: flex;
  max-width: 330px;
  width: 100%;
}
.registered-dimensions .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display {
  border-radius: var(--inputBorderRadius);
  width: 330px;
  height: 200px;
  background-image: url(../../img/images/credit_card_container.svg);
  background-size: cover;
  padding: 20px;
  display: grid;
}
.registered-dimensions .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display.display-back {
  background-image: url(../../img/images/credit_card_back_container.svg);
  justify-content: center;
  align-items: center;
}
.registered-dimensions .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display.display-back .card-back {
  color: var(--subtitleDarkBlue);
  margin-left: 75px;
  margin-top: 20px;
}
.registered-dimensions .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display .card-top {
  display: flex;
  justify-content: flex-end;
  color: var(--lighterBg);
}
.registered-dimensions .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display .card-bottom {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  justify-content: flex-end;
  align-items: flex-start;
  color: var(--lighterBg);
  margin-bottom: 10px;
}
.registered-dimensions .card-spring-in .default-card .card-info-register {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--gap3x);
  width: 100%;
}
.registered-dimensions .card-spring-in .default-card .card-info-register button {
  padding: 7px 30px;
  background-color: var(--btnBgBlue);
  border-radius: var(--buttonBorderRadius);
  border: 1px solid var(--btnBgBlue);
  color: var(--lighterBg);
  cursor: pointer;
  transition: all 150ms linear;
  font-size: var(--smallTextSize);
}
.registered-dimensions .card-spring-in .default-card .card-info-register button:hover {
  background-color: var(--blueBrand);
  border: 1px solid var(--blueBrand);
  transition: all 150ms linear;
}
.registered-dimensions .card-spring-in .default-dimension {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: var(--gap3x) 0 0;
}
.registered-dimensions .card-spring-in .default-dimension .dimension-info-wrapper {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  width: 100%;
  gap: var(--gap2x);
}
.registered-dimensions .card-spring-in .default-dimension .dimension-info-register {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--gap3x);
  width: 100%;
  flex-wrap: wrap;
}
.registered-dimensions .dimensions {
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--inputBorderRadius);
}
.registered-dimensions .dimensions .profile-card-wrapper {
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.registered-dimensions .dimensions .profile-card-wrapper .profile-card-header {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--priceText);
  font-weight: 700;
}
.registered-dimensions .dimensions .profile-card-wrapper .profile-card-header svg {
  color: var(--priceText);
}
.registered-dimensions .dimensions .dimension-list .card-adder-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: var(--gap);
}
.registered-dimensions .dimensions .dimension-list .card-adder-wrapper .card-adder {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--info);
  display: flex;
  align-items: center;
  justify-self: flex-end;
  gap: 10px;
  font-weight: 700;
}
.registered-dimensions .dimensions .dimension-list .dimensions-display-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  gap: var(--gap2x);
  margin-top: var(--gap2x);
}
.registered-dimensions .dimensions .dimension-list .dimensions-display-wrapper .dimensions-display {
  border: 1px solid var(--inputBorderColor);
  border-radius: var(--inputBorderRadius);
}
.registered-dimensions .dimensions .dimension-list .dimensions-display-wrapper .dimensions-display .display-header {
  padding: var(--padding);
  font-size: var(--smallTextSize);
  color: var(--priceText);
}
.registered-dimensions .dimensions .dimension-list .dimensions-display-wrapper .dimensions-display .display-content {
  padding: var(--padding);
  display: grid;
  gap: var(--gap2x);
}
.registered-dimensions .dimensions .dimension-list .dimensions-display-wrapper .dimensions-display .display-content div {
  font-size: var(--smallTextSize);
  color: var(--priceText);
}

@media (max-width: 1366px) {
  .profile-settings .profile-settings-wrapper .general-info,
  .profile-settings .profile-settings-wrapper .address-info,
  .profile-settings .profile-settings-wrapper .company-info,
  .profile-settings .profile-settings-wrapper .registered-cards,
  .profile-settings .profile-settings-wrapper .registered-dimensions,
  .profile-settings .profile-settings-wrapper .connect-integration {
    padding: 20px;
  }
  .profile-settings .profile-settings-wrapper .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .info-collapse-individual-form {
    gap: var(--gap2x);
  }
}
@media (max-width: 1160px) {
  .profile-settings .profile-settings-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper {
    display: grid;
  }
  .profile-settings .profile-settings-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper {
    max-width: none;
    justify-content: center;
  }
}
@media (max-width: 700px) {
  .profile-settings .profile-settings-wrapper .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .info-collapse-individual-form {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
  .profile-settings .profile-settings-wrapper .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .info-collapse-individual-form .individual-form-phone {
    flex-direction: column;
    gap: var(--gap2x);
  }
  .profile-settings .profile-settings-wrapper .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .info-collapse-individual-form .individual-form-phone .individual-form-phone-area-code {
    width: 100%;
  }
  .profile-settings .profile-settings-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper .card-info-text .card-info-text-date {
    flex-direction: column;
  }
  .profile-settings .profile-settings-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display {
    width: 280px;
    height: 163px;
  }
}
@media (max-width: 600px) {
  .registered-dimensions {
    padding: 10px;
  }
  .registered-cards .card-spring-in .default-dimension .dimension-info-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }
}/*# sourceMappingURL=RegisteredDimensions.css.map */