.forgot-main {
  display: flex;
}
.forgot-main .forgot-wrapper {
  width: 100%;
  display: grid;
  justify-content: center;
  margin: auto;
  padding: 50px 70px;
}
.forgot-main .forgot-wrapper .forgot {
  display: grid;
  gap: var(--gap3x);
  max-width: 488px;
  width: 100%;
}
.forgot-main .forgot-wrapper .forgot .forgot-headline {
  display: grid;
  gap: var(--gap2x);
}
.forgot-main .forgot-wrapper .forgot .forgot-headline h1 {
  font-size: 36px;
}
.forgot-main .forgot-wrapper .forgot .forgot-headline p {
  font-size: var(--smallTextSize);
}
.forgot-main .forgot-wrapper .forgot .forgot-reset {
  display: grid;
  gap: var(--gap3x);
}
.forgot-main .forgot-wrapper .forgot .forgot-reset .others-divider {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.forgot-main .forgot-wrapper .forgot .forgot-reset .others-divider .others-divider-line {
  background-color: var(--lighterTextColor);
  height: 1px;
  width: 100%;
}
.forgot-main .forgot-wrapper .forgot .forgot-reset .others-divider .others-divider-text {
  color: var(--lighterTextColor);
  font-size: 12px;
  min-width: 175px;
}
.forgot-main .forgot-wrapper .forgot .forgot-reset .selected-wrapper .selected-country .MuiSelect-select {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.forgot-main .forgot-wrapper .forgot .forgot-reset .others-log {
  width: 100%;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  background-color: var(--blueBrand);
  border-radius: var(--inputBorderRadius);
  border: 1px solid transparent;
  color: var(--lighterBg);
  font-weight: 700;
  cursor: pointer;
}
.forgot-main .forgot-wrapper .forgot .forgot-reset .others-log:disabled {
  opacity: 0.2;
  cursor: auto;
}
.forgot-main .forgot-wrapper .forgot .sign-others {
  display: flex;
  flex-direction: column;
  gap: var(--gap3x);
  max-width: 488px;
  width: 100%;
  margin: 0 auto;
}
.forgot-main .forgot-wrapper .forgot .sign-others .others-divider {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.forgot-main .forgot-wrapper .forgot .sign-others .others-divider .others-divider-line {
  background-color: var(--lighterTextColor);
  height: 1px;
  width: 100%;
}
.forgot-main .forgot-wrapper .forgot .sign-others .others-divider .others-divider-text {
  color: var(--lighterTextColor);
  font-size: 12px;
  min-width: 90px;
}
.forgot-main .forgot-wrapper .forgot .sign-others .nav-link {
  width: 100%;
  border-radius: var(--inputBorderRadius);
  background-color: var(--blueBrand);
  padding: 10px;
  color: var(--lighterBg);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--smallTextSize);
  line-height: 21px;
}
.forgot-main .forgot-wrapper .forgot .sign-others .others-sign-in {
  border: 1px solid var(--lighterTextColor);
  border-radius: var(--inputBorderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  gap: var(--gap2x);
  background-color: var(--lighterBg);
  transition: background-color 150ms linear;
  cursor: pointer;
}
.forgot-main .forgot-wrapper .forgot .sign-others .others-sign-in div {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap);
}
.forgot-main .forgot-wrapper .forgot .sign-others .others-sign-in:hover {
  background-color: var(--background);
  transition: background-color 150ms linear;
}
.forgot-main .forgot-wrapper .forgot .sign-others .others-sign-in span {
  color: var(--darkerTextColor);
  font-weight: 700;
  font-size: var(--smallTextSize);
}

@media (max-width: 1024px) {
  .forgot-main {
    flex-direction: column-reverse;
  }
  .forgot-main .forgot-wrapper {
    padding: 20px;
  }
  .forgot-main .forgot-wrapper .forgot .forgot-headline h1 {
    font-size: 32px;
  }
}/*# sourceMappingURL=Forgot.css.map */