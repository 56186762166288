*,
*::before,
*::after {
  font-size: 14px;
}

.fast-shipment {
  background-color: var(--background);
  min-height: 100vh;


  .fast-shipment-wrapper {
    .fast-shipment-container {
      display: grid;
      gap: var(--gap3x);

      .fast-shipment-list {
        display: flex;
        flex-direction: column;
        gap: var(--gap2x);
        max-width: 1400px;
        width: 100%;
        margin: auto;

        .fast-shipment-list-buttons {
          display: flex;
          align-items: center;
          gap: var(--gap2x);
          width: 100%; 
        }

        @media (max-width: 768px) {
          .fast-shipment-list-buttons {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 450px;
            button {
              width: 100%;
            }
          }
        }

        .fast-shipment-list-item-wrapper {
          display: flex;
          flex-direction: column;
          gap: var(--gap);

          .line {
            display: flex;
            flex-direction: column;
            gap: 5px;
            background-color: var(--lighterBg);
            border-radius: var(--inputBorderRadius);
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
            padding: 10px;

            .list-item-line {
              display: flex;
              align-items: center;
              gap: 35px;

              .list-item-check {
                margin-right: 0;
              }

              .list-item-header {
                width: 200px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                height: 100px;
              }

              .w-borders {
                border-right: 1px solid rgb(207, 207, 207);

              }

              .list-item-direction {
                display: flex;
                gap: var(--gap);
                align-items: center;
                width: 100px;
                flex-direction: column;
                justify-content: center;
                gap: 3px;
                text-align: center;
              }

              .list-item-select-inputs {
                display: flex;
                gap: 5px;
                width: 300px;

                div {
                  display: flex;
                  flex-direction: column;
                  gap: 5px;

                  button {
                    height: 25px;
                    width: 130px;
                  }
                }



                img {
                  height: 16px !important;
                  margin-right: 5px;
                }
              }

              @media (max-width: 1360px) {
                .list-item-select-inputs {
                  flex-direction: column;
                  justify-content: center;
                }

                .fast-shipment-buttons {
                  .fast-shipment-buttons-price {
                    flex-direction: column;
                    width: 100%;

                    button {
                      width: 100%;
                    }
                  }
                }
              }

              .fast-shipment-buttons {
                display: flex;
                align-items: center;
                gap: 4px;

                .fast-shipment-buttons-price {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                }

                .fast-address {
                  background-color: blue !important;
                }
              }

              @media (max-width: 1430px) {
                .fast-shipment-buttons {
                  flex-direction: column;
                  justify-content: center;
                  width: 200px;

                  .fast-address {
                    width: 100%;
                  }

                  .fast-shipment-buttons-price {
                    .fast-dimension-button {
                      width: 100%;
                      min-width: 75px;
                    }

                    .fast-price {
                      min-width: 105px;
                    }
                  }
                }
              }
            }

            .list-item-price {
              display: flex;
              font-size: 30px;
              text-align: center;
              gap: var(--gap);
              justify-content: center;

              .price-text {
                display: flex;
                align-items: center;
                gap: var(--gap);
              }

              &.none {
                display: none;
              }
            }
          }

          .card {
            background-color: var(--lighterBg);
            border-radius: var(--inputBorderRadius);
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
            padding: 10px;
            gap: var(--gap);
            display: none;
            align-items: center;

            .list-item-header {
              display: flex;
              height: 100%;
              gap: var(--gap);
              position: relative;
              justify-content: center;

              .list-item-check {
                position: absolute;
                left: 5px;
              }

              .list-item-select-child {
                display: flex;
                flex-direction: column;
                gap: 3px;
                align-items: center;
              }
            }

            .fast-shipment-buttons {
              display: flex;
              gap: 5px;

              button {
                width: 100%;
              }
            }

            .list-item-direction {
              display: flex;
              gap: var(--gap);
              align-items: center;
            }
          }
        }

        @media (max-width: 1023px) {
          .fast-shipment-list-item-wrapper {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
            gap: var(--gap);

            .card {
              display: grid;
            }
          }
        }
      }

      @media (max-width: 1366px) {
        .fast-shipment-list {
          max-width: 1100px;
          width: 100%;
          margin: auto;
        }
      }

      @media (max-width: 1200px) {
        .fast-shipment-list {
          max-width: 800px;
          width: 100%;
          margin: auto;
        }
      }

      @media (max-width: 768px) {
        .fast-shipment-list {
          max-width: 650px;
          width: 100%;
          margin: auto;
        }
      }

      @media (max-width: 468px) {
        .fast-shipment-list {
          max-width: 340px;
          width: 100%;
          margin: auto;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .fast-shipment-wrapper {
      max-width: 500px
     
    }

  }
}

@media (max-width: 768px) {
  .fast-shipment {
    min-width: 100%;
  }
}