.profile-nav-links-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--gap) 0;
  padding-bottom: 10px;
  margin-bottom: var(--gap3x);

  .nav-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--priceText);
    text-decoration: none;
    gap: var(--gap);
    font-size: 14px;
    padding: 0;
    padding-bottom: 10px;
    text-transform: none;
    border-bottom: 2px solid var(--phoneDigitBg);
    transition: all 150ms linear;
    border-radius: 0;

    &.active {
      font-weight: 700;
      color: var(--info);
      border-bottom: 2px solid var(--info);
      transition: all 150ms linear;
    }
  }
}

@media (max-width: 1166px) {
  .profile-nav-links-wrapper {
    gap: var(--gap2x) 0;
  }
}
