.fade-enter {
    opacity: 0;
    transform: translateX(50px); /* Metinlerin sağa kaydırıldığı başlangıç konumu */
  }
  
  .fade-enter-active {
    opacity: 1;
    transform: translateX(0); /* Metinlerin orijinal konumuna dönmesi */
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transform: translateX(-50px); /* Metinlerin sola kaydırıldığı son konum */
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
  }