.address-info {
  background-color: var(--background);
  min-height: 100vh;
  width: 100%;
  border-radius: var(--wrapperBorderRadius);

  .profile-card-wrapper {
    display: flex;
    align-items: center;
    padding: 0;

    .profile-card-header {
      display: flex;
      align-items: center;
      gap: var(--gap2x);
    }
  }

  .new-address {
    display: flex;
    flex-direction: column;
    gap: var(--gap2x);

    .new-address-adder-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-top: var(--gap);

      .new-address-adder {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        color: var(--info);
        display: flex;
        align-items: center;
        justify-self: flex-end;
        gap: 10px;
        font-weight: 700;
      }
    }

    .new-address-modal {
      opacity: 0;
      transform: translateX(-105%);

      .new-address-register {
        display: flex;
        gap: var(--gap);
      }
    }

    .address-list {
      display: flex;
      flex-direction: column;
      gap: var(--gap2x);
      max-width: 1100px;
      width: 100%;
      margin: auto;

      .address-list-defaults {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: var(--gap2x);

        .address-list-default {
          display: flex;
          flex-direction: column;
          gap: var(--gap);
          box-shadow: var(--shadow);
          border-radius: var(--inputBorderRadius);
          padding: 10px;

          .bill-address {
            border: 1px solid var(--inputBorderColor);
            border-radius: var(--inputBorderRadius);
            padding: var(--gap);

            .bill-address-wrapper {
              .address-medium-text {
                font-size: var(--smallTextSize);
                font-weight: 700;
                color: var(--priceText);
                font-size: 12px;

                span {
                  font-weight: 600;
                  color: var(--btnBgBlue);
                  font-size: 12px;
                }

                &.none {
                  display: none;
                }
              }

              .address-small-text {
                font-size: 11px;
                color: var(--priceText);

                span {
                  font-weight: 600;
                  color: var(--btnBgBlue);
                  font-size: 12px;
                }

                &.none {
                  display: none;
                }
              }
            }
          }
        }
      }

      .address-list-tables {
        display: flex;
        flex-direction: column;
        gap: var(--gap2x);
        box-shadow: var(--shadow);
        border-radius: var(--inputBorderRadius);
        padding: 10px;
      }

      .sender-address {
        width: 100%;

        .sender-address-container {
          border: 1px solid var(--inputBorderColor);
          border-radius: var(--inputBorderRadius);
          padding: var(--gap);
          width: 100%;

          &.default {
            background: rgba(16, 123, 230, 0.04);
          }

          .address-medium-text {
            font-size: var(--smallTextSize);
            font-weight: 700;
            color: var(--priceText);
            font-size: 13px;

            span {
              font-weight: 600;
              font-size: 13px;
              color: var(--btnBgBlue);
            }
          }

          .address-small-text {
            font-size: 12px;
            color: var(--priceText);

            span {
              font-weight: 600;
              font-size: 13px;
              color: var(--btnBgBlue);
            }

            &.none {
              display: none;
            }
          }
        }
      }

      .address-delete {
        color: var(--notRed);
        transition: all 150ms linear;
        cursor: pointer;

        &:hover {
          color: var(--error);
          transition: all 150ms linear;
        }
      }

      .address-delete-disabled {
        color: var(--parcelText);
        opacity: 0.6;
        cursor: auto;
      }
    }
  }
}

.basic-menu-list {
  li {
    display: flex !important;
    justify-content: space-between !important;
    gap: 5px !important;

    svg {
      height: 22px;
    }
  }
}
