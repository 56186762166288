.company-info-wrapper {
  display: grid;
  gap: var(--gap);
  width: 100%;

  .company-info-inputs {
    display: flex;
    gap: var(--gap);
    width: 100%;
  }

  .company-info-dropzone {
    display: flex;
    align-items: center;
    gap: 5px;

    .info-bold {
      font-weight: 700;
      font-size: 11px;
    }

    .info-text {
      color: var(--subtitleDarkBlue);
      font-weight: 300;
      font-size: 11px;
    }
  }
}
