.get-card-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  padding: 20px;

  .get-card-container {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    background-color: var(--lighterBg);
    border-radius: var(--wrapperBorderRadius);
    padding: 20px;
    width: 100%;

    .get-card-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 5px;

      .get-card {
        display: grid;
        align-content: space-between;
        color: var(--priceText);
        gap: var(--gap);
        box-shadow: var(--shadow);
        padding: 10px;
        border-radius: var(--wrapperBorderRadius);

        &:hover {
          box-shadow: var(--blueShadow);
          transition: all 150ms linear;
        }

        &.selected {
          box-shadow: var(--bluestShadow);
        }

        .get-card-header {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .get-card-header-headline {
            display: flex;
            align-items: center;
            gap: var(--gap);
            font-weight: 600;
          }
          .get-card-header-subtext {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 500;

            span {
              font-weight: 700;
            }
          }
        }

        .get-card-main {
          .get-card-main-price-wrapper {
            display: flex;
            flex-direction: column;
            gap: var(--gap);

            .get-card-main-price {
              color: var(--info);

              .main-price-out {
                font-size: 36px;
                font-weight: 500;
              }

              .main-price-in {
                font-size: inherit;
                color: var(--priceText);
              }
            }
          }

          .shipmentPrice-get-insurance {
            padding: 10px 0 10px 10px;

            label {
              span {
                padding: 3px;
              }
            }
          }
        }
      }
    }
  }
}
