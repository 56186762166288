.support {
  background-color: var(--background);
  min-height: 100vh;

  .support-wrapper {
    .support-container {
      display: grid;
      gap: var(--gap3x);

      max-width: 1400px;
      width: 100%;
      margin: auto;

      .no-support {
        background-color: var(--lighterBg);
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--gap3x);
        box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
        border-radius: var(--wrapperBorderRadius);

        .no-support-img {
          max-width: 250px;
          width: 100%;
        }

        .no-support-text {
          color: var(--priceText);
          font-size: 15px;
        }

        .no-support-button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 7px 16px;
          background-color: var(--info);
          color: var(--lighterBg);
          border: none;
          outline: none;
          border-radius: var(--buttonBorderRadius);
          text-transform: none;
          gap: 5px;
          transition: all 150ms linear;

          svg {
            color: var(--lighterBg);
          }

          &:hover {
            background-color: var(--blueBrand);
          }
        }
      }

      .support-request {
        background-color: var(--lighterBg);
        padding: 20px;
        box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
        border-radius: var(--wrapperBorderRadius);
        display: flex;
        flex-direction: column;
        gap: var(--gap2x);

        h6 {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: var(--gap);
          color: var(--priceText);
        }

        .support-request-subject-info {
          background-color: var(--pastelBlue);
          padding: 5px 10px;
          border-radius: var(--wrapperBorderRadius);
          display: flex;
          gap: 5px;
          align-items: center;
          color: var(--priceText);

          p {
            font-size: 13px;
            color: var(--priceText);
            .support-request-subject-info-link {
              font-size: 13px;
              color: var(--info);
              transition: all 150ms linear;

              &:hover {
                color: var(--blueBrand);
                transition: all 150ms linear;
              }
            }
          }
        }

        .support-request-wrapper {
          display: flex;
          gap: var(--gap2x);

          .support-request-left {
            display: grid;
            align-content: space-between;
            max-width: 300px;
            width: 100%;
          }
        }

        .support-request-done {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          gap: var(--gap);

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: none;
            background-color: var(--info);
            color: var(--lighterBg);
            border-radius: var(--buttonBorderRadius);
            padding: 5px 15px;
            transition: all 150ms linear;
            font-size: 13px;

            &:hover {
              background-color: var(--blueBrand);
            }
          }
        }
      }

      .support-list {
        background-color: var(--lighterBg);
        border-radius: var(--wrapperBorderRadius);
        padding: 20px;
        overflow-x: auto;
        color: var(--priceText);
        display: flex;
        flex-direction: column;
        gap: var(--gap2x);

        h6 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: var(--gap);
          margin-bottom: 20px;
        }

        .support-list-new-support-demand {
          display: flex;
          justify-content: flex-end;

          button {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 5px 15px;
            text-transform: none;
            background-color: var(--info);
            color: var(--lighterBg);
            transition: all 150ms linear;

            &:hover {
              background-color: var(--blueBrand);
            }
          }
        }

        .support-list-table-head {
          th {
            .th-container {
              font-size: 13px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: var(--gap);
              cursor: pointer;
              transition: all 150ms linear;
              font-weight: 700;
              color: var(--priceText);

              &:hover {
                color: var(--info);
                transition: all 150ms linear;
              }
            }

            &:last-child {
              .th-container {
                cursor: auto;
                &:hover {
                  color: var(--priceText);
                  transition: all 150ms linear;
                }
              }
            }
          }
        }

        .support-list-table-body {
          td {
            font-size: 13px;
            color: var(--priceText);

            .status {
              padding: 5px 10px;
              border-radius: 9999px;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;

              &.new {
                color: var(--blueBrand);
                background-color: var(--pastelBlue);
              }

              &.closed {
                color: var(--priceText);
                background-color: var(--phoneDigitBg);
              }

              &.active {
                color: var(--greenBrand);
                background-color: var(--pastelGreen);
              }

              &.on-hold {
                color: var(--redBrand);
                background-color: var(--pastelRed);
              }
              .status-container {
                font-size: 12px;
                display: flex;
                align-items: center;
                gap: 5px;

                .status-logo {
                  width: 20px !important;
                  height: 20px !important;

                  &.continues {
                    color: var(--redBrand);
                  }

                  &.closed {
                    color: var(--orangeBrand);
                  }

                  &.active {
                    color: var(--greenBrand);
                  }
                }
              }
            }

            .support-list-show-detail {
              background-color: var(--info);
              text-transform: none;
              color: var(--lighterBg);
              padding: 5px 15px;
              transition: all 150ms linear;
              font-size: 13px;

              &:hover {
                background-color: var(--blueBrand);
                transition: all 150ms linear;
              }
            }
          }
        }

        .support-list-pagination-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }

      .support-detail {
        h6 {
          color: var(--priceText);
          margin-bottom: var(--gap2x);
        }

        .support-detail-container {
          display: grid;
          gap: var(--gap2x);
          margin-bottom: var(--gap2x);

          .support-detail-info {
            background-color: var(--lighterBg);
            border-radius: var(--inputBorderRadius);
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            padding: 20px;
            gap: var(--gap2x);
            box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);

            .detail-info-text {
              font-size: 14px;
              color: var(--priceText);
            }
          }

          .stepper {
            background-color: var(--lighterBg);
            border-radius: var(--inputBorderRadius);
            padding: 20px;
            gap: var(--gap2x);
            box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
            display: flex;

            .stepper-wrapper {
              display: flex;
              flex-direction: column;
              gap: var(--gap2x);

              .steps-where-to {
                justify-content: space-evenly;
                align-items: center;
                gap: var(--gap);
                max-width: 600px;
                width: 100%;

                &.l-size {
                  display: flex;
                }

                &.s-size {
                  display: none;
                }

                .to-integration {
                  text-align: center;

                  div {
                    font-size: 13px;

                    &:first-child {
                      color: orange;
                      font-size: 20px;
                    }

                    &:last-child {
                      font-weight: 700;
                    }
                  }
                }

                .to-plane {
                  display: grid;
                  gap: 5px;
                  text-align: center;
                  font-size: 13px;

                  .plane-flag {
                    display: flex;
                    gap: var(--gap);

                    #land {
                      color: var(--shipmentGreen);
                    }

                    #take-off {
                      color: var(--error);
                    }
                  }
                }

                .to-tracking {
                  background-color: var(--background);
                  padding: 5px 30px;
                  border-radius: var(--buttonBorderRadius);
                  font-size: 12px;
                  color: var(--priceText);
                  font-weight: 700;
                }
              }

              .stepper-wrapper-css {
                display: flex;
                justify-content: space-between;
                position: relative;
                max-width: 600px;
                width: 100%;

                .step-wrapper-css {
                  z-index: 2;

                  .step-css {
                    font-size: 10px;
                    padding: 10px;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .step-logo-css {
                      width: 24px;
                      height: 24px;
                    }

                    .step-shipment-logo-css {
                      width: 34px;
                      height: 34px;
                    }
                  }
                }

                .step-connector-css {
                  position: absolute;
                  width: 100%;
                  height: 4px;
                  background-color: var(--phoneDigitBg);
                  display: flex;
                  align-self: center;
                  z-index: 0;

                  .step {
                    width: 100%;

                    &.active {
                      width: calc(100%);
                      background-color: var(--shipmentGreen);
                    }
                  }
                }
              }
            }

            .steps-info {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
              gap: var(--gap2x);
              width: 100%;

              .steps-info-wrapper {
                display: grid;
                gap: var(--gap);

                .info-top {
                  color: var(--priceText);
                  font-size: 14px;
                  font-weight: 700;
                  text-align: center;
                }

                .info-bottom {
                  display: flex;
                  gap: var(--gap);
                  justify-content: space-between;

                  .info-bottom-subline {
                    color: var(--priceText);
                    font-size: 12px;
                    display: grid;
                    text-align: center;

                    .info-bottom-subline-number {
                      background-color: var(--background);
                      padding: 3px 7px;
                      border-radius: var(--buttonBorderRadius);
                      font-weight: 700;
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }

          .support-detail-shipment {
            background-color: var(--lighterBg);
            border-radius: var(--inputBorderRadius);
            padding: 20px;
            gap: var(--gap2x);
            box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
          }

          .support-detail-chat {
            background-color: var(--lighterBg);
            border-radius: var(--inputBorderRadius);
            padding: 20px;
            gap: var(--gap2x);
            box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);

            .support-detail-chat-messages {
              max-height: 400px;
              overflow-y: auto;
              display: flex;
              flex-direction: column;
              gap: var(--gap2x);
              margin-bottom: var(--gap2x);

              .chat-message {
                display: flex;
                flex-direction: column;
                gap: 2px;
                max-width: 250px;
                width: 100%;

                &.customer {
                  align-self: flex-end;
                }

                &.admin {
                  align-self: flex-start;
                }

                .chat-message-time {
                  font-size: 10px;
                  color: var(--priceText);
                  text-align: center;
                }

                .chat-message-text-wrapper {
                  display: flex;
                  gap: 5px;

                  &.customer-wrapper {
                    flex-direction: row-reverse;
                  }

                  .chat-message-avatar {
                    width: 32px;
                    height: 32px;
                    background-color: var(--info);
                  }

                  .chat-message-text {
                    padding: var(--gap);

                    &.customer {
                      background-color: var(--info);
                      color: var(--lighterBg);
                      border-radius: 24px 0px 24px 24px;
                      font-size: 13px;
                    }

                    &.admin {
                      background-color: var(--phoneDigitBg);
                      border-radius: 0px 24px 24px 24px;
                      font-size: 13px;
                    }
                  }
                }
              }
            }

            .detail-chat-send-message {
              display: flex;
              gap: var(--gap2x);

              .detail-chat-send-message-button {
                background-color: var(--info);
                transition: all 150ms linear;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                min-width: 40px;

                &:hover {
                  background-color: var(--blueBrand);
                  transition: all 150ms linear;
                }
              }
            }
          }

          .support-detail-files {
            .support-detail-files-header {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 5px;
              color: var(--priceText);
              padding-left: 12px;
            }

            .support-detail-files-content {
              display: flex;
              flex-direction: column;

              .files-content {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
                gap: var(--gap2x);

                .files-content-item {
                  display: flex;
                  flex-direction: column;
                  gap: 5px;
                  align-items: center;
                  cursor: pointer;

                  .files-content-item-icon {
                    border: 1px solid var(--inputBorderColor);
                    border-radius: var(--inputBorderRadius);
                    padding: 19px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                      width: 60px;
                      height: 60px;
                      color: var(--priceText);
                    }
                  }

                  .files-content-item-text {
                    color: var(--priceText);
                    font-size: 13px;
                  }
                }
              }

              .note-content {
                display: grid;
                gap: 2px;

                .note-content-date {
                  color: var(--priceText);
                  font-size: 13px;
                }
                .note-content-text {
                  color: var(--priceText);
                  font-size: 16px;
                  border: 1px solid var(--inputBorderColor);
                  border-radius: var(--inputBorderRadius);
                  padding: 5px 10px;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .support {
    .support-wrapper {
      .support-container {
        .support-detail {
          .support-detail-container {
            .stepper {
              flex-direction: column;
              align-items: center;

              .stepper-wrapper {
                width: 100%;
                align-items: center;

                .steps-where-to {
                  &.l-size {
                    display: none;
                  }

                  &.s-size {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .size-wrapper {
                      display: flex;
                      align-items: center;
                      gap: var(--gap);
                      justify-content: center;
                      flex-wrap: wrap;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .support {
    .support-wrapper {
      .support-container {
        .support-detail {
          .support-detail-container {
            .stepper {
              .stepper-wrapper {
                .steps-where-to {
                  &.l-size {
                    display: none;
                  }

                  &.s-size {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .size-wrapper {
                      display: flex;
                      align-items: center;
                      gap: var(--gap);
                      justify-content: center;
                      flex-wrap: wrap;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .support {
    .support-wrapper {
      .support-container {
        .support-request {
          .support-request-wrapper {
            flex-direction: column;
            justify-content: center;

            .support-request-left {
              max-width: 100%;
              gap: var(--gap2x);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .support {
    .support-wrapper {
      .support-container {
        .support-detail {
          .support-detail-container {
            .stepper {
              .stepper-wrapper {
                .stepper-wrapper-css {
                  .step-wrapper-css {
                    .step-css {
                      padding: 7px;
                      width: 30px;
                      height: 30px;

                      .step-logo-css {
                        width: 16px;
                        height: 16px;
                      }

                      .step-shipment-logo-css {
                        width: 24px;
                        height: 24px;
                      }
                    }
                  }

                  .step-connector-css {
                    height: 2px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .support {
    .support-wrapper {
      .support-container {
        .support-request {
          .support-request-done {
            display: grid;
            justify-content: normal;

            button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
