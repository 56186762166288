.my-wallet-arrangement-bill-modal {
  background-color: var(--lighterBg);
  padding: 20px;
  max-width: 600px;
  width: 100%;
  border-radius: var(--wrapperBorderRadius);
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
}
.my-wallet-arrangement-bill-modal .my-wallet-arrangement-bill-modal-headline {
  font-size: 16px;
}
.my-wallet-arrangement-bill-modal .my-wallet-arrangement-bill-modal-info {
  background-color: var(--shipmentRed);
  display: flex;
  gap: var(--gap);
  padding: 5px;
  border-radius: var(--wrapperBorderRadius);
}
.my-wallet-arrangement-bill-modal .my-wallet-arrangement-bill-modal-info svg {
  color: var(--lighterBg);
}
.my-wallet-arrangement-bill-modal .my-wallet-arrangement-bill-modal-info p {
  font-size: 12px;
  color: var(--lighterBg);
}
.my-wallet-arrangement-bill-modal .my-wallet-arrangement-bill-modal-inputs {
  display: flex;
  gap: var(--gap);
  justify-content: space-between;
}
.my-wallet-arrangement-bill-modal .bakiye-table-row th {
  padding: 5px;
}
.my-wallet-arrangement-bill-modal .bakiye-table-row td {
  padding: 5px;
}
@media (max-width: 550px) {
  .my-wallet-arrangement-bill-modal .my-wallet-arrangement-bill-modal-inputs {
    display: grid;
    justify-content: initial;
  }
}

.borderless-tablerow td {
  border-bottom: none;
  padding-bottom: 0;
}

.borderless-tablerow-reject.borderless2 td {
  border-bottom: none;
  padding-bottom: 0;
}
.borderless-tablerow-reject td {
  padding-top: 10px;
}

.iyzi-button {
  background-color: var(--pastelBlue) !important;
  text-transform: none !important;
  color: var(--blueBrand) !important;
  transition: all 150ms linear !important;
  border-radius: var(--wrapperBorderRadius) !important;
  box-shadow: var(--shadow);
}
.iyzi-button svg {
  color: var(--blueBrand);
  transition: all 150ms linear;
  width: 15px !important;
  height: 15px !important;
}
.iyzi-button:hover {
  background-color: var(--blueBrand) !important;
  color: var(--pastelBlue) !important;
  transition: all 150ms linear !important;
}
.iyzi-button:hover svg {
  color: var(--pastelBlue);
  transition: all 150ms linear;
}

.iyzi-button-blue {
  background-color: var(--btnBgBlue) !important;
  text-transform: none !important;
  color: var(--lighterBg) !important;
  transition: all 150ms linear !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--btnBgBlue) !important;
  gap: 5px;
}
.iyzi-button-blue svg {
  color: var(--lighterBg) !important;
  transition: all 150ms linear !important;
  width: 15px !important;
  height: 15px !important;
}
.iyzi-button-blue:hover {
  background-color: var(--lighterBg) !important;
  color: var(--btnBgBlue) !important;
  border: 1px solid var(--btnBgBlue) !important;
  transition: all 150ms linear !important;
}
.iyzi-button-blue:hover svg {
  color: var(--btnBgBlue) !important;
  transition: all 150ms linear !important;
}

.iyzi-button-red {
  background-color: red !important;
  text-transform: none !important;
  color: var(--lighterBg) !important;
  transition: all 150ms linear !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--btnBgBlue) !important;
  gap: 5px;
}
.iyzi-button-red svg {
  color: var(--lighterBg) !important;
  transition: all 150ms linear !important;
  width: 15px !important;
  height: 15px !important;
}
.iyzi-button-red:hover {
  background-color: var(--lighterBg) !important;
  color: var(--btnBgBlue) !important;
  border: 1px solid var(--btnBgBlue) !important;
  transition: all 150ms linear !important;
}
.iyzi-button-red:hover svg {
  color: var(--btnBgBlue) !important;
  transition: all 150ms linear !important;
}

.iyzi-button-greenBold {
  background-color: var(--greenBrand) !important;
  text-transform: none !important;
  color: white !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--greenBrand) !important;
  transition: all 150ms linear !important;
  box-shadow: var(--shadow);
}

.iyzi-button-red {
  background-color: red !important;
  text-transform: none !important;
  color: white !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--greenBrand) !important;
  transition: all 150ms linear !important;
  box-shadow: var(--shadow);
}

.iyzi-button-yellow {
  background-color: yellow !important;
  text-transform: none !important;
  color: white !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--greenBrand) !important;
  transition: all 150ms linear !important;
  box-shadow: var(--shadow);
}

.iyzi-button-bg {
  background-color: var(--lighterBg) !important;
  text-transform: none !important;
  color: var(--btnBgBlue) !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--btnBgBlue) !important;
  transition: all 150ms linear !important;
}
.iyzi-button-bg svg {
  color: var(--btnBgBlue) !important;
  transition: all 150ms linear !important;
  width: 15px !important;
  height: 15px !important;
}
.iyzi-button-bg:hover {
  background-color: var(--btnBgBlue) !important;
  color: var(--lighterBg) !important;
  transition: all 150ms linear !important;
}
.iyzi-button-bg:hover svg {
  color: var(--lighterBg) !important;
  transition: all 150ms linear !important;
}

.iyzi-button-sea {
  background-color: var(--btnBgBlue) !important;
  text-transform: none !important;
  color: var(--lighterBg) !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--btnBgBlue) !important;
  transition: all 150ms linear !important;
}
.iyzi-button-sea svg {
  color: var(--lighterBg) !important;
  transition: all 150ms linear !important;
  width: 15px !important;
  height: 15px !important;
}
.iyzi-button-sea:hover {
  border: 1px solid var(--btnBgBlue) !important;
  background-color: var(--lighterBg) !important;
  color: var(--btnBgBlue) !important;
  transition: all 150ms linear !important;
}
.iyzi-button-sea:hover svg {
  color: var(--btnBgBlue) !important;
  transition: all 150ms linear !important;
}

.iyzi-button-white {
  background-color: var(--lighterBg) !important;
  text-transform: none !important;
  color: var(--btnBgBlue) !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--btnBgBlue) !important;
  transition: all 150ms linear !important;
}
.iyzi-button-white svg {
  color: var(--lighterBg) !important;
  transition: all 150ms linear !important;
  width: 15px !important;
  height: 15px !important;
}
.iyzi-button-white:hover {
  background-color: var(--btnBgBlue) !important;
  color: var(--lighterBg) !important;
  transition: all 150ms linear !important;
  border: 1px solid var(--lighterBg) !important;
}
.iyzi-button-white:hover svg {
  color: var(--lighterBg) !important;
  transition: all 150ms linear !important;
}

.iyzi-button-red {
  background-color: var(--shipmentRed) !important;
  text-transform: none !important;
  color: var(--pastelRed) !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--shipmentRed) !important;
  transition: all 150ms linear !important;
  box-shadow: var(--shadow);
}
.iyzi-button-red svg {
  color: var(--pastelRed) !important;
  transition: all 150ms linear !important;
  width: 15px !important;
  height: 15px !important;
}
.iyzi-button-red:hover {
  background-color: var(--pastelRed) !important;
  color: var(--shipmentRed) !important;
  transition: all 150ms linear !important;
}
.iyzi-button-red:hover svg {
  color: var(--shipmentRed) !important;
  transition: all 150ms linear !important;
}

.iyzi-button-green {
  background-color: var(--pastelGreen) !important;
  text-transform: none !important;
  color: var(--shipmentGreen) !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--pastelGreen) !important;
  transition: all 150ms linear !important;
  box-shadow: var(--shadow);
}
.iyzi-button-green svg {
  color: var(--shipmentGreen) !important;
  transition: all 150ms linear !important;
  width: 15px !important;
  height: 15px !important;
}
.iyzi-button-green:hover {
  background-color: var(--shipmentGreen) !important;
  color: var(--pastelGreen) !important;
  transition: all 150ms linear !important;
}
.iyzi-button-green:hover svg {
  color: var(--pastelGreen) !important;
  transition: all 150ms linear !important;
}

.iyzi-button-yellow {
  background-color: var(--shipmentYellow) !important;
  text-transform: none !important;
  color: var(--pastelYellow) !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--shipmentYellow) !important;
  transition: all 150ms linear !important;
  box-shadow: var(--shadow);
}
.iyzi-button-yellow svg {
  color: var(--pastelYellow) !important;
  transition: all 150ms linear !important;
  width: 15px !important;
  height: 15px !important;
}
.iyzi-button-yellow:hover {
  background-color: var(--pastelYellow) !important;
  color: var(--shipmentYellow) !important;
  transition: all 150ms linear !important;
}
.iyzi-button-yellow:hover svg {
  color: var(--shipmentYellow) !important;
  transition: all 150ms linear !important;
}

.iyzi-button-gray {
  background-color: #7c7c7a !important;
  text-transform: none !important;
  color: var(--lighterBg) !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid #7c7c7a !important;
  transition: all 150ms linear !important;
  box-shadow: var(--shadow);
}
.iyzi-button-gray svg {
  color: var(--lighterBg) !important;
  transition: all 150ms linear !important;
  width: 15px !important;
  height: 15px !important;
}
.iyzi-button-gray:hover {
  background-color: var(--lighterBg) !important;
  color: #7c7c7a !important;
  transition: all 150ms linear !important;
}
.iyzi-button-gray:hover svg {
  color: #7c7c7a !important;
  transition: all 150ms linear !important;
}

.iyzi-icon-close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px !important;
  height: 24px !important;
  color: var(--text);
  transition: all 150ms linear;
  cursor: pointer;
}
.iyzi-icon-close:hover {
  transition: all 150ms linear;
  color: var(--btnBgBlue);
}

.main-link-wrapper {
  cursor: pointer !important;
}
.main-link-wrapper:hover .main-link span {
  background: var(--pastelBlue);
  border-radius: var(--wrapperBorderRadius);
}
.main-link-wrapper .main-link span {
  font-size: 12px !important;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 10px;
}

.reactpicker-wrapper {
  display: grid;
  gap: 5px;
}
.reactpicker-wrapper label {
  font-size: 13px;
  color: var(--priceText);
}
.reactpicker-wrapper input {
  border-radius: var(--wrapperBorderRadius);
  outline: 0;
  border: 1px solid var(--inputBorderColor);
  height: 30px;
  padding: 0 10px;
  max-width: 130px;
}
.reactpicker-wrapper .react-datepicker-wrapper .react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  z-index: 2;
  fill: var(--priceText);
  top: 8px;
  left: 6px;
  padding: 0;
}

.status {
  padding: 5px 10px;
  border-radius: var(--wrapperBorderRadius);
  text-align: center;
  font-weight: 700;
  font-size: inherit;
}
.status.status0 {
  background-color: var(--pastelYellow);
  color: var(--shipmentYellow);
}
.status.status1 {
  background-color: var(--pastelGreen);
  color: var(--shipmentGreen);
}
.status.status2 {
  background-color: var(--pastelRed);
  color: var(--shipmentRed);
}
.status.tanzim0 {
  background-color: var(--pastelYellow);
  color: var(--shipmentYellow);
}
.status.tanzim1 {
  background-color: var(--pastelYellow);
  color: var(--shipmentYellow);
}
.status.tanzim2 {
  background-color: var(--pastelYellow);
  color: var(--shipmentYellow);
}
.status.tanzim3 {
  background-color: var(--pastelGreen);
  color: var(--shipmentGreen);
}
.status.tanzim4 {
  background-color: var(--pastelRed);
  color: var(--shipmentRed);
}

.iyzi-export {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.iyzi-export .iyzi-date-range {
  display: flex;
}
.iyzi-export .iyzi-date-range input {
  padding: 5px 10px;
  border-radius: var(--wrapperBorderRadius);
  border: 1px solid var(--inputBorderColor);
  margin: 0 var(--gap);
}

.new-address-modal-wrapper .react-tel-input {
  display: flex;
  align-self: baseline;
}

.integration-list-item {
  grid-template-columns: repeat(auto-fit, minmax(317px, 1fr));
}

.international-shipment {
  display: flex;
  gap: var(--gap2x);
  justify-content: space-between;
  align-items: center;
}
.international-shipment .international-shipment-text {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
}
.international-shipment .international-shipment-text article {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
  max-width: 600px;
  width: 100%;
}
.international-shipment .international-shipment-text article h3 {
  font-size: 30px;
  color: var(--btnBgBlue);
  text-align: start;
}
.international-shipment .international-shipment-text article p {
  font-size: 16px;
  font-weight: 400;
  color: var(--priceText);
  text-align: start;
}
.international-shipment .international-shipment-text .international-shipment-text-button {
  display: flex;
  gap: var(--gap2x);
}
.international-shipment .international-shipment-text .international-shipment-text-button button {
  padding: 8px 20px;
}
.international-shipment img {
  max-width: 800px;
}

@media (max-width: 1250px) {
  .international-shipment {
    flex-direction: column;
    margin-top: var(--gap2x);
  }
  .international-shipment .international-shipment-text {
    width: 100%;
  }
  .international-shipment .international-shipment-text article {
    max-width: 100%;
  }
  .international-shipment img {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .integration-list-item {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}/*# sourceMappingURL=Globals.css.map */