.my-wallet {
  background-color: var(--background);
  min-height: 100vh;

  .my-wallet-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap2x);

    .my-wallet-nav {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      gap: var(--gap);
      justify-items: center;
      align-items: center;
      height: 30px;

      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      .my-wallet-nav-link {
        text-decoration: none;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .my-wallet-container {
      display: flex;
      flex-direction: column;
      gap: var(--gap3x);

      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      .my-wallet-card,
      .my-wallet-list {
        background-color: var(--lighterBg);
        border-radius: var(--wrapperBorderRadius);
        padding: 20px;
        width: 100%;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      }

      .my-wallet-card {
        display: flex;
        gap: var(--gap2x);

        .wallet-card-credit {
          display: grid;
          gap: var(--gap2x);
          align-items: center;
          width: 100%;
          justify-items: center;

          .card-credit-buttons {
            display: grid;
            gap: var(--gap);
            max-width: 300px;
          }
        }

        .card-credit-balance {
          width: 100%;
          display: flex;
          justify-content: center;
          gap: var(--gap);
          flex-wrap: wrap;

          img {
            height: 70px;
            object-fit: contain;
            object-position: center;
            border-radius: var(--inputBorderRadius);
          }

          .card-credit-balance-amount {
            display: flex;
            gap: var(--gap);
            justify-items: center;
            flex-direction: column;
            text-align: center;
            min-width: 120px;

            div {
              &:first-child {
                align-items: flex-end;
                display: grid;
                height: 100%;
                color: var(--priceText);
                font-weight: 700;
              }

              &:last-child {
                align-items: flex-start;
                display: grid;
                height: 100%;
                color: var(--info);
                font-size: 30px;
                font-weight: 700;
              }
            }
          }
        }

        .card-credit-choice {
          display: flex;
          gap: var(--gap2x);
          width: 100%;
          justify-content: center;
          flex-wrap: wrap;

          .card-credit-choice-item {
            display: flex;
            flex-direction: column;
            position: relative;
            box-shadow: var(--shadow);
            padding: 10px 30px;
            gap: var(--gap);
            border-radius: var(--wrapperBorderRadius);
            max-width: 290px;
            width: 100%;
            cursor: pointer;

            &.passive {
              filter: brightness(60%) grayscale(50%);
              opacity: 0.7;
              transform: scale(0.98);
            }

            .card-credit-choice-item-check {
              position: absolute;
              top: 10px;
              right: 10px;
              padding: 0;
              margin: 0;
            }
          }
        }
      }

      .my-wallet-payment {
        width: 100%;
        background-color: var(--lighterBg);
        border-radius: var(--inputBorderRadius);
        outline: 0;
        padding: 20px;
        position: relative;
        margin: 0 auto 20px;
        overflow-y: auto;
        box-shadow: var(--shadow);

        .my-wallet-payment-box {
          margin: auto;
          display: grid;
          gap: var(--gap2x);
          max-width: 450px;
        }
      }

      .my-wallet-refund {
        display: flex;
        flex-direction: column;
        gap: var(--gap);

        .my-wallet-refund-balance {
          display: flex;
          gap: var(--gap3x);
          justify-content: center;
          align-items: center;
          background-color: var(--lighterBg);
          padding: 20px;
          box-shadow: var(--shadow);
          border-radius: var(--wrapperBorderRadius);
          flex-wrap: wrap;

          .my-wallet-refund-balance-item {
            box-shadow: var(--shadow);
            border-radius: var(--wrapperBorderRadius);
            padding: 20px;
            max-width: 300px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: var(--gap);
            min-height: 128px;

            .refund-balance-item-headline {
              color: var(--priceText);
              font-size: 14px;
              text-align: center;
            }

            .refund-balance-item-amount {
              color: var(--info);
              font-size: 20px;
              text-align: center;
            }

            .refund-balance-item-info {
              font-size: 11px;
              color: var(--redBrand);
              text-align: center;
            }
          }
        }

        .my-wallet-refund-amount-wrapper {
          background-color: var(--lighterBg);
          box-shadow: var(--shadow);
          padding: 20px;
          display: flex;
          justify-content: center;
          border-radius: var(--wrapperBorderRadius);

          .my-wallet-refund-amount {
            display: flex;
            flex-direction: column;
            gap: var(--gap2x);
            max-width: 400px;
            width: 100%;

            .my-wallet-refund-amount-buttons {
              display: grid;
              justify-content: space-between;
              grid-template-columns: repeat(auto-fit, minmax(64px, 1fr));
              gap: var(--gap2x);
            }

            .my-wallet-refund-amount-show {
              margin: auto;
            }

            .my-wallet-refund-option {
              display: flex;
              gap: var(--gap);

              .my-wallet-refund-option-select {
                max-width: 150px;
                width: 100%;
              }

              .my-wallet-refund-option-select-field {
                p {
                  color: var(--error);
                  padding: 0;
                  margin: 3px 0 0;
                }
              }
            }

            .my-wallet-refund-amount-submit {
              display: flex;
              justify-content: flex-end;
              gap: var(--gap);
            }
          }
        }
      }

      .my-wallet-arrangement {
        display: grid;
        gap: var(--gap);

        .my-wallet-arrangement-info {
          display: flex;
          flex-direction: column;
          gap: var(--gap);

          .my-wallet-arrangement-info-item {
            background-color: var(--pastelBlue);
            padding: 3px 10px;
            display: flex;
            align-items: center;
            gap: var(--gap);
            border-radius: var(--wrapperBorderRadius);

            svg {
              color: var(--info);
            }

            strong {
              font-size: 12px;
            }

            p {
              font-size: 12px;
            }
          }
        }

        .my-wallet-arrangement-table {
          .my-wallet-arrangement-table-status {
            width: fit-content;
            font-size: 13px;
            color: var(--lighterBg);
            padding: 3px 10px;
            border-radius: var(--wrapperBorderRadius);

            &.hold {
              background-color: var(--shipmentYellow);
            }

            &.active {
              background-color: var(--shipmentGreen);
            }

            &.denied {
              background-color: var(--shipmentRed);
            }
          }
        }

        .my-wallet-arrangement-bill-modal {
          background-color: var(--lighterBg);
        }
      }
    }
  }
}
