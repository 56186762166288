@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");


#etsy {
	align-items: center;
	display: flex;
	justify-content: center;
	gap: var(--gap1x);


	.get-start {
		.field {
			display: flex;
			flex-direction: row;
			gap: 170px;
			justify-content: center;


			.get-start-text {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				gap: var(--gap3x);
				margin-top: 50px;
				max-width: 1000px;
				position: relative;
				width: 100%;


				h1 {
					display: flex;
					align-items: center;
					text-align: center;
					justify-content: center;
					width: 100%;
					color: var(--btnBgBlue);
					font-size: 40px;
					font-weight: bold;
					height: auto;
					line-height: normal;
					margin-bottom: 15px;
					margin-top: 50px;
				}


				p {
					color: #4f4f4f;
					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					line-height: 30px;
					text-align: center;
				}


				.main-header-text-buttons {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: var(--gap2x);
					margin-bottom: 20px;


					a {
						padding: 9px 14px;
						min-width: 200px;
						font-weight: 700;
						display: flex;
						justify-content: center;
						align-items: center;
						text-decoration: none;
						height: 49.927px;
						gap: 10px;
						font-size: 14px;
						line-height: 18px;
					}
				}
			}
		}
	}


	.main-header {
		display: flex;
		gap: 100px;
		justify-content: space-between;
		max-width: 1440px;
		padding: 20px 20px 0;
		width: 100%;


		.main-header-text {
			display: flex;
			flex-direction: column;
			gap: var(--gap3x);
			margin-top: 50px;
			max-width: 500px;
			position: relative;
			width: 100%;
			z-index: 2;


			h1 {
				font-size: 35px;
				font-weight: 400;
				height: auto;
				line-height: normal;
				margin-top: 50px;
			}


			p {
				color: #4f4f4f;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 115.023%;
			}


			.main-header-text-buttons {
				display: flex;
				gap: var(--gap2x);
			}
		}


		.main-header-img {
			width: 100%;


			img {
				margin-top: 40px;
				max-width: 661px;
				width: 100%;
			}
		}
	}


	.main-info {
		display: flex;
		flex-direction: column;
		gap: var(--gap5x);
		max-width: 1440px;
		padding: 40px 20px;
		width: 100%;


		h2 {
			color: var(--btnBgBlue);
			font-size: 40px;
			font-weight: 600;
			line-height: 45px;
			text-align: center;


			span {
				color: inherit;
				font-size: inherit;
				font-weight: 800;
				line-height: inherit;
			}
		}


		.info-container {
			display: flex;
			gap: var(--gap5x);
			justify-content: center;
			margin: 0 60px;


			.step-header {
				text-align: center;
				width: 100%;


				h2 {
					font-size: 30px;
				}


				p {
					font-size: 17px;
					text-align: center;
				}
			}


			&:first-child {
				background-color: #F6F6F6;
				border-radius: 20px;
				max-width: 1600px;
				margin: 0;
				width: 100%;
				margin: 0 auto;


				p {
					text-align: center;
				}
			}


			&:nth-child(odd) {
				flex-direction: row;
			}


			&:nth-child(2n) {
				flex-direction: row-reverse;
			}


			.info-image {
				display: flex;
				width: 100%;


				img {
					border-radius: var(--inputBorderRadius);
					object-fit: contain;
					object-position: center;
					width: 100%;
				}
			}


			.main-header-text-buttons {
				display: flex;
				gap: var(--gap2x);
				justify-content: center;
				margin: 10px auto;


				a {
					padding: 9px 14px;
					min-width: 200px;
					font-weight: 700;
					display: flex;
					justify-content: center;
					align-items: center;
					text-decoration: none;
					height: 49.927px;
					gap: 10px;
					font-size: 14px;
					line-height: 18px;
				}
			}


			.info-price {
				background-color: #334155;
				width: 100%;
				table-layout: fixed;
				padding-top: 100px;
				padding-bottom: 40px;
				border-radius: 15px;


				.info-content {
					display: flex;
					justify-content: center;
					align-items: flex-start;


					.info-text {
						width: 50%;
						margin: 10px auto;


						h2 {
							color: var(--btnBgBlue);
							font-size: 35px;
							font-style: normal;
							font-weight: 600;
							letter-spacing: -1.33px;
							line-height: 45px;
							margin-bottom: 15px;
							text-align: start;
							margin-left: 20px;
						}


						p {
							color: white;
							font-size: 20px;
							font-style: normal;
							font-weight: 400;
							line-height: 35px;
							margin-left: 20px;
						}
					}
				}


				.main-price-get-card {
					border-color: #334155;
					margin: 20px auto;
				}


				button {
					display: flex;
					align-content: center;
					margin: 0 auto;
				}
			}


			.price-form-container {
				padding-right: 55px;
			}


			.price-form {
				form {
					display: flex;
					flex-direction: column;
					-webkit-text-fill-color: white;


					button {
						margin: 0 auto;
					}


					div {
						margin-bottom: 5px;
					}


					fieldset {
						border-color: rgba(255, 255, 255, 0.23);
					}
				}


				input {
					-webkit-text-fill-color: white;
				}
			}


			.last {
				width: 60%;
			}


			.domestic-select {
				max-width: 1400px;
			}


			.info {
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-top: 35px;
				max-width: 621px;
				padding: 0 30px 30px;
				width: 100%;


				.info-buttons {
					display: flex;
					flex-wrap: wrap;
					gap: 20px;
					margin-top: 20px;
				}


				h2 {
					color: var(--btnBgBlue);
					font-size: 35px;
					font-style: normal;
					font-weight: 600;
					line-height: 47.5px;
					margin-bottom: var(--gap2x);
					text-align: left;
				}


				p {
					color: #4f4f4f;
					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					line-height: 35px;
					padding: 0;
					margin: 20px 0;


					span {
						color: #007fff;
						color: var(--mavi, #007fff);
						color: var(--mavi, #007fff);
						font-family: Inter;
						font-size: 20px;
						font-style: normal;
						font-weight: 700;
						line-height: 28px;
						text-decoration-line: underline;
					}
				}
			}
		}


		.info-container-brief {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 40px;
			width: 100%;


			.info-container {
				p {
					color: #4f4f4f;
					font-size: 20px;
				}
			}
		}
	}


	.main-ishowitwork-frame {
		.ishowitwork-frame {
			max-width: 1440px;
			width: 100%;


			h2 {
				color: #007fff;
				font-family: Inter;
				font-size: 40px;
				font-style: normal;
				font-weight: 600;
				line-height: 45px;
				padding-bottom: 30px;
				padding-top: 20px;
				text-align: center;
			}


			p {
				color: #4f4f4f;
				display: flex;
				font-family: Inter;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				justify-content: center;
				line-height: 120.023%;
				margin-bottom: 30px;
				max-width: 1016px;
				text-align: center;
				width: 100%;
			}


			.button-container {
				display: flex;
				gap: var(--gap2x);
				justify-content: center;
				margin-bottom: -130px;
				margin-top: 90px;


				a {
					padding: 9px 14px;
					min-width: 200px;
					font-weight: 700;
					display: flex;
					justify-content: center;
					align-items: center;
					text-decoration: none;
					height: 49.927px;
					gap: 10px;
					font-size: 14px;
					line-height: 18px;
				}


				.righ-btn {
					background-color: var(--lighterBg);
					border: 1px solid var(--btnBgBlue);
					border-radius: var(--wrapperBorderRadius);
					color: var(--btnBgBlue);
					text-transform: none;


					&:hover {
						background-color: var(--btnBgBlue);
						border: 1px solid var(--lighterBg);
						color: var(--lighterBg);
					}
				}


				.left-btn {
					background-color: var(--btnBgBlue);
					border: 1px solid #84c1ff;
					border-radius: var(--wrapperBorderRadius);
					color: var(--lighterBg);
					text-transform: none;
					transition: all .15s linear;


					&:hover {
						background-color: var(--lighterBg);
						border: 1px solid var(--btnBgBlue);
						color: var(--btnBgBlue);
					}
				}
			}
		}


		.grid-container-wrapper {
			.background {
				background-color: #f0f2f5;
				height: 340px;
				left: 0;
				margin-top: 70px;
				position: absolute;
				width: 100%;
				z-index: -1;
			}


			.grid-container {
				grid-gap: 20px;
				display: grid;
				gap: 20px;
				grid-template-columns: repeat(4, 300px);
				grid-template-columns: repeat(4, 300px);
				height: 313px;
				justify-content: center;
				margin: 0 auto;
				max-width: 800px;
				position: relative;
				z-index: 1;


				.grid-item {
					background: #fff;
					background: var(--base-0, #fff);
					background: var(--base-0, #fff);
					border: 1px solid #e3e3e3;
					border: 1px solid var(--base-20, #e3e3e3);
					border: 1px solid var(--base-20, #e3e3e3);
					border-radius: 20px;
					height: 370px;
					padding: 20px;
					text-align: center;


					img {
						margin-bottom: 15px;
					}


					.grid-item-bottom {
						align-items: center;
						display: flex;
						flex-direction: column;
						justify-content: center;


						h3 {
							color: #007fff;
							font-family: Inter;
							font-size: 24px;
							font-style: normal;
							font-weight: 600;
							line-height: 125%;
							text-align: center;
						}


						p {
							color: #626262;
							color: var(--base-80, #626262);
							color: var(--base-80, #626262);
							font-family: Inter;
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 150%;
							margin-top: 11px;
							text-align: center;
							width: 208px;
						}
					}
				}
			}
		}
	}


	.main-frame {
		gap: var(--gap2x);
		overflow: hidden;


		.frame-wrapper {
			gap: var(--gap3x);
			max-width: 1440px;
			padding: 60px 30px 30px;
			position: relative;


			.frame-left {
				left: 0;
				position: absolute;
				top: -100px;
				z-index: 0;
			}


			.frame-right {
				position: absolute;
				right: 0;
				top: -200px;
				z-index: 0;
			}


			h2 {
				color: var(--btnBgBlue);
				font-size: 35px;
				font-weight: 600;
				margin-bottom: 15px;
				max-width: 100%;
				text-align: center;
				z-index: 1;
			}


			.iframe-wrapper {
				display: flex;
				justify-content: center;
				max-width: 613px;
				width: 100%;


				.iframe-container {
					overflow: hidden;
					padding-top: 56.25%;
					position: relative;
					width: 100%;


					.iframe {
						border: none;
						border-radius: var(--buttonBorderRadius);
						bottom: 0;
						height: 100%;
						left: 0;
						position: absolute;
						right: 0;
						top: 0;
						width: 100%;
					}
				}
			}


			button {
				margin-bottom: var(--gap2x);
			}
		}
	}


	.main-faq {
		width: 100%;
		margin: auto;
		padding: 40px 20px;
		max-width: 1280px;


		h2 {
			color: var(--btnBgBlue);
			font-size: 36px;
			line-height: 45px;
			margin-top: 30px;
			margin-bottom: 50px;
			text-align: center;
			font-weight: 600;
		}


		.faq-item {
			margin-bottom: 10px;
			padding: 10px 0;


			div {
				color: #061027;
				font-family: Inter;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				letter-spacing: -.9px;
				line-height: 34px;
				text-align: start;
			}


			.question {
				display: flex;
				justify-content: space-between;
				cursor: pointer;
				margin-left: 5px;


				span {
					margin-right: 5px;
					font-size: 20px;
					font-weight: 600;
				}


				&.active {
					font-weight: bold;
					margin-right: 3px;
					font-size: 20px;


					.toggle {
						content: '-';
					}
				}
			}


			.answer {
				display: none;
				padding-top: 10px;
				margin-left: 5px;


				ol {
					margin-left: 21px;
				}
			}
		}
	}


	.main-get-finish {
		.insider {
			flex-direction: row;
			margin-bottom: -70px;
			max-width: 1440px;


			.get-finish-headline {
				display: flex;
				flex-direction: column;
				gap: var(--gap3x);
				justify-content: center;
				max-width: 576px;
				padding-bottom: 55px;
				width: 100%;


				h6 {
					color: #007fff;
					font-size: 36px;
					font-weight: 600;
					line-height: 38px;
					margin-bottom: 15px;
				}


				p {
					color: #4f4f4f;
					font-family: Inter;
					font-size: 20px;
					font-weight: 400;
					line-height: 30px;
				}


				.headline-links {
					align-items: center;
					display: flex;
					gap: var(--gap2x);


					a {
						padding: 9px 14px;
						min-width: 200px;
						font-weight: 700;
						display: flex;
						justify-content: center;
						align-items: center;
						text-decoration: none;
						height: 49.927px;
						gap: 10px;
						font-size: 14px;
						line-height: 18px;
					}
				}
			}


			.get-finish-mockup {
				display: flex;
				max-width: 800px;
				padding-top: 75px;
				position: relative;
				width: 100%;


				.get-finish-mockup-text-wrapper {
					display: flex;
					flex-direction: column;
					gap: var(--gap);
					margin-top: 40px;
					max-width: 288px;


					.get-finish-mockup-text {
						align-items: center;
						-webkit-backdrop-filter: blur(8px);
						backdrop-filter: blur(8px);
						background: #ffffffe6;
						border: 1px solid #fff;
						border: 1px solid var(--white, #fff);
						border: 1px solid var(--white, #fff);
						border-radius: 8px;
						display: flex;
						gap: var(--gap);
						padding: 5px;
						width: 100%;
					}
				}


				.get-finish-mockup-img {
					height: 315px;
					position: absolute;
					right: 150px;
					top: 146px;
				}


				.get-finish-laptop {
					height: 550px;
					width: 100%;
					z-index: -99;
				}
			}
		}
	}
}

#etsy,
#etsy .get-start {
	flex-direction: column;
}

#etsy {
	.main-header {
		.down-btn {
			align-items: center;
			display: flex;
			justify-content: center;
			margin-top: 80px;
			text-align: center;
			width: 100%;
		}
	}
}


@media (max-width: 768px) {
    #etsy {
        .get-start {
			padding-top: 0;
            .field {
				margin-top: 0;
                .get-start-text {
                    h1 {
                        font-size: 30px;
						margin-top: 0;
                    }

                    p {
                        font-size: 14px;
                    }

                    .main-header-text-buttons {
                        flex-direction: column;
                        
                        a {
							margin-bottom: 10px;
                            min-width: 200px;
							font-weight: 700;
							box-sizing: border-box;
                        }
                    }
                }
            }
        }

        .main-info {
			min-height: 1150px;
			
            .info-container {
                margin: 0 20px;
				flex-direction: column;
				&:nth-child(2n+1){
					flex-direction: column;
					h2 {
                        font-size: 30px;
                    }

                    p {
                        font-size: 14px;
                    }
				}
                
                .info-image {
					margin-bottom: 100px;
                    img {
                        max-width: 100%;
                    }
                }

                .main-header-text-buttons {
                    flex-direction: column;
                    gap: var(--gap1x);

                    a {
						margin-bottom: 10px;
                        min-width: 200px;
                    }
                }

                .info-price {
                    padding-top: 50px;
                    padding-bottom: 20px;

                    .info-content {
                        .info-text {
                            h2 {
                                font-size: 30px;
                            }

                            p {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }

        .main-ishowitwork-frame {
			min-height: 1950px;
			margin-bottom: 0;
            .ishowitwork-frame {
				h2{
					margin-bottom: 660px;
					font-size: 30px;
					padding-top: 0;
				}
				.grid-container-wrapper{
					min-height: 960px;

					.grid-container{
						display:flex;
						flex-direction: column;
						.grid-item{
							margin: auto 40px;
							height: 400px;
							img {
								width: 100%;
							}
							.grid-item-bottom {
								h2 {
									font-size: 24px;
								}
								p {
									font-size: 12px;
								}
							}
						}
					}
				}
				.button-container{
					margin: 0 !important;
				}
            }
        }

		#main-info {
			min-height: 4100px;
			padding-top: 0;
            .info-container {
                margin: 0 20px;
				flex-direction: column;
				&:nth-child(2n+1), :nth-child(2n) {
					flex-direction: column;
					h2 {
                        font-size: 30px;
                    }

                    p {
                        font-size: 16px;
                    }
				}
                
                .info-image {
					margin-bottom: 40px;
                    img {
                        max-width: 100%;
                    }
                }

                .main-header-text-buttons {
                    flex-direction: column;
                    gap: var(--gap1x);

                    a {
						margin-bottom: 10px;
                        min-width: 200px;
                    }
                }

                .info-price {
					padding-top: 10px;
					margin-top: 20px;
                    .info-content {
						display: flex;
						flex-direction: column;
                        .info-text {
							width: 100%;
							padding: 10px;
                            h2 {
                                font-size: 30px;
                            }

                            p {
                                font-size: 14px;
                            }
                        }
						.price-form-container{
							padding-right: 0;
							.price-form {
								justify-content: center;
								margin: 10px;
								form{
									div{
										flex-direction: row;
										div{
											width: 100%;
											div{
												align-items: start;
												input {
													min-width: 110px;
												}
											}
										}
									}
								}
								
							}
						}
                    }
                }
				&:nth-child(5) {
					h2 {
						margin-top: 70px;
					}
				}
				&:nth-child(6) {
					.info-image {
						margin-top: 90px;
					}
					.info {
						margin-top: 0;
					}
				}
            }
        }

		.main-faq{
			h2{
				margin-top: 0;
				margin-bottom: 20px;
			}
		}
        .main-get-finish {
			min-height: 500px;
            .insider {
				.get-finish-headline {
					margin-bottom: 0;
					padding-bottom: 0;
				}
				.get-finish-mockup {
					padding-top: 0;					
					.get-finish-laptop {
						height: 300px;
					}
				}
			}
        }
    }
}