.dashboard {
  min-height: 100vh;
  background-color: var(--background);

  .dashboard-wrapper {
    .pie {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(610px, 1fr)) !important;
      grid-template-rows: minmax(250px, 5fr) !important;
      gap: var(--gap2x);
      justify-content: center;
      max-width: 1240px;
      margin: auto;
      @media (max-width: 1562px) {
        grid-template-columns: repeat(2, minmax(320px, 1fr)) !important;
      }
      @media (max-width: 980px) {
        grid-template-columns: repeat(1, minmax(320px, 1fr)) !important;
      }
      @media (max-width: 660px) {
        grid-template-columns: repeat(1, minmax(320px, 1fr)) !important;
      }
      @media (max-width: 468px) {
        grid-template-columns: repeat(1, minmax(170px, 1fr)) !important;
      }
      .pie-counts {
        display: flex;
        flex-direction: column;
        gap: var(--gap2x);
      }
      .dashboard-shipment-pie {
        width: 100%;
        height: 100%;
        margin: auto;
        .pie-chart {
          padding: 30px;
          background-color: var(--lighterBg);
          border-radius: 5px;
          box-shadow: var(--shadow);
          height: 100%;
          position: relative;
          transition: all 0.3s ease;
          .pie-chart-header {
            padding-bottom: 30px;
            .pie-text-total {
              color: var(--priceText);
              font-size: var(--normalTextSize);
              font-weight: 600;
            }
            .pie-text-total-detailed {
              color: var(--btnBgBlue);
              font-size: 12px;
              margin-top: 10px;
              &:hover {
                cursor: pointer;
                text-decoration: underline;
              }
            }
          } 
          .pie-chart-footer {
            display: flex;
            justify-content: space-between;
            .count-button {
              button {
                padding: 0;
                font-size: 12px;
                color: #007fff;
                font-weight: 600;
                text-transform: capitalize;
                background: none;
                border: none;
                cursor: pointer;
              }
            }
          }
          .pie-modal-st {
            padding: 30px;
            background-color: var(--lighterBg);
            border-radius: 5px;
            box-shadow: var(--shadow);
            height: 100%;
          }
        }
        .pie-chart.expanded {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 70%;
          height: fit-content;
          z-index: 1000;
        }
        canvas {
          width: 100% !important;
          height: fit-content !important;
        }
      }
    }
    .dashboard-shipment-count {
      margin-bottom: 1rem !important;
      h3 {
        color: #7c7c7a;
        font-size: 30px;
        text-align: left;
      }
      .shipment-count-wrapper {
        display: grid;
        grid-template-columns: repeat(2, minmax(610px, 320px));
        grid-template-rows: minmax(95px, 1fr);
        gap: var(--gap2x);
        justify-content: center;
        margin-bottom: 20px;
        @media (max-width: 468px) {
          grid-template-columns: repeat(1, minmax(170px, 1fr));
        }
        @media (max-width: 660px) {
          grid-template-columns: repeat(1, minmax(320px, 1fr));
        }

        @media (max-width: 1317px) {
          grid-template-columns: repeat(2, minmax(160px, 1fr));
          margin-top: 20px;
        }

        @media (max-width: 1600px) {
          grid-template-columns: repeat(2, minmax(160px, 1fr));
          margin-top: 20px;
        }

        .shipment-count-container {
          display: flex;
          background-color: var(--lighterBg);
          box-shadow: var(--shadow);
          justify-content: space-between;
          align-items: center;
          min-height: 95px;
          gap: 20px;
          padding: 23px;
          border-radius: 5px;
          @media (max-width: 1317px) {
            width: 100% !important;
          }

          .shipment-count-text {
            display: grid;
            gap: 5px;
            width: 100%;
            .shipment-count-text-total {
              color: var(--priceText);
              font-size: var(--normalTextSize);
              font-weight: 600;
            }

            .shipment-count-text-number {
              font-size: var(--gap4x);
              font-weight: 500;
              @media (max-width: 468px) {
                font-size: 25px;
              }
            }

            .shipment-count-text-footer {
              display: flex;
              justify-content: space-between;
              width: 100%;
              @media (max-width: 468px) {
                flex-direction: column;
                gap: 15px;
              }
              .shipment-count-text-time {
                color: var(--grayCount);
                font-size: 10px;
              }
              .count-button {
                padding: 0;
                font-size: 12px;
                color: #007fff;
                font-weight: 600;
                text-transform: capitalize;
                background: none;
                border: none;
                cursor: pointer;
              }
            }

            .yellow {
              color: var(--shipmentYellow);
            }

            .blue {
              color: var(--shipmentBlue);
            }

            .red {
              color: var(--shipmentRed);
            }

            .green {
              color: #86b817;
            }
          }
        }
      }
      .header {
        max-width: 1240px;
        padding: 0px 0px 20px 0px;
        margin: auto;
        @media (max-width: 468px) {
          padding: 10px 0px;
        }
        h3 {
          @media (max-width: 468px) {
            font-size: 25px;
          }
        }
      }
    }

    .dashboard-shipment-line-wealth {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(610px, 1fr));
      justify-content: center;
      @media (max-width: 1570px) {
        grid-template-columns: repeat(2, minmax(320px, 1fr));
      }
      @media (max-width: 660px) {
        grid-template-columns: repeat(1, minmax(320px, 1fr));
      }
      @media (max-width: 468px) {
        grid-template-columns: repeat(1, minmax(320px, 1fr));
      }
      .line-chart {
        width: 100%;
        padding: 30px;
        margin: 20px auto;
        background-color: var(--lighterBg);
        border-radius: 5px;
        box-shadow: var(--shadow);
        .line-chart-header {
          .line-chart-header-worth {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
          }
          .shipment-count-text-total {
            color: var(--priceText);
            font-size: var(--normalTextSize);
            font-weight: 600;
          }
          .shipment-count-text-total-detailed {
            color: var(--btnBgBlue);
            font-size: 12px;
            margin-top: 10px;
            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
        .line-chart-footer {
          display: flex;
          justify-content: flex-end;
          .count-button {
            padding: 0;
            font-size: 12px;
            color: #007fff;
            font-weight: 600;
            text-transform: capitalize;
            background: none;
            border: none;
            cursor: pointer;
          }
        }
        canvas {
          width: 100% !important;
        }
      }
    }

    .dashboard-shipment-line {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(610px, 1fr));
      justify-content: center;
      max-width: 1240px;
      margin: auto;
      @media (max-width: 1570px) {
        grid-template-columns: repeat(1, minmax(320px, 1fr));
      }
      @media (max-width: 660px) {
        grid-template-columns: repeat(1, minmax(320px, 1fr));
      }
      @media (max-width: 468px) {
        grid-template-columns: repeat(auto-fit, minmax(345px, 1fr));
      }
      .line-chart {
        padding: 30px;
        margin: 0px auto 20px;
        background-color: var(--lighterBg);
        border-radius: 5px;
        width: 100%;
        box-shadow: var(--shadow);
        @media (max-width: 1317px) {
          margin: 0px auto 0px;
        }

        .line-chart-header {
          .line-chart-header-worth {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
          }
          .shipment-count-text-total {
            color: var(--priceText);
            font-size: var(--normalTextSize);
            font-weight: 600;
          }
          .shipment-count-text-total-detailed {
            color: var(--btnBgBlue);
            font-size: 12px;
            margin-top: 10px;
            margin-bottom: 10px;
            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
        .line-chart-footer {
          display: flex;
          justify-content: flex-end;
          .count-button {
            padding: 0;
            font-size: 12px;
            color: #007fff;
            font-weight: 600;
            text-transform: capitalize;
            background: none;
            border: none;
            cursor: pointer;
          }
        }
        canvas {
          width: 100% !important;
        }
      }
    }
    .dashboard-shipment-line-close {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(610px, 2fr));
      justify-content: center;
      max-width: 1500px;
      @media (max-width: 660px) {
        grid-template-columns: repeat(1, minmax(320px, 1fr));
      }
      @media (max-width: 468px) {
        grid-template-columns: repeat(1, minmax(320px, 1fr));
      }
      .line-chart {
        width: 100%;
        padding: 30px;
        margin: 0px auto 20px;
        background-color: var(--lighterBg);
        border-radius: var(--inputBorderRadius);
        box-shadow: var(--shadow);
        .line-chart-header {
          .shipment-count-text-total {
            color: var(--priceText);
            font-size: var(--normalTextSize);
            font-weight: 600;
          }
          .shipment-count-text-total-detailed {
            color: var(--btnBgBlue);
            font-size: 12px;
            margin-top: 10px;
            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }

        canvas {
          width: 100% !important;
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: var(--gap2x);
      max-width: 1440px;
      margin: 0 auto;

      .info-item {
        background-color: var(--lighterBg);
        box-shadow: var(--shadow);
        border-radius: var(--wrapperBorderRadius);
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: var(--gap2x);

        h2 {
          font-size: 20px;
          color: var(--priceText);
        }

        .cargo {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          justify-items: center;

          .cargo-item {
            box-shadow: var(--shadow);
            border-radius: var(--wrapperBorderRadius);
            padding: 20px;
            max-width: 317px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: var(--gap);
            align-items: center;
            justify-content: center;
          }
        }

        .bank {
          max-width: 378px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          box-shadow: var(--shadow);
          border-radius: var(--wrapperBorderRadius);
          align-self: center;

          img {
            max-width: 257px;
            width: 100%;
          }

          .bank-text {
            display: flex;
            flex-direction: column;
            gap: var(--gap);

            span {
              text-align: center;
              font-size: 16px;
              color: var(--priceText);

              svg {
                width: 16px;
                height: 16px;
                cursor: pointer;
              }

              &:nth-child(3) {
                font-size: 13px;
              }
            }
          }
        }

        .info-address {
          flex-direction: column;
          align-items: flex-start;

          .info-address-iframe {
            border: 0;
            width: 100%;
            max-width: 800px;
            min-height: 300px;
            border-radius: var(--wrapperBorderRadius);
            box-shadow: var(--shadow);
            margin: 0 auto;
          }

          .info-address-text {
            display: flex;
            flex-direction: column;
            gap: var(--gap);

            span {
              font-size: 16px;
              color: var(--priceText);
              max-width: 286px;
              width: 100%;

              &:first-child {
                font-weight: 700;
              }

              &:last-child {
                font-size: 14px;
              }
            }
          }
        }

        @media (max-width: 900px) {
          .cargo {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            justify-items: center;
          }

          .info-address {
            flex-direction: column;
            align-items: flex-start;
          }
        }

        @media (max-width: 400px) {
          .cargo {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            justify-items: center;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .dashboard {
    .dashboard-wrapper {
      .dashboard-shipment-count {
        .shipment-count-wrapper {
          grid-template-columns: repeat(1, 1fr);

          .shipment-count-container {
            flex-direction: column;

            .shipment-count-text {
              margin: auto;
            }

            .shipment-count-image {
              margin: auto;
            }
          }
        }
      }
    }
  }
}
