.connected-payments {
  outline: 0;
  max-width: 1100px;
  margin: 0 auto;
}
.connected-payments .connected-payments-headline {
  display: flex;
  align-items: center;
  font-weight: 700;
  gap: var(--gap);
  padding: 20px;
}
.connected-payments .desktop-table {
  display: table;
}
.connected-payments .mobile-table {
  display: none;
}
@media (max-width: 650px) {
  .connected-payments .desktop-table {
    display: none;
  }
  .connected-payments .mobile-table {
    display: table;
  }
}/*# sourceMappingURL=ConnectedPayments.css.map */