.page-header-title {
  background-color: white;
  box-shadow: var(--shadow);
  margin: -20px 0 20px -20px;
}
.page-header-title .page-header-title-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  gap: var(--gap2x);
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.page-header-title .page-header-title-wrapper .page-header-title-headline {
  gap: var(--gap);
  display: flex;
  align-items: center;
  color: var(--priceText);
}
.page-header-title .page-header-title-wrapper .page-header-title-headline h1 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
}
.page-header-title .page-header-title-wrapper .page-header-title-headline .page-header-title-span {
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  color: var(--btnBgBlue);
}
.page-header-title .page-header-title-wrapper .page-header-title-how-link {
  border: 1px solid var(--btnBgBlue);
  color: var(--btnBgBlue);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: var(--buttonBorderRadius);
  cursor: pointer;
  transition: all 150ms linear;
}
.page-header-title .page-header-title-wrapper .page-header-title-how-link:hover {
  box-shadow: var(--shadow);
}
.page-header-title .page-header-title-wrapper .page-header-title-how-link svg {
  width: 16px;
  height: 16px;
}
.page-header-title .page-header-title-wrapper .page-header-title-how-link span {
  font-size: 16px;
}

@media (max-width: 768px) {
  .page-header-title .page-header-title-wrapper {
    gap: var(--gap);
  }
  .page-header-title .page-header-title-wrapper .page-header-title-headline h1 {
    font-size: 16px;
    line-height: 20px;
  }
  .page-header-title .page-header-title-wrapper .page-header-title-headline .page-header-title-span {
    font-size: 16px;
    line-height: 20px;
  }
  .page-header-title .page-header-title-wrapper .page-header-title-how-link svg {
    width: 13px;
    height: 13px;
  }
  .page-header-title .page-header-title-wrapper .page-header-title-how-link span {
    font-size: 13px;
  }
}/*# sourceMappingURL=PageHeaderTitle.css.map */