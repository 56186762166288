.shipment-dimension-modal {
  max-width: 1100px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.shipment-dimension-modal .shipment-dimension-modal-form {
  width: 100%;
  height: auto;
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--inputBorderRadius);
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
  outline: 0;
}
.shipment-dimension-modal .shipment-dimension-modal-form .shipment-dimension-modal-form-headline {
  display: flex;
  gap: var(--gap);
}
.shipment-dimension-modal .shipment-dimension-modal-form .shipment-dimension-modal-form-headline input {
  width: 100%;
}
.shipment-dimension-modal .shipment-dimension-modal-form .shipment-dimension-modal-form-inputs {
  display: flex;
  justify-content: space-between;
  gap: var(--gap);
}
.shipment-dimension-modal .shipment-dimension-modal-form .shipment-dimension-modal-form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: var(--gap2x);
}

@media (max-width: 600px) {
  .shipment-dimension-modal .shipment-dimension-modal-form .shipment-dimension-modal-form-inputs {
    flex-direction: column;
    gap: var(--gap2x);
  }
}/*# sourceMappingURL=ShipmentDimensionModal.css.map */