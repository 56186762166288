.myShipments {
  min-height: 100vh;
  background-color: var(--background);

  .myShipments-wrapper {
    display: grid;
    width: 100%;
    gap: var(--gap2x);

    .myShipments-search {
      border-radius: var(--inputBorderRadius);
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      .search-card {
        width: 100%;

        .myShipments-detailed-search-wrapper {
          .myShipments-detailed-search-title {
            color: var(--priceText);
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            gap: var(--gap);
          }

          .myShipments-detailed-search {
            display: grid;
            grid-template-columns: repeat(5, minmax(200px, 1fr));
            gap: var(--gap3x) var(--gap);
          }

          .myShipments-detailed-search-done {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-top: var(--gap2x);

            button {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 5px;
              background-color: var(--info);
              color: var(--lighterBg);
              transition: all 150ms linear;
              text-transform: none;
              padding: 5px 20px;

              svg {
                width: 20px;
                height: 20px;
              }

              &:hover {
                background-color: var(--blueBrand);
                transition: all 150ms linear;
              }
            }
          }
        }

        @media (max-width: 1375px) {
          .myShipments-detailed-search {
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
            gap: var(--gap2x) var(--gap) !important;
          }
        }

        @media (max-width: 1150px) {
          .search-card-content {
            flex-direction: column;

            .search-wrapper {
              .search-card-content-status {
                width: 100%;
              }
            }
          }
        }

        @media (max-width: 460px) {
          .search-card-content {
            .search-wrapper {
              flex-direction: column;
            }
          }
        }

        .search-card-content {
          display: flex;
          gap: var(--gap);
          padding: 20px;

          .search-card-content-search {
            width: 100%;

            label {
              color: var(--priceText);
            }
          }

          .search-card-content-handler-button {
            text-transform: none;
            background-color: var(--info);
            color: var(--lighterBg);
            font-size: 14px;
            width: 200px;
            height: 40px;
          }
        }
      }
    }

    .myShipments-list {
      display: grid;
      width: 100%;
      gap: var(--gap2x);

      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      .myShipments-list-selectors {
        display: flex;
        justify-content: space-between;
        gap: var(--gap);
        box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
        padding: 20px;
        border-radius: 10px;
        background: var(--lighterBg);

        .list-selectors-position {
          display: flex;
          align-items: center;
          gap: var(--gap);

          .selectors-button {
            background-color: var(--info);
            text-transform: none;
            transition: all 150ms linear;
            color: var(--lighterBg);
            height: 40px;
            border-radius: var(--buttonBorderRadius);
            gap: 5px;
            font-size: 12px;

            &:hover {
              background-color: var(--blueBrand);
              transition: all 150ms linear;
            }
          }

          .selector-limit {
            label {
              color: var(--priceText);
            }

            svg {
              color: var(--priceText);
            }
          }

          .MuiFormGroup-root {
            width: 155px;

            .selector-switch-all {
              .MuiTypography-root {
                font-size: 12px;
              }
            }
          }
        }

        .list-selectors-position-wrapper {
          display: flex;
          gap: var(--gap);

          .list-selectors-position {
            display: flex;
            align-items: center;
            gap: var(--gap);

            .selectors-button {
              background-color: var(--info);
              text-transform: none;
              transition: all 150ms linear;
              color: var(--lighterBg);
              height: 40px;
              border-radius: var(--buttonBorderRadius);
              gap: 5px;

              &:hover {
                background-color: var(--blueBrand);
                transition: all 150ms linear;
              }
            }

            .selector-limit {
              label {
                color: var(--priceText);
              }

              svg {
                color: var(--priceText);
              }
            }
          }
        }
      }

      .myShipments-list-wrapper {
        background-color: var(--lighterBg);
        padding: 20px;
        border-radius: var(--inputBorderRadius);
        display: flex;
        position: relative;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

        .list-picker-wrapper {
          position: absolute;
          top: 5px;
          right: 5px;
          display: flex;
          align-items: center;

          .list-options {
            border-radius: 50%;
            width: 33px;
            height: 33px;
          }

          @media (min-width: 1150px) {
            .list-options {
              display: none;
            }
          }
        }

        .list-steps {
          width: 100%;
          display: grid;
          align-content: space-around;
          gap: var(--gap);

          .support-desktop-wrapper {
            display: flex;
            gap: var(--gap2x);
            align-items: center;

            .support-desktop {
              padding: 10px;
              border-radius: var(--wrapperBorderRadius);
              display: flex;
              justify-content: center;
              align-items: center;
              text-decoration: none;
              background-color: var(--shipmentYellow);
              color: var(--lighterBg);
              gap: 5px;
              width: 100%;
              height: 35px;
            }

            @media (min-width: 1150px) {
              .support-desktop {
                display: none;
              }
            }
          }

          .list-steps-headline {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            @media (min-width: 1150px) {
              .list-steps-parcel-ref-no {
                display: none;
              }
            }
          }

          @media (max-width: 450px) {
            .list-steps-headline {
              padding-top: 30px;
            }
          }

          .to-tracking {
            background-color: var(--background);
            padding: 5px 30px;
            border-radius: var(--buttonBorderRadius);
            font-size: 12px;
            color: var(--priceText);
            font-weight: 700;
            width: fit-content;
            margin: auto;
          }

          .steps-where-to {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            gap: var(--gap);

            .to-integration {
              text-align: center;
              width: 100px;
              height: 48px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              .to-integration-platform {
                height: inherit;
              }
            }

            .to-plane {
              display: grid;
              gap: 5px;
              text-align: center;
              font-size: 13px;

              .plane-flag {
                display: flex;
                gap: var(--gap);

                #land {
                  color: var(--shipmentGreen);
                }

                #take-off {
                  color: var(--error);
                }
              }
            }
          }

          .stepper-wrapper-css {
            display: flex;
            justify-content: space-between;
            position: relative;

            .step-wrapper-css {
              z-index: 2;

              .step-css {
                font-size: 10px;
                padding: 20px;
                border-radius: 50%;
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;

                .step-logo-css {
                  width: 34px;
                  height: 34px;
                }

                .step-shipment-logo-css {
                  width: 54px;
                  height: 54px;
                }
              }
            }

            @media (max-width: 500px) {
              .step-css {
                width: 30px !important;
                height: 30px !important;

                .step-logo-css {
                  width: 21px !important;
                  height: 21px !important;
                }

                .step-shipment-logo-css {
                  width: 30px !important;
                  height: 30px !important;
                }
              }
            }
          }

          .steps-info {
            display: flex;
            justify-content: space-between;

            .steps-info-wrapper {
              display: grid;
              gap: var(--gap);
              max-width: 280px;
              width: 100%;

              .info-top {
                color: var(--priceText);
                font-size: 14px;
                font-weight: 700;
                text-align: center;
                height: 40px;
              }

              .info-top-mobil {
                display: none;
                color: var(--priceText);
                font-size: 14px;
                font-weight: 700;
                text-align: center;
                height: 40px;
              }

              .info-bottom {
                display: flex;
                gap: var(--gap);
                justify-content: space-between;

                .info-bottom-subline {
                  color: var(--priceText);
                  font-size: 12px;
                  display: grid;
                  text-align: center;

                  .info-bottom-subline-number {
                    background-color: var(--background);
                    padding: 3px 7px;
                    border-radius: var(--buttonBorderRadius);
                    font-weight: 700;
                    font-size: 12px;
                  }
                }
              }

              @media (max-width: 550px) {
                .info-top {
                  display: none;
                }

                .info-top-mobil {
                  display: block;
                }

                .info-bottom {
                  flex-direction: column;
                }
              }
            }

            .steps-info-done {
              display: flex;
              color: var(--notRed);
              align-items: center;
              justify-content: center;
              gap: 5px;

              &.hidden {
                display: none;
              }

              .steps-info-done-price {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                font-size: 12px;
                border: 1px solid;
                padding: 10px 5px;
                border-radius: var(--buttonBorderRadius);
                font-weight: 500;

                &.paid {
                  border: 1px solid var(--notGreen);
                  color: var(--notGreen);
                }
                &.refund {
                  border: 1px solid var(--shipmentYellow);
                  color: var(--shipmentYellow);
                }
              }

              .steps-info-done-button {
                font-weight: 700;
                text-transform: none;
                transition: all 150ms linear;
                display: flex;
                gap: 5px;
                font-size: 12px;
                border-radius: var(--buttonBorderRadius);
                background-color: var(--notRed);
                color: var(--lighterBg);
                padding: 8px 16px;

                &.paid {
                  background-color: var(--notGreen);
                  color: var(--lighterBg);
                  padding: 10px 16px;

                  &:hover {
                    background-color: var(--notGreen);
                    color: var(--lighterBg);
                    padding: 10px 16px;
                  }
                }
                &.refund {
                  border: 1px solid var(--shipmentYellow);
                  color: var(--shipmentYellow);
                }

                &:hover {
                  background-color: var(--error);
                  transition: all 150ms linear;
                }
              }
            }

            @media (max-width: 1400px) {
              .steps-info-done {
                display: none;
              }
            }
          }

          .steps-info-done {
            display: none;
            color: var(--notRed);
            align-items: center;
            justify-content: center;
            gap: 5px;

            .steps-info-done-price {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;
              font-size: 12px;
              border: 1px solid;
              padding: 10px 5px;
              border-radius: var(--buttonBorderRadius);
              font-weight: 500;

              &.unpaid {
                border: 1px solid var(--notRed);
                color: var(--notRed);
              }

              &.paid {
                border: 1px solid var(--notGreen);
                color: var(--notGreen);
              }
              &.refund {
                border: 1px solid var(--shipmentYellow);
                color: var(--shipmentYellow);
              }
            }

            .steps-info-done-button {
              font-weight: 700;
              text-transform: none;
              transition: all 150ms linear;
              display: flex;
              gap: 5px;
              font-size: 12px;
              border-radius: var(--buttonBorderRadius);
              background-color: var(--notRed);
              color: var(--lighterBg);
              padding: 8px 16px;

              &.paid {
                background-color: var(--notGreen);
                color: var(--lighterBg);
                padding: 10px 16px;

                &:hover {
                  background-color: var(--notGreen);
                  color: var(--lighterBg);
                  padding: 10px 16px;
                }
              }
              &.refund {
                border: 1px solid var(--shipmentYellow);
                color: #fff;
              }

              &:hover {
                background-color: var(--error);
                transition: all 150ms linear;
              }
            }
          }

          .steps-note {
            background: var(--background);
            color: var(--priceText);
            padding: 5px 7px;
            font-size: 12px;
            border-radius: 7px;

            span {
              font-size: 12px;
              font-weight: 700;
              color: var(--priceText);
            }
          }

          @media (max-width: 1400px) {
            .steps-info-done {
              display: flex;
            }
          }
        }

        .list-links-wrapper {
          display: flex;
          align-items: center;
          min-width: 270px;
          margin: auto;

          .list-links-container {
            display: grid;
            gap: var(--gap2x);

            .list-links {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: var(--gap);

              .passive-link {
                cursor: default;
                opacity: 0.4;

                &:hover {
                  color: var(--priceText);
                }
              }

              div {
                font-size: 12px;
                display: flex;
                align-items: center;
                text-transform: none;
                cursor: pointer;
                color: var(--priceText);
                transition: all 150ms linear;

                &:hover {
                  color: var(--info);
                  transition: all 150ms linear;
                }
              }
            }

            .list-links-help {
              background-color: var(--shipmentYellow);
              gap: var(--gap);
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: none;

              svg {
                width: 20px;
                height: 20px;
                color: var(--lighterBg);
              }

              span {
                color: var(--lighterBg);
                font-weight: 700;
                font-size: 13px;
              }
            }
            .list-links-disabled {
              display: none !important;
            }
          }
        }
      }

      .list-item-checkbox {
        display: flex;
        align-items: center;
      }

      .listStyle-list-item {
        display: flex;
        align-items: center;
        gap: var(--gap);
        width: 100%;
        justify-content: space-evenly;

        .list-item-supp-message-countries {
          display: flex;
          gap: var(--gap);

          .list-item-supp-message {
            display: flex;
            align-items: center;
            gap: var(--gap);

            .list-item-supplier {
              .list-item-supplier-img {
                width: 24px;
                height: 24px;
              }
            }

            .list-item-message {
              display: flex;
              align-items: center;
              font-size: 13px;
              width: 200px;
              gap: 4px;
            }
          }

          .list-item-countries {
            display: flex;
            align-items: center;
          }
        }

        @media (max-width: 500px) {
          .list-item-supp-message-countries {
            flex-direction: column;
            align-items: center;
          }
        }

        .list-item-buttons {
          display: flex;
          gap: var(--gap);

          .list-item-buttons-wrapper {
            display: flex;
            align-items: center;
            gap: var(--gap);

            div {
              width: 100px !important;
            }

            button {
              width: 120px;
            }
          }

          .list-item-buttons-navlink {
            width: 160px;
            border-radius: var(--inputBorderRadius);
            background-color: var(--pastelRed);
            color: var(--shipmentRed);
            box-shadow: var(--shadow);
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12.25px;
            text-align: center;
            padding: 0 5px;
            transition: all 150ms linear;

            &:hover {
              color: var(--lighterBg);
              background-color: var(--shipmentRed);
              transition: all 150ms linear;
            }
          }

          .steps-info-done {
            display: flex;
            color: var(--notRed);
            align-items: center;
            justify-content: space-between;
            // border: 1px solid;
            box-shadow: var(--shadow);
            border-radius: var(--inputBorderRadius);
            padding: 5px 10px;
            width: 255px;
            gap: 5px;

            &.paid {
              color: var(--shipmentGreen) !important;

              button {
                margin-left: 17px;
                width: 181px !important;
              }
            }
            &.refund {
              // border: 1px solid var(--shipmentYellow);
              color: var(--shipmentYellow);
            }

            .steps-info-done-price {
              display: flex;
              align-items: center;
              // background-color: var(--lighterBg);

              &.paid-price {
                color: var(--shipmentGreen);
              }

              &.refund-price {
                color: var(--shipmentYellow);
              }
            }

            .paid {
              font-weight: 700;
              display: flex;
              gap: 5px;
              font-size: 12px;
              border-top-right-radius: var(--wrapperBorderRadius);
              border-bottom-right-radius: var(--wrapperBorderRadius);
              background-color: var(--shipmentGreen) !important;
              color: var(--lighterBg);
              width: 91.25px;
              height: 100% !important;
              align-items: center;
              justify-content: center;
            }

            .steps-info-done-button {
              display: flex;
              gap: 5px;
              // border-top-right-radius: var(--wrapperBorderRadius);
              // border-bottom-right-radius: var(--wrapperBorderRadius);
              // border-top-left-radius: 0 !important;
              // border-bottom-left-radius: 0 !important;
              border-radius: var(--wrapperBorderRadius);
              background-color: var(--notRed);
              color: var(--lighterBg);
              font-weight: 700;
              text-transform: none;
              transition: all 150ms linear;

              width: 150px;

              &:hover {
                background-color: var(--shipmentRed);
                transition: all 150ms linear;
              }
            }
          }
        }

        @media (max-width: 960px) {
          .list-item-buttons {
            flex-direction: column;
            align-items: center;
          }
        }
      }

      @media (max-width: 450px) {
        .wrapper-list-item {
          position: relative;

          .iyzi-button {
            position: absolute;
            top: 5px;
            left: 5px;
            z-index: 20;
          }

          .list-item-checkbox {
            position: absolute;
            top: 5px;
            left: 50px;
            z-index: 20;
          }

          .listStyle-list-item {
            margin-top: 30px;
          }

          .list-items-options {
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 20;
          }
        }
      }

      @media (max-width: 940px) {
        .myShipments-list-selectors {
          flex-direction: column-reverse;
          gap: var(--gap2x);

          .list-selectors-position-wrapper {
            justify-content: space-between;
          }
        }
      }

      @media (max-width: 1270px) {
        .list-item-checkbox {
          align-items: flex-start;
          padding-top: 5px;
        }

        .listStyle-list-item {
          flex-direction: column;
        }
      }

      @media (max-width: 1275px) {
        .myShipments-list-selectors {
          .list-selectors-position-wrapper {
            flex-wrap: wrap;

            .list-selectors-position {
              flex-wrap: wrap;
            }
          }
        }
      }

      @media (max-width: 1150px) {
        .myShipments-list-wrapper {
          flex-direction: column;

          .steps-divider {
            display: none;
          }

          .list-links-wrapper {
            padding-top: 20px;

            .list-links-container {
              display: none;
            }
            .show {
              display: flex !important;
              flex-direction: column;
              width: 100%;
              .list-links {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: var(--gap);
              }
            }
          }
        }
      }
    }

    .myShipments-list-none {
      margin: 15vh auto;
      font-size: 30px;
      font-style: italic;
    }
  }
}
