.general-info-wrapper {
  background-color: var(--background);
  min-height: 100vh;
}
.general-info-wrapper .general-info-container {
  padding-bottom: 20px;
}
.general-info-wrapper .general-info-container .general-infos {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
}
.general-info-wrapper .general-info-container .general-infos .general-info {
  background-color: var(--lighterBg);
  padding: 20px;
  width: 100%;
  border-radius: var(--wrapperBorderRadius);
}
.general-info-wrapper .general-info-container .general-infos .general-info .profile-card-wrapper {
  display: flex;
  align-items: center;
  padding: 0;
}
.general-info-wrapper .general-info-container .general-infos .general-info .profile-card-wrapper .profile-card-header {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.general-info-wrapper .general-info-container .general-infos .general-info .profile-card-wrapper .profile-card-header .profile-card-header-icon {
  width: 20px;
  height: 20px;
  color: var(--priceText);
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse {
  display: grid;
  gap: var(--gap3x);
  padding: 0 0 24px 0;
  margin-top: 40px;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information {
  display: flex;
  flex-direction: column;
  gap: var(--gap3x);
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms {
  display: flex;
  justify-content: space-between;
  gap: var(--gap2x);
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms .photo-upload-wrapper {
  display: flex;
  align-items: flex-end;
  gap: var(--gap2x);
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms .photo-upload-wrapper .photo-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--gap2x);
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms .photo-upload-wrapper .photo-upload .photo-upload-buttonbase {
  position: relative;
  width: 130px;
  height: 130px;
  cursor: pointer;
  border-radius: 50%;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms .photo-upload-wrapper .photo-upload .photo-upload-buttonbase .photo-upload-avatar {
  width: 100%;
  height: 100%;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms .photo-upload-wrapper .photo-upload .photo-upload-buttonbase .photo-edit-icon-wrapper {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: var(--btnBgBlue);
  border-radius: 50%;
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms .photo-upload-wrapper .photo-upload .photo-upload-buttonbase .photo-edit-icon-wrapper .photo-edit-icon {
  width: 17px;
  height: 17px;
  color: var(--lighterBg);
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms .photo-upload-wrapper .photo-upload .photo-upload-buttonbase .photo-upload-icon-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  border-radius: 50%;
  transition: opacity 0.3s;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms .photo-upload-wrapper .photo-upload .photo-upload-buttonbase .photo-upload-icon-wrapper:hover {
  opacity: 1;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms .photo-upload-wrapper .photo-upload .photo-upload-buttonbase .photo-upload-icon-wrapper .photo-upload-icon {
  color: var(--btnBgBlue);
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms .photo-upload-wrapper .photo-upload-buttons {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
@media (max-width: 420px) {
  .general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms .photo-upload-wrapper {
    flex-direction: column;
    align-items: center;
  }
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms .profile-options {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms .profile-options .profile-id {
  display: flex;
  align-items: center;
  gap: var(--gap);
  color: var(--btnBgBlue);
  font-weight: 600;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms .profile-options .profile-id strong {
  color: var(--darkerTextColor);
}
@media (max-width: 700px) {
  .general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms {
    flex-direction: column;
  }
  .general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-information-perms .photo-upload-wrapper {
    justify-content: center;
  }
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-inputs-form {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-inputs-form .profile-user-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--gap2x);
}
@media (max-width: 800px) {
  .general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-inputs-form .profile-user-inputs {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-button {
  display: flex;
  justify-content: flex-end;
  gap: var(--gap);
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .profile-user-information .profile-user-button span {
  font-size: 13px;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-checkboxes {
  display: flex;
  flex-direction: row;
  gap: var(--gap);
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .info-collapse-individual-form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--gap3x);
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .info-collapse-individual-form .individual-form-phone-wrapper {
  display: grid;
  align-items: baseline;
  position: relative;
  gap: var(--gap);
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .info-collapse-individual-form .individual-form-phone-wrapper .phone-checked {
  color: var(--success);
  text-align: center;
  position: absolute;
  right: 15px;
  top: 10px;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .info-collapse-individual-form .individual-form-phone-wrapper .phone-not-checked {
  color: var(--error);
  text-align: center;
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .info-vkn-form {
  box-shadow: var(--shadow);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .info-vkn-form .info-vkn-form-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--gap3x);
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .info-vkn-form .info-vkn-form-requst {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .individual-form-register {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--gap3x);
  width: 100%;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .individual-form-register button {
  padding: 7px 30px;
  background-color: var(--btnBgBlue);
  border-radius: var(--buttonBorderRadius);
  border: 1px solid var(--btnBgBlue);
  color: var(--lighterBg);
  cursor: pointer;
  transition: all 150ms linear;
  font-size: var(--smallTextSize);
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .individual-form-register button:hover {
  background-color: var(--blueBrand);
  border: 1px solid var(--blueBrand);
  transition: all 150ms linear;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .company-info-wrapper {
  display: grid;
  gap: var(--gap);
  width: 100%;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .company-info-wrapper .company-info-inputs {
  display: flex;
  gap: var(--gap);
  width: 100%;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .company-info-wrapper .company-info-dropzone {
  display: flex;
  align-items: center;
  gap: 5px;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .company-info-wrapper .company-info-dropzone .info-bold {
  font-weight: 700;
  font-size: 11px;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .company-info-wrapper .company-info-dropzone .info-text {
  color: var(--subtitleDarkBlue);
  font-weight: 300;
  font-size: 11px;
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-membership {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
  margin-top: var(--gap2x);
}
.general-info-wrapper .general-info-container .general-infos .general-info .general-info-membership .membership-types {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--gap2x);
}
@media (max-width: 800px) {
  .general-info-wrapper .general-info-container .general-infos .general-info .general-info-membership .membership-types {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 1366px) {
  .general-info-wrapper .general-info-container .general-infos .general-info {
    padding: 20px;
  }
  .general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .info-collapse-individual-form {
    gap: var(--gap2x);
  }
}
@media (max-width: 700px) {
  .general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .info-collapse-individual-form {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
  .general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .info-collapse-individual-form .individual-form-phone {
    flex-direction: column;
    gap: var(--gap2x);
  }
  .general-info-wrapper .general-info-container .general-infos .general-info .general-info-collapse .info-collapse-individual .info-collapse-individual-form-wrapper .info-collapse-individual-form .individual-form-phone .individual-form-phone-area-code {
    width: 100%;
  }
}/*# sourceMappingURL=GeneralInfo.css.map */