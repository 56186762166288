.fast-dimension-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.fast-dimension {
  background-color: var(--lighterBg);
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: var(--inputBorderRadius);
  display: flex;
  flex-direction: column;
  gap: var(--gap);

  .fast-dimension-input {
    display: flex;
    justify-content: space-between;
    gap: var(--gap);
    flex-wrap: wrap;
    width: 1100px;
  }

  .form-dimensions-weight {
    display: flex;
    gap: var(--gap);
    width: 1100px;

    div {
      width: 100%;
      border: 1px solid var(--grayText);
      border-radius: var(--buttonBorderRadius);
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 10px;
        color: var(--grayText);
      }
    }
  }

  .fast-dimension-buttons {
    display: flex;
    gap: var(--gap);
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .fast-dimension-modal {
    width: 90%;
    margin: auto;
    div{
     div{
      .receiver-address-details{
        flex-wrap: wrap;
      }
     } 
    }
  }
  .fast-dimension {
    width: 95%;
    max-height: 500px;
    overflow: auto;

    .fast-dimension-input {
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      div{
        width: 130px;
        text-align: center;
      }
    }
    .form-dimensions-weight {
      flex-direction: column;
      width: 100%;
    }
  }
}