.shipment-dimension-detail-modal {
  outline: 0;
  max-width: 1100px;
  margin: auto;
  width: 100%;
  padding: 20px;
}
.shipment-dimension-detail-modal .shipment-dimension-detail-wrapper {
  padding: 20px;
  border-radius: var(--inputBorderRadius);
  background-color: var(--lighterBg);
  outline: 0px;
}
.shipment-dimension-detail-modal .shipment-dimension-detail-wrapper .per-side-headline {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: var(--gap);
}
.shipment-dimension-detail-modal .shipment-dimension-detail-wrapper .per-side-headline.customer {
  color: var(--shipmentBlue);
}
.shipment-dimension-detail-modal .shipment-dimension-detail-wrapper .per-side-headline.iyzi {
  color: var(--shipmentRed);
}
.shipment-dimension-detail-modal .shipment-dimension-detail-wrapper .shipment-dimension-detail {
  display: flex;
  gap: var(--gap);
  width: 100%;
}
.shipment-dimension-detail-modal .shipment-dimension-detail-wrapper .shipment-dimension-detail .dimension-detail-per-side-wrapper {
  width: 100%;
}
.shipment-dimension-detail-modal .shipment-dimension-detail-wrapper .shipment-dimension-detail .dimension-detail-per-side-wrapper .dimension-detail-per-side .detail-per-side {
  border: 1px solid var(--inputBorderColor);
  padding: 10px;
  border-radius: var(--wrapperBorderRadius);
  font-size: 13px;
  box-shadow: var(--shadow);
  display: flex;
  gap: var(--gap);
  flex-direction: column;
}
.shipment-dimension-detail-modal .shipment-dimension-detail-wrapper .shipment-dimension-detail .dimension-detail-per-side-wrapper .dimension-detail-per-side .detail-per-side .per-side-subline {
  font-size: 13px;
  font-weight: 600;
}
.shipment-dimension-detail-modal .shipment-dimension-detail-wrapper .shipment-dimension-detail .dimension-detail-per-side-wrapper .dimension-detail-per-side .detail-per-side .per-side-content {
  font-size: 13px;
  font-weight: 300;
}/*# sourceMappingURL=ShipmentDimensionDetail.css.map */