.orders {
  min-height: 100vh;
  background-color: var(--background);
}
.orders .order-title {
  background-color: white;
  box-shadow: var(--shadow);
  margin: -20px 0 0 -20px;
}
.orders .order-title .order-title-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  gap: var(--gap2x);
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.orders .order-title .order-title-wrapper .order-title-headline {
  gap: var(--gap);
  display: flex;
  align-items: center;
  color: var(--priceText);
}
.orders .order-title .order-title-wrapper .order-title-headline h1 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
}
.orders .order-title .order-title-wrapper .order-title-how-link {
  border: 1px solid var(--btnBgBlue);
  color: var(--btnBgBlue);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: var(--buttonBorderRadius);
  cursor: pointer;
  transition: all 150ms linear;
}
.orders .order-title .order-title-wrapper .order-title-how-link:hover {
  box-shadow: var(--shadow);
}
.orders .order-title .order-title-wrapper .order-title-how-link svg {
  width: 16px;
  height: 16px;
}
.orders .order-title .order-title-wrapper .order-title-how-link span {
  font-size: 16px;
}
.orders .orders-wrapper {
  display: grid;
  width: 100%;
  gap: var(--gap2x);
  padding-top: 20px;
}
.orders .orders-wrapper .orders-wrapper-loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}
.orders .orders-wrapper .order-detail-search {
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.orders .orders-wrapper .order-detail-search .order-detail-search-header {
  display: flex;
  align-items: center;
  gap: var(--gap2x);
  padding: 0;
}
.orders .orders-wrapper .order-detail-search .order-detail-search-header .order-detail-search-select-all {
  min-width: 140px;
  padding-left: 10px;
}
.orders .orders-wrapper .order-detail-search .order-detail-search-header .order-detail-search-button {
  display: flex;
  gap: var(--gap);
  width: 100%;
}
.orders .orders-wrapper .order-detail-search .order-detail-search-header .order-detail-search-button button {
  width: 100%;
}
.orders .orders-wrapper .order-detail-search .order-detail-search-collapse .order-detail-search-content .order-detail-search-form {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.orders .orders-wrapper .order-detail-search .order-detail-search-collapse .order-detail-search-content .order-detail-search-form .order-detail-search-form-children {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: var(--gap2x);
}
.orders .orders-wrapper .order-detail-search .order-detail-search-collapse .order-detail-search-content .order-detail-search-form .order-detail-search-form-submit {
  display: flex;
  gap: var(--gap);
  justify-content: flex-end;
  align-items: center;
}
.orders .orders-wrapper .order-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  max-height: 500px;
  overflow: auto;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.orders .orders-wrapper .order-list .order-list-none-item {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.orders .orders-wrapper .order-list .order-list-none-item .order-list-none-item-info {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: var(--pastelBlue);
  color: var(--btnBgBlue);
  padding: 5px 10px;
  border-radius: var(--wrapperBorderRadius);
}
.orders .orders-wrapper .order-list .order-list-item {
  color: var(--priceText);
  display: flex;
  align-items: center;
  gap: var(--gap);
  background-color: var(--lighterBg);
  padding: 5px 20px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: var(--gap);
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info {
  display: flex;
  align-items: center;
  gap: var(--gap);
  width: 100%;
  justify-content: space-evenly;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info div {
  font-size: 12px;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-doctime {
  width: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-doctime .item-info-doc {
  text-align: center;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-doctime .item-info-time {
  text-align: center;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-parcel {
  width: 100px;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-tracking {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 150px;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-tracking .tracking-container {
  background-color: var(--background);
  padding: 3px 5px;
  border-radius: var(--buttonBorderRadius);
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: var(--priceText);
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-tracking .tracking-container .tracking-title {
  text-align: center;
  font-weight: 700;
  font-size: 13px;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-tracking .tracking-container .tracking-text {
  text-align: center;
  font-weight: 500;
  font-size: 11px;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-price-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-price-wrapper .item-info-price {
  width: 100px;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-price-wrapper .item-info-price .currency-price {
  font-size: 12px;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-price-wrapper .item-info-price .currency-price.try {
  font-size: 16px;
  color: var(--btnBgBlue);
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-price-wrapper .item-info-price .currency {
  font-size: 12px;
  padding-left: 3px;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-price-wrapper .item-info-price .currency.info {
  color: var(--btnBgBlue);
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-child.marketplace {
  width: 50px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-weight: 700;
  font-size: 10px;
  color: var(--priceText);
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-child.marketplace .avatar {
  width: 40px;
  height: auto;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-child .item-info-child-item {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-child .item-info-child-item .item-info-name {
  width: 85px;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-child .item-info-child-item .item-info-country {
  display: flex;
  flex-direction: column;
  width: 50px;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-child .item-info-child-item .item-info-country .item-info-country-icons {
  display: flex;
  align-items: center;
  gap: 5px;
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-child .item-info-child-item .item-info-country .item-info-country-icons.land-in {
  color: var(--shipmentGreen);
}
.orders .orders-wrapper .order-list .order-list-item .item-info-wrapper .item-info .item-info-child .item-info-child-item .item-info-country .item-info-country-icons.land-out {
  color: var(--shipmentRed);
}
.orders .orders-wrapper .order-count {
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: var(--gap3x);
}
.orders .orders-wrapper .order-count .order-count-item .order-count-item-headline {
  color: black;
  font-weight: 700;
  font-size: 14px;
}
.orders .orders-wrapper .order-count .order-count-item .order-count-item-subline {
  color: var(--btnBgBlue);
  font-weight: 500;
  font-size: 14px;
}
.orders .orders-wrapper .order-basket {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.orders .orders-wrapper .order-basket .order-basket-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: var(--gap2x);
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-button {
  background-color: var(--lighterBg);
  padding: 10px 20px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
  transition: all 150ms linear;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap);
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-button:hover {
  box-shadow: var(--bluestShadow);
  transition: all 150ms linear;
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-button.selected {
  box-shadow: var(--bluestShadow);
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-button.disabled {
  opacity: 0.3;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-button.disabled:hover {
  box-shadow: var(--shadow);
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-button .order-button-headline {
  font-size: 16px;
  font-weight: 700;
  color: black;
  text-align: center;
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-button .order-button-image {
  height: 100px;
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-button p {
  font-size: 12px;
  text-align: center;
  color: var(--priceText);
  font-weight: 700;
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-button p span {
  color: var(--btnBgBlue);
  font-size: inherit;
  font-weight: 500;
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-basket-total {
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  justify-content: space-between;
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-basket-total .order-basket-total-item {
  display: flex;
  justify-content: space-between;
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-basket-total .order-basket-total-item .total-item-child {
  font-size: 13px;
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-basket-total .order-basket-total-item .total-item-child.headline {
  color: black;
  font-weight: 700;
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-basket-total .order-basket-total-item .total-item-child.blue {
  color: var(--btnBgBlue);
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-basket-total .order-basket-total-item .total-item-child .red {
  color: var(--shipmentRed);
  font-weight: 700;
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-basket-total .order-basket-total-item .total-item-child .blue {
  color: var(--btnBgBlue);
  font-weight: 700;
}
.orders .orders-wrapper .order-basket .order-basket-wrapper .order-basket-total .total-item-final-price {
  display: flex;
  justify-content: flex-end;
}
.orders .orders-wrapper .order-domestic {
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.orders .orders-wrapper .order-domestic .order-domestic-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(155px, 1fr));
  gap: var(--gap);
}
.orders .orders-wrapper .order-domestic .order-domestic-wrapper .order-domestic-item {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  align-items: center;
  box-shadow: var(--shadow);
  padding: 5px;
  border-radius: var(--inputBorderRadius);
  transition: all 150ms linear;
}
.orders .orders-wrapper .order-domestic .order-domestic-wrapper .order-domestic-item.selected {
  box-shadow: var(--blueShadow);
  transition: all 150ms linear;
}
.orders .orders-wrapper .order-domestic .order-domestic-wrapper .order-domestic-item img {
  height: 31px;
}
.orders .orders-wrapper .order-domestic .order-domestic-wrapper .order-domestic-item .domestic-item-title {
  font-size: 12px;
  font-weight: 600;
}
.orders .orders-wrapper .order-domestic .order-domestic-wrapper .order-domestic-item .domestic-item-subtitle {
  font-size: 10px;
}
.orders .orders-wrapper .order-domestic .order-domestic-wrapper .order-domestic-item .domestic-item-price {
  font-size: 14px;
  font-weight: 600;
  color: var(--btnBgBlue);
}

@media (max-width: 768px) {
  .orders .order-title .order-title-wrapper {
    gap: var(--gap);
  }
  .orders .order-title .order-title-wrapper .order-title-headline h1 {
    font-size: 16px;
    line-height: 20px;
  }
  .orders .order-title .order-title-wrapper .order-title-how-link svg {
    width: 13px;
    height: 13px;
  }
  .orders .order-title .order-title-wrapper .order-title-how-link span {
    font-size: 13px;
  }
}/*# sourceMappingURL=Orders.css.map */