.terms-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  z-index: 2000 !important;

  .terms-modal {
    max-width: 600px;
    width: 100%;
    padding: 20px;
    background-color: var(--lighterBg);
    border-radius: var(--wrapperBorderRadius);
    max-height: 600px;
    height: 100%;
    overflow-y: auto;

    .policies {
      article {
        display: flex;
        flex-direction: column;
        gap: var(--gap);

        h1 {
          color: var(--btnBgBlue);
          font-size: 30px;
        }

        h4 {
          font-size: 20px;
          color: var(--btnBgBlue);
          padding-top: var(--gap);
        }

        p {
          font-size: 16px;
        }
      }
    }

    .modal-header {
      color: var(--btnBgBlue);
    }

    main {
      .sub-title {
        color: var(--btnBgBlue);
      }
    }

    footer {
      margin-top: 30px;
      display: flex;
      justify-content: space-evenly;
      gap: var(--gap);

      .others-log {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        border-radius: var(--inputBorderRadius);
        border: 1px solid transparent;
        color: var(--lighterBg);
        font-weight: 700;
        cursor: pointer;
        font-size: 13px;
        padding: 10px;

        &:disabled {
          opacity: 0.2;
          cursor: auto;
        }
      }
    }
  }
}