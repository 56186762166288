@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
#etsy {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: var(--gap1x);
}
#etsy .get-start .field {
  display: flex;
  flex-direction: row;
  gap: 170px;
  justify-content: center;
}
#etsy .get-start .field .get-start-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: var(--gap3x);
  margin-top: 50px;
  max-width: 1000px;
  position: relative;
  width: 100%;
}
#etsy .get-start .field .get-start-text h1 {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  color: var(--btnBgBlue);
  font-size: 40px;
  font-weight: bold;
  height: auto;
  line-height: normal;
  margin-bottom: 15px;
  margin-top: 50px;
}
#etsy .get-start .field .get-start-text p {
  color: #4f4f4f;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}
#etsy .get-start .field .get-start-text .main-header-text-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--gap2x);
  margin-bottom: 20px;
}
#etsy .get-start .field .get-start-text .main-header-text-buttons a {
  padding: 9px 14px;
  min-width: 200px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 49.927px;
  gap: 10px;
  font-size: 14px;
  line-height: 18px;
}
#etsy .main-header {
  display: flex;
  gap: 100px;
  justify-content: space-between;
  max-width: 1440px;
  padding: 20px 20px 0;
  width: 100%;
}
#etsy .main-header .main-header-text {
  display: flex;
  flex-direction: column;
  gap: var(--gap3x);
  margin-top: 50px;
  max-width: 500px;
  position: relative;
  width: 100%;
  z-index: 2;
}
#etsy .main-header .main-header-text h1 {
  font-size: 35px;
  font-weight: 400;
  height: auto;
  line-height: normal;
  margin-top: 50px;
}
#etsy .main-header .main-header-text p {
  color: #4f4f4f;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 115.023%;
}
#etsy .main-header .main-header-text .main-header-text-buttons {
  display: flex;
  gap: var(--gap2x);
}
#etsy .main-header .main-header-img {
  width: 100%;
}
#etsy .main-header .main-header-img img {
  margin-top: 40px;
  max-width: 661px;
  width: 100%;
}
#etsy .main-info {
  display: flex;
  flex-direction: column;
  gap: var(--gap5x);
  max-width: 1440px;
  padding: 40px 20px;
  width: 100%;
}
#etsy .main-info h2 {
  color: var(--btnBgBlue);
  font-size: 40px;
  font-weight: 600;
  line-height: 45px;
  text-align: center;
}
#etsy .main-info h2 span {
  color: inherit;
  font-size: inherit;
  font-weight: 800;
  line-height: inherit;
}
#etsy .main-info .info-container {
  display: flex;
  gap: var(--gap5x);
  justify-content: center;
  margin: 0 60px;
}
#etsy .main-info .info-container .step-header {
  text-align: center;
  width: 100%;
}
#etsy .main-info .info-container .step-header h2 {
  font-size: 30px;
}
#etsy .main-info .info-container .step-header p {
  font-size: 17px;
  text-align: center;
}
#etsy .main-info .info-container:first-child {
  background-color: #F6F6F6;
  border-radius: 20px;
  max-width: 1600px;
  margin: 0;
  width: 100%;
  margin: 0 auto;
}
#etsy .main-info .info-container:first-child p {
  text-align: center;
}
#etsy .main-info .info-container:nth-child(odd) {
  flex-direction: row;
}
#etsy .main-info .info-container:nth-child(2n) {
  flex-direction: row-reverse;
}
#etsy .main-info .info-container .info-image {
  display: flex;
  width: 100%;
}
#etsy .main-info .info-container .info-image img {
  border-radius: var(--inputBorderRadius);
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  width: 100%;
}
#etsy .main-info .info-container .main-header-text-buttons {
  display: flex;
  gap: var(--gap2x);
  justify-content: center;
  margin: 10px auto;
}
#etsy .main-info .info-container .main-header-text-buttons a {
  padding: 9px 14px;
  min-width: 200px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 49.927px;
  gap: 10px;
  font-size: 14px;
  line-height: 18px;
}
#etsy .main-info .info-container .info-price {
  background-color: #334155;
  width: 100%;
  table-layout: fixed;
  padding-top: 100px;
  padding-bottom: 40px;
  border-radius: 15px;
}
#etsy .main-info .info-container .info-price .info-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
#etsy .main-info .info-container .info-price .info-content .info-text {
  width: 50%;
  margin: 10px auto;
}
#etsy .main-info .info-container .info-price .info-content .info-text h2 {
  color: var(--btnBgBlue);
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1.33px;
  line-height: 45px;
  margin-bottom: 15px;
  text-align: start;
  margin-left: 20px;
}
#etsy .main-info .info-container .info-price .info-content .info-text p {
  color: white;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  margin-left: 20px;
}
#etsy .main-info .info-container .info-price .main-price-get-card {
  border-color: #334155;
  margin: 20px auto;
}
#etsy .main-info .info-container .info-price button {
  display: flex;
  align-content: center;
  margin: 0 auto;
}
#etsy .main-info .info-container .price-form-container {
  padding-right: 55px;
}
#etsy .main-info .info-container .price-form form {
  display: flex;
  flex-direction: column;
  -webkit-text-fill-color: white;
}
#etsy .main-info .info-container .price-form form button {
  margin: 0 auto;
}
#etsy .main-info .info-container .price-form form div {
  margin-bottom: 5px;
}
#etsy .main-info .info-container .price-form form fieldset {
  border-color: rgba(255, 255, 255, 0.23);
}
#etsy .main-info .info-container .price-form input {
  -webkit-text-fill-color: white;
}
#etsy .main-info .info-container .last {
  width: 60%;
}
#etsy .main-info .info-container .domestic-select {
  max-width: 1400px;
}
#etsy .main-info .info-container .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 35px;
  max-width: 621px;
  padding: 0 30px 30px;
  width: 100%;
}
#etsy .main-info .info-container .info .info-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}
#etsy .main-info .info-container .info h2 {
  color: var(--btnBgBlue);
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 47.5px;
  margin-bottom: var(--gap2x);
  text-align: left;
}
#etsy .main-info .info-container .info p {
  color: #4f4f4f;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  padding: 0;
  margin: 20px 0;
}
#etsy .main-info .info-container .info p span {
  color: #007fff;
  color: var(--mavi, #007fff);
  color: var(--mavi, #007fff);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-decoration-line: underline;
}
#etsy .main-info .info-container-brief {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  width: 100%;
}
#etsy .main-info .info-container-brief .info-container p {
  color: #4f4f4f;
  font-size: 20px;
}
#etsy .main-ishowitwork-frame .ishowitwork-frame {
  max-width: 1440px;
  width: 100%;
}
#etsy .main-ishowitwork-frame .ishowitwork-frame h2 {
  color: #007fff;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  padding-bottom: 30px;
  padding-top: 20px;
  text-align: center;
}
#etsy .main-ishowitwork-frame .ishowitwork-frame p {
  color: #4f4f4f;
  display: flex;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  line-height: 120.023%;
  margin-bottom: 30px;
  max-width: 1016px;
  text-align: center;
  width: 100%;
}
#etsy .main-ishowitwork-frame .ishowitwork-frame .button-container {
  display: flex;
  gap: var(--gap2x);
  justify-content: center;
  margin-bottom: -130px;
  margin-top: 90px;
}
#etsy .main-ishowitwork-frame .ishowitwork-frame .button-container a {
  padding: 9px 14px;
  min-width: 200px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 49.927px;
  gap: 10px;
  font-size: 14px;
  line-height: 18px;
}
#etsy .main-ishowitwork-frame .ishowitwork-frame .button-container .righ-btn {
  background-color: var(--lighterBg);
  border: 1px solid var(--btnBgBlue);
  border-radius: var(--wrapperBorderRadius);
  color: var(--btnBgBlue);
  text-transform: none;
}
#etsy .main-ishowitwork-frame .ishowitwork-frame .button-container .righ-btn:hover {
  background-color: var(--btnBgBlue);
  border: 1px solid var(--lighterBg);
  color: var(--lighterBg);
}
#etsy .main-ishowitwork-frame .ishowitwork-frame .button-container .left-btn {
  background-color: var(--btnBgBlue);
  border: 1px solid #84c1ff;
  border-radius: var(--wrapperBorderRadius);
  color: var(--lighterBg);
  text-transform: none;
  transition: all 0.15s linear;
}
#etsy .main-ishowitwork-frame .ishowitwork-frame .button-container .left-btn:hover {
  background-color: var(--lighterBg);
  border: 1px solid var(--btnBgBlue);
  color: var(--btnBgBlue);
}
#etsy .main-ishowitwork-frame .grid-container-wrapper .background {
  background-color: #f0f2f5;
  height: 340px;
  left: 0;
  margin-top: 70px;
  position: absolute;
  width: 100%;
  z-index: -1;
}
#etsy .main-ishowitwork-frame .grid-container-wrapper .grid-container {
  grid-gap: 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 300px);
  grid-template-columns: repeat(4, 300px);
  height: 313px;
  justify-content: center;
  margin: 0 auto;
  max-width: 800px;
  position: relative;
  z-index: 1;
}
#etsy .main-ishowitwork-frame .grid-container-wrapper .grid-container .grid-item {
  background: #fff;
  background: var(--base-0, #fff);
  background: var(--base-0, #fff);
  border: 1px solid #e3e3e3;
  border: 1px solid var(--base-20, #e3e3e3);
  border: 1px solid var(--base-20, #e3e3e3);
  border-radius: 20px;
  height: 370px;
  padding: 20px;
  text-align: center;
}
#etsy .main-ishowitwork-frame .grid-container-wrapper .grid-container .grid-item img {
  margin-bottom: 15px;
}
#etsy .main-ishowitwork-frame .grid-container-wrapper .grid-container .grid-item .grid-item-bottom {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#etsy .main-ishowitwork-frame .grid-container-wrapper .grid-container .grid-item .grid-item-bottom h3 {
  color: #007fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  text-align: center;
}
#etsy .main-ishowitwork-frame .grid-container-wrapper .grid-container .grid-item .grid-item-bottom p {
  color: #626262;
  color: var(--base-80, #626262);
  color: var(--base-80, #626262);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 11px;
  text-align: center;
  width: 208px;
}
#etsy .main-frame {
  gap: var(--gap2x);
  overflow: hidden;
}
#etsy .main-frame .frame-wrapper {
  gap: var(--gap3x);
  max-width: 1440px;
  padding: 60px 30px 30px;
  position: relative;
}
#etsy .main-frame .frame-wrapper .frame-left {
  left: 0;
  position: absolute;
  top: -100px;
  z-index: 0;
}
#etsy .main-frame .frame-wrapper .frame-right {
  position: absolute;
  right: 0;
  top: -200px;
  z-index: 0;
}
#etsy .main-frame .frame-wrapper h2 {
  color: var(--btnBgBlue);
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 15px;
  max-width: 100%;
  text-align: center;
  z-index: 1;
}
#etsy .main-frame .frame-wrapper .iframe-wrapper {
  display: flex;
  justify-content: center;
  max-width: 613px;
  width: 100%;
}
#etsy .main-frame .frame-wrapper .iframe-wrapper .iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}
#etsy .main-frame .frame-wrapper .iframe-wrapper .iframe-container .iframe {
  border: none;
  border-radius: var(--buttonBorderRadius);
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
#etsy .main-frame .frame-wrapper button {
  margin-bottom: var(--gap2x);
}
#etsy .main-faq {
  width: 100%;
  margin: auto;
  padding: 40px 20px;
  max-width: 1280px;
}
#etsy .main-faq h2 {
  color: var(--btnBgBlue);
  font-size: 36px;
  line-height: 45px;
  margin-top: 30px;
  margin-bottom: 50px;
  text-align: center;
  font-weight: 600;
}
#etsy .main-faq .faq-item {
  margin-bottom: 10px;
  padding: 10px 0;
}
#etsy .main-faq .faq-item div {
  color: #061027;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.9px;
  line-height: 34px;
  text-align: start;
}
#etsy .main-faq .faq-item .question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-left: 5px;
}
#etsy .main-faq .faq-item .question span {
  margin-right: 5px;
  font-size: 20px;
  font-weight: 600;
}
#etsy .main-faq .faq-item .question.active {
  font-weight: bold;
  margin-right: 3px;
  font-size: 20px;
}
#etsy .main-faq .faq-item .question.active .toggle {
  content: "-";
}
#etsy .main-faq .faq-item .answer {
  display: none;
  padding-top: 10px;
  margin-left: 5px;
}
#etsy .main-faq .faq-item .answer ol {
  margin-left: 21px;
}
#etsy .main-get-finish .insider {
  flex-direction: row;
  margin-bottom: -70px;
  max-width: 1440px;
}
#etsy .main-get-finish .insider .get-finish-headline {
  display: flex;
  flex-direction: column;
  gap: var(--gap3x);
  justify-content: center;
  max-width: 576px;
  padding-bottom: 55px;
  width: 100%;
}
#etsy .main-get-finish .insider .get-finish-headline h6 {
  color: #007fff;
  font-size: 36px;
  font-weight: 600;
  line-height: 38px;
  margin-bottom: 15px;
}
#etsy .main-get-finish .insider .get-finish-headline p {
  color: #4f4f4f;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
#etsy .main-get-finish .insider .get-finish-headline .headline-links {
  align-items: center;
  display: flex;
  gap: var(--gap2x);
}
#etsy .main-get-finish .insider .get-finish-headline .headline-links a {
  padding: 9px 14px;
  min-width: 200px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 49.927px;
  gap: 10px;
  font-size: 14px;
  line-height: 18px;
}
#etsy .main-get-finish .insider .get-finish-mockup {
  display: flex;
  max-width: 800px;
  padding-top: 75px;
  position: relative;
  width: 100%;
}
#etsy .main-get-finish .insider .get-finish-mockup .get-finish-mockup-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin-top: 40px;
  max-width: 288px;
}
#etsy .main-get-finish .insider .get-finish-mockup .get-finish-mockup-text-wrapper .get-finish-mockup-text {
  align-items: center;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.9019607843);
  border: 1px solid #fff;
  border: 1px solid var(--white, #fff);
  border: 1px solid var(--white, #fff);
  border-radius: 8px;
  display: flex;
  gap: var(--gap);
  padding: 5px;
  width: 100%;
}
#etsy .main-get-finish .insider .get-finish-mockup .get-finish-mockup-img {
  height: 315px;
  position: absolute;
  right: 150px;
  top: 146px;
}
#etsy .main-get-finish .insider .get-finish-mockup .get-finish-laptop {
  height: 550px;
  width: 100%;
  z-index: -99;
}

#etsy,
#etsy .get-start {
  flex-direction: column;
}

#etsy .main-header .down-btn {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 80px;
  text-align: center;
  width: 100%;
}

@media (max-width: 768px) {
  #etsy .get-start {
    padding-top: 0;
  }
  #etsy .get-start .field {
    margin-top: 0;
  }
  #etsy .get-start .field .get-start-text h1 {
    font-size: 30px;
    margin-top: 0;
  }
  #etsy .get-start .field .get-start-text p {
    font-size: 14px;
  }
  #etsy .get-start .field .get-start-text .main-header-text-buttons {
    flex-direction: column;
  }
  #etsy .get-start .field .get-start-text .main-header-text-buttons a {
    margin-bottom: 10px;
    min-width: 200px;
    font-weight: 700;
    box-sizing: border-box;
  }
  #etsy .main-info {
    min-height: 1150px;
  }
  #etsy .main-info .info-container {
    margin: 0 20px;
    flex-direction: column;
  }
  #etsy .main-info .info-container:nth-child(2n+1) {
    flex-direction: column;
  }
  #etsy .main-info .info-container:nth-child(2n+1) h2 {
    font-size: 30px;
  }
  #etsy .main-info .info-container:nth-child(2n+1) p {
    font-size: 14px;
  }
  #etsy .main-info .info-container .info-image {
    margin-bottom: 100px;
  }
  #etsy .main-info .info-container .info-image img {
    max-width: 100%;
  }
  #etsy .main-info .info-container .main-header-text-buttons {
    flex-direction: column;
    gap: var(--gap1x);
  }
  #etsy .main-info .info-container .main-header-text-buttons a {
    margin-bottom: 10px;
    min-width: 200px;
  }
  #etsy .main-info .info-container .info-price {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  #etsy .main-info .info-container .info-price .info-content .info-text h2 {
    font-size: 30px;
  }
  #etsy .main-info .info-container .info-price .info-content .info-text p {
    font-size: 14px;
  }
  #etsy .main-ishowitwork-frame {
    min-height: 1950px;
    margin-bottom: 0;
  }
  #etsy .main-ishowitwork-frame .ishowitwork-frame h2 {
    margin-bottom: 660px;
    font-size: 30px;
    padding-top: 0;
  }
  #etsy .main-ishowitwork-frame .ishowitwork-frame .grid-container-wrapper {
    min-height: 960px;
  }
  #etsy .main-ishowitwork-frame .ishowitwork-frame .grid-container-wrapper .grid-container {
    display: flex;
    flex-direction: column;
  }
  #etsy .main-ishowitwork-frame .ishowitwork-frame .grid-container-wrapper .grid-container .grid-item {
    margin: auto 40px;
    height: 400px;
  }
  #etsy .main-ishowitwork-frame .ishowitwork-frame .grid-container-wrapper .grid-container .grid-item img {
    width: 100%;
  }
  #etsy .main-ishowitwork-frame .ishowitwork-frame .grid-container-wrapper .grid-container .grid-item .grid-item-bottom h2 {
    font-size: 24px;
  }
  #etsy .main-ishowitwork-frame .ishowitwork-frame .grid-container-wrapper .grid-container .grid-item .grid-item-bottom p {
    font-size: 12px;
  }
  #etsy .main-ishowitwork-frame .ishowitwork-frame .button-container {
    margin: 0 !important;
  }
  #etsy #main-info {
    min-height: 4100px;
    padding-top: 0;
  }
  #etsy #main-info .info-container {
    margin: 0 20px;
    flex-direction: column;
  }
  #etsy #main-info .info-container:nth-child(2n+1), #etsy #main-info .info-container :nth-child(2n) {
    flex-direction: column;
  }
  #etsy #main-info .info-container:nth-child(2n+1) h2, #etsy #main-info .info-container :nth-child(2n) h2 {
    font-size: 30px;
  }
  #etsy #main-info .info-container:nth-child(2n+1) p, #etsy #main-info .info-container :nth-child(2n) p {
    font-size: 16px;
  }
  #etsy #main-info .info-container .info-image {
    margin-bottom: 40px;
  }
  #etsy #main-info .info-container .info-image img {
    max-width: 100%;
  }
  #etsy #main-info .info-container .main-header-text-buttons {
    flex-direction: column;
    gap: var(--gap1x);
  }
  #etsy #main-info .info-container .main-header-text-buttons a {
    margin-bottom: 10px;
    min-width: 200px;
  }
  #etsy #main-info .info-container .info-price {
    padding-top: 10px;
    margin-top: 20px;
  }
  #etsy #main-info .info-container .info-price .info-content {
    display: flex;
    flex-direction: column;
  }
  #etsy #main-info .info-container .info-price .info-content .info-text {
    width: 100%;
    padding: 10px;
  }
  #etsy #main-info .info-container .info-price .info-content .info-text h2 {
    font-size: 30px;
  }
  #etsy #main-info .info-container .info-price .info-content .info-text p {
    font-size: 14px;
  }
  #etsy #main-info .info-container .info-price .info-content .price-form-container {
    padding-right: 0;
  }
  #etsy #main-info .info-container .info-price .info-content .price-form-container .price-form {
    justify-content: center;
    margin: 10px;
  }
  #etsy #main-info .info-container .info-price .info-content .price-form-container .price-form form div {
    flex-direction: row;
  }
  #etsy #main-info .info-container .info-price .info-content .price-form-container .price-form form div div {
    width: 100%;
  }
  #etsy #main-info .info-container .info-price .info-content .price-form-container .price-form form div div div {
    align-items: start;
  }
  #etsy #main-info .info-container .info-price .info-content .price-form-container .price-form form div div div input {
    min-width: 110px;
  }
  #etsy #main-info .info-container:nth-child(5) h2 {
    margin-top: 70px;
  }
  #etsy #main-info .info-container:nth-child(6) .info-image {
    margin-top: 90px;
  }
  #etsy #main-info .info-container:nth-child(6) .info {
    margin-top: 0;
  }
  #etsy .main-faq h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }
  #etsy .main-get-finish {
    min-height: 500px;
  }
  #etsy .main-get-finish .insider .get-finish-headline {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  #etsy .main-get-finish .insider .get-finish-mockup {
    padding-top: 0;
  }
  #etsy .main-get-finish .insider .get-finish-mockup .get-finish-laptop {
    height: 300px;
  }
}/*# sourceMappingURL=Etsy.css.map */