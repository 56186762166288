.fast-shipment-search {
  background-color: var(--lighterBg);
  padding: 20px;
  box-shadow: var(--shadow);
  border-radius: var(--wrapperBorderRadius);
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.fast-shipment-search .fast-shipment-basic-search {
  display: flex;
  gap: var(--gap);
  align-items: center;
  width: 100%;
}
.fast-shipment-search .fast-shipment-detailed-search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--gap2x);
}
.fast-shipment-search .fast-shipment-detailed-search .search-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: var(--gap2x);
}

@media (max-width: 768px) {
  .fast-shipment-search {
    max-width: 100% !important;
  }
  .fast-shipment-pagination {
    max-width: 100% !important;
  }
  .fast-shipment-pagination div {
    width: 100%;
  }
  .fast-shipment-pagination div div button {
    width: 100%;
  }
  .fast-shipment-table {
    max-width: 100% !important;
    overflow: auto;
  }
}/*# sourceMappingURL=FastShipmentSearch.css.map */