.createShipment {
  min-height: 100vh;
  background-color: var(--background);
}
.createShipment .createShipment-stepper {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.createShipment .createShipment-wrapper .create-shipment-stepper-buttons {
  max-width: 1400px;
  width: 100%;
  margin: var(--gap2x) auto;
}
.createShipment .createShipment-wrapper .shipmentInfo {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--inputBorderRadius);
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.createShipment .createShipment-wrapper .shipmentInfo .shipmentInfo-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
}
.createShipment .createShipment-wrapper .shipmentInfo .shipmentInfo-form-wrapper .form-document-info {
  border: 1px solid var(--info);
  border-radius: 5px;
  padding: 7px 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--info);
  gap: 5px;
}
.createShipment .createShipment-wrapper .shipmentInfo .shipmentInfo-form-wrapper .form-document-info .form-document-info-text {
  color: var(--info);
  font-size: 12px;
}
.createShipment .createShipment-wrapper .shipmentInfo .shipmentInfo-form-wrapper .form-document-info .form-document-info-detail {
  font-weight: 700;
  color: var(--error);
  font-size: 12px;
  margin-left: 3px;
  cursor: pointer;
}
.createShipment .createShipment-wrapper .shipmentInfo .shipmentInfo-form-wrapper .form-document-info .form-document-info-detail:hover {
  color: var(--info);
  transition: all 150ms linear;
}
.createShipment .createShipment-wrapper .shipmentInfo .shipmentInfo-form-wrapper .shipmentInfo-form {
  display: flex;
  gap: var(--gap2x);
}
.createShipment .createShipment-wrapper .shipmentInfo .shipmentInfo-form-wrapper .shipmentInfo-form .form-document-button {
  background-color: var(--info);
  color: white;
  transition: all 150ms linear;
}
.createShipment .createShipment-wrapper .shipmentInfo .shipmentInfo-form-wrapper .shipmentInfo-form .form-document-button:hover {
  background-color: var(--blueBrand);
  transition: all 150ms linear;
}
.createShipment .createShipment-wrapper .shipmentDimension {
  background: var(--lighterBg);
  padding: 20px;
  border-radius: 10px;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form {
  margin-top: var(--gap3x);
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions {
  display: grid;
  gap: var(--gap2x);
  margin-top: var(--gap3x);
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper {
  display: grid;
  gap: var(--gap2x);
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain {
  display: grid;
  gap: var(--gap2x);
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .dimension-delete {
  width: -moz-fit-content;
  width: fit-content;
  background: var(--pastelRed);
  width: 33px;
  height: 33px;
  border: none;
  outline: none;
  color: var(--shipmentRed);
  cursor: pointer;
  transition: all 150ms linear;
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .dimension-delete:hover {
  color: var(--notRed);
  transition: all 150ms linear;
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .form-dimensions-content-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
  padding: 20px;
  box-shadow: 0 0 7px #e0dfde;
  border-radius: 10px;
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .form-dimensions-content-container .form-dimensions-content .form-dimensions-content-header {
  display: flex;
  justify-content: space-between;
  gap: var(--gap2x);
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .form-dimensions-content-container .form-dimensions-container {
  display: grid;
  grid-template-columns: repeat(6, minmax(160px, 1fr));
  gap: var(--gap);
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .form-dimensions-content-container .form-dimensions-container .dimension-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .form-dimensions-content-container .form-dimensions-container .dimension-loading span {
  width: 21px !important;
  height: 21px !important;
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .form-dimensions-weight {
  display: flex;
  gap: var(--gap);
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .form-dimensions-weight div {
  width: 100%;
  border: 1px solid var(--grayText);
  border-radius: var(--buttonBorderRadius);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .form-dimensions-weight div span {
  font-size: 10px;
  color: var(--grayText);
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .form-add-dimension-button-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .form-add-dimension-button-wrapper .add-dimension-button {
  cursor: pointer;
  color: var(--info);
  font-size: 30px;
  opacity: 1;
  border: none;
  background-color: transparent;
  outline: none;
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .form-add-dimension-button-wrapper .add-dimension-button:disabled {
  opacity: 0.2;
  cursor: auto;
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .total-weight {
  display: grid;
  gap: var(--gap);
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .total-weight div {
  border: 1px solid var(--grayText);
  border-radius: var(--buttonBorderRadius);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .total-weight div span {
  font-size: 10px;
  color: var(--grayText);
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .dimension-wrapper-button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: var(--gap);
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .dimension-wrapper-button-container .button-container-cw {
  padding: 10px;
  box-shadow: var(--shadow);
  border-radius: var(--inputBorderRadius);
  min-width: 250px;
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .dimension-wrapper-button-container .button-container-cw .cw-headline {
  font-size: inherit;
  font-weight: 700;
  color: var(--btnBgBlue);
}
.createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .dimension-wrapper-button-container .button-container-cw .cw-amount {
  font-size: inherit;
  font-weight: 700;
  color: var(--priceText);
}
@media (max-width: 500px) {
  .createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .dimension-wrapper-button-container {
    flex-direction: column;
    justify-content: baseline;
  }
}
.createShipment .createShipment-wrapper .shipmentPrice {
  margin-top: var(--gap3x);
  display: flex;
  flex-direction: column;
  gap: var(--gap3x);
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-info {
  background-color: var(--pastelBlue);
  padding: 20px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
  color: var(--btnBgBlue);
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-exchange-rate {
  display: flex;
  justify-content: flex-end;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-header {
  display: flex;
  align-items: center;
  gap: var(--gap2x);
  justify-content: center;
  padding: 20px 20px 40px;
  border-radius: var(--inputBorderRadius);
  background-color: var(--lighterBg);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-header .shipmentPrice-header-sides-wrapper {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-header .shipmentPrice-header-sides-wrapper .shipmentPrice-header-sides {
  display: grid;
  justify-items: center;
  gap: 5px;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-header .shipmentPrice-header-sides-wrapper .shipmentPrice-header-sides .shipmentPrice-header-sides-top {
  font-size: 12px;
  font-weight: 700;
  color: var(--priceText);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-header .shipmentPrice-header-sides-wrapper .shipmentPrice-header-sides .shipmentPrice-header-sides-bottom {
  font-size: var(--smallTextSize);
  font-weight: 700;
  color: var(--priceText);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-header .shipmentPrice-header-connection {
  display: grid;
  justify-items: center;
  gap: 15px;
  align-self: flex-end;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-header .shipmentPrice-header-connection .shipmentPrice-header-connection-gross {
  background-color: rgba(106, 112, 126, 0.5);
  border-radius: 5px;
  color: var(--lighterBg);
  padding: 4px 6px;
  font-size: var(--smallTextSize);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get {
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--inputBorderRadius);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .supplier-id-warning {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 20px;
  background: var(--notRed);
  padding: 10px;
  border-radius: var(--inputBorderRadius);
  box-shadow: 0 0 5px var(--background);
  color: var(--toastify-color-light);
  font-size: 13px;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper {
  display: grid;
  gap: var(--gap5x);
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
  max-width: 1100px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper.shipmentPriceCalculation-get-wrapper {
  grid-template-columns: repeat(3, 1fr);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-logo {
  display: flex;
  align-items: center;
  gap: var(--gap);
  width: 100%;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-logo .get-logo {
  width: 80px;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-logo .get-logo-warning {
  display: flex;
  align-items: center;
  background-color: var(--pastelBlue);
  padding: 5px;
  font-size: 10px;
  border-radius: var(--buttonBorderRadius);
  width: 100%;
  gap: var(--gap);
  box-shadow: var(--shadow);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-logo .get-logo-warning .logo-warning {
  width: 18px;
  color: var(--info);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-delivery {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
  width: 100%;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-delivery .get-delivery-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--gap4x);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-delivery .get-delivery-status .delivery-time-wrapper {
  display: grid;
  gap: var(--gap);
  font-size: 10px;
  text-align: center;
  color: var(--priceText);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-delivery .get-delivery-status .delivery-time-wrapper .delivery-time {
  width: 130px;
  height: 13px;
  background: linear-gradient(90deg, #ef5400 0%, #fc9900 22.94%, #d6c00a 48.79%, #02b81f 99.27%);
  border: 1px solid #e9ecef;
  border-radius: 20px;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-delivery .get-delivery-status .delivery-status {
  font-size: var(--largeTextSize);
  color: var(--priceText);
  font-weight: 700;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-delivery .get-delivery-return {
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  font-size: 11px;
  color: var(--priceText);
  font-weight: 500;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-delivery .get-delivery-return span {
  color: var(--priceText);
  font-weight: 700;
  font-size: 11px;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-price {
  display: flex;
  align-items: center;
  gap: var(--gap2x);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-price .get-price-currency {
  display: grid;
  gap: var(--gap);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-price .get-price-currency .price-foreign-currency {
  background: var(--shipmentYellow);
  border-radius: var(--inputBorderRadius);
  padding: 5px 10px;
  text-align: center;
  color: var(--lighterBg);
  min-width: 100px;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-price .get-price-currency .price-local {
  background: var(--background);
  border-radius: var(--inputBorderRadius);
  padding: 5px 10px;
  color: var(--priceText);
  min-width: 100px;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-price button {
  background-color: var(--info);
  color: var(--lighterBg);
  border: none;
  outline: none;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 7px 15px;
  border-radius: var(--buttonBorderRadius);
  cursor: pointer;
  transition: all 150ms linear;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-price button:hover {
  background-color: var(--blueBrand);
  transition: all 150ms linear;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-insurance {
  display: grid;
  justify-items: start;
  width: 100%;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper .shipmentPrice-get-insurance label > span {
  font-size: 14px;
  font-weight: 500;
  color: var(--priceText);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  gap: var(--gap2x);
  margin-bottom: var(--gap2x);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card .get-card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--lighterBg);
  box-shadow: var(--shadow);
  padding: 10px;
  border-radius: var(--inputBorderRadius);
  transition: all 150ms linear;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card .get-card-wrapper.selected {
  box-shadow: var(--blueShadow);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card .get-card-wrapper:hover {
  transition: all 150ms linear;
  box-shadow: var(--blueShadow);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card .get-card-wrapper .get-card {
  display: grid;
  align-content: space-between;
  color: var(--priceText);
  gap: var(--gap);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card .get-card-wrapper .get-card .get-card-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card .get-card-wrapper .get-card .get-card-header .get-card-header-headline {
  display: flex;
  align-items: center;
  gap: var(--gap);
  font-weight: 600;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card .get-card-wrapper .get-card .get-card-header .get-card-header-subtext {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card .get-card-wrapper .get-card .get-card-header .get-card-header-subtext span {
  font-weight: 700;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card .get-card-wrapper .get-card .get-card-main .get-card-main-price-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card .get-card-wrapper .get-card .get-card-main .get-card-main-price-wrapper .get-card-main-price {
  color: var(--info);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card .get-card-wrapper .get-card .get-card-main .get-card-main-price-wrapper .get-card-main-price .main-price-out {
  font-size: 36px;
  font-weight: 500;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card .get-card-wrapper .get-card .get-card-main .get-card-main-price-wrapper .get-card-main-price .main-price-in {
  font-size: inherit;
  color: var(--priceText);
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card .get-card-wrapper .get-card .get-card-main .shipmentPrice-get-insurance {
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 10px;
}
.createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card .get-card-wrapper .get-card .get-card-main .shipmentPrice-get-insurance label span {
  padding: 3px;
}
.createShipment .createShipment-wrapper .shipmentAddress {
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--inputBorderRadius);
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.createShipment .createShipment-wrapper .shipmentAddress .new-address-adder-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: var(--gap);
}
.createShipment .createShipment-wrapper .shipmentAddress .new-address-adder-wrapper .new-address-adder {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--adderBtn);
  display: flex;
  align-items: center;
  justify-self: flex-end;
  gap: 10px;
  font-weight: 700;
}
.createShipment .createShipment-wrapper .shipmentAddress .shipmentAddress-list {
  display: grid;
  gap: var(--gap4x);
}
.createShipment .createShipment-wrapper .shipmentAddress .shipmentAddress-list .shipmentAddress-list-board {
  display: grid;
  gap: var(--gap);
}
.createShipment .createShipment-wrapper .shipmentAddress .shipmentAddress-list .shipmentAddress-list-board .list-board-items {
  display: grid;
  gap: var(--gap2x);
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}
.createShipment .createShipment-wrapper .shipmentAddress .shipmentAddress-list .shipmentAddress-list-board .list-board-items .list-board-item {
  border: 1px solid var(--priceText);
  border-radius: var(--inputBorderRadius);
  color: var(--priceText);
  padding: var(--padding) 30px var(--padding) var(--padding);
  position: relative;
}
.createShipment .createShipment-wrapper .shipmentAddress .shipmentAddress-list .shipmentAddress-list-board .list-board-items .list-board-item div {
  font-size: 12px;
}
.createShipment .createShipment-wrapper .shipmentAddress .shipmentAddress-list .shipmentAddress-list-board .list-board-items .list-board-item .item-select {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
}
.createShipment .createShipment-wrapper .shipmentAddress .shipmentAddress-list .shipmentAddress-list-board .list-board-items .list-board-item .item-delete {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: var(--notRed);
  transition: all 150ms linear;
  cursor: pointer;
}
.createShipment .createShipment-wrapper .shipmentAddress .shipmentAddress-list .shipmentAddress-list-board .list-board-items .list-board-item .item-delete:hover {
  color: var(--error);
  transition: all 150ms linear;
}
.createShipment .createShipment-wrapper .shipmentProforma {
  display: grid;
  width: 100%;
  gap: var(--gap2x);
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--inputBorderRadius);
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-number {
  display: grid;
  gap: var(--gap);
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-info {
  display: flex;
  align-items: flex-start;
  width: 100%;
  border: 1px solid var(--pastelBlue);
  background-color: var(--pastelBlue);
  padding: var(--padding);
  border-radius: var(--buttonBorderRadius);
  box-shadow: 0 0 10px var(--lighterBg);
  gap: 5px;
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-info .info-text {
  display: grid;
  gap: 4px;
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-info .info-text .text-header {
  font-weight: 700;
  font-size: 12px;
  color: var(--priceText);
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-info .info-text .text-content {
  font-weight: 500;
  font-size: 12px;
  color: var(--priceText);
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-detail-headline {
  color: var(--priceText);
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap);
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-details .details-columns {
  display: grid;
  width: 100%;
  gap: var(--gap2x);
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-details .details-columns .details-column {
  display: flex;
  align-items: center;
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-details .details-columns .details-column .column {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: var(--gap);
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-details .details-columns .details-column .display {
  margin-left: 10px;
  color: var(--notRed);
  cursor: pointer;
  transition: all 150ms linear;
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-details .details-columns .details-column .display:hover {
  color: var(--error);
  transition: all 150ms linear;
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-details .details-columns .details-column .displayHide {
  display: none;
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-details .proforma-add {
  color: var(--btnBgBlue);
  transition: all 150ms linear;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--lighterBg);
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-details .proforma-add.active:hover {
  color: var(--lighterBg);
  background-color: var(--btnBgBlue);
  transition: all 150ms linear;
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-details .proforma-add.disabled {
  cursor: auto;
  opacity: 0.3;
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-send {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  background: var(--info);
  border: none;
  outline: none;
  color: var(--lighterBg);
  font-size: 14px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 150ms linear;
}
.createShipment .createShipment-wrapper .shipmentProforma .proforma-send:hover {
  background: var(--blueBrand);
  transition: all 150ms linear;
}
.createShipment .createShipment-wrapper .shipmentPayment {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--inputBorderRadius);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--gap2x);
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.createShipment .createShipment-wrapper .shipmentPayment svg {
  color: var(--greenBrand);
  width: 150px;
  height: 150px;
}
.createShipment .createShipment-wrapper .shipmentPayment div {
  color: var(--priceText);
}
.createShipment .createShipment-wrapper .shipmentPayment .button-wrapper {
  display: flex;
  align-items: center;
  gap: var(--gap2x);
}
.createShipment .createShipment-wrapper .shipmentPayment .button-wrapper .button {
  padding: 8px 20px;
  text-align: center;
  background-color: var(--info);
  color: var(--lighterBg);
  border-radius: var(--buttonBorderRadius);
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 150ms linear;
  text-transform: none;
  text-decoration: none;
  height: 40px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.createShipment .createShipment-wrapper .shipmentPayment .button-wrapper .button:hover {
  background-color: var(--blueBrand);
  transition: all 150ms linear;
}

@media (max-width: 1366px) {
  .createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-content-container .form-dimensions-container {
    grid-template-columns: repeat(3, minmax(150px, 1fr)) !important;
  }
}
@media (max-width: 1300px) {
  .createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 1024px) {
  .createShipment .createShipment-stepper {
    display: none;
  }
  .createShipment .createShipment-wrapper {
    transition: all 150ms linear;
  }
  .createShipment .createShipment-wrapper .shipmentInfo .shipmentInfo-form {
    flex-direction: column;
    align-items: flex-start;
  }
  .createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get .shipmentPrice-get-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 850px) {
  .createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-content-container .form-dimensions-container {
    grid-template-columns: repeat(2, minmax(150px, 1fr)) !important;
  }
  .createShipment .createShipment-wrapper .shipmentPayment .button-wrapper {
    flex-direction: column;
  }
  .createShipment .createShipment-wrapper .shipmentPayment .button-wrapper .button {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-content-container .form-dimensions-container {
    grid-template-columns: repeat(1, minmax(150px, 1fr)) !important;
  }
  .createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-content-container .form-dimensions-weight {
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
  }
}
@media (max-width: 600px) {
  .createShipment .createShipment-wrapper {
    transition: all 150ms linear;
  }
  .createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .total-weight {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media (max-width: 450px) {
  .createShipment .createShipment-wrapper .shipmentDimension .shipmentDimension-form .form-dimensions .form-dimensions-wrapper .form-dimensions-contain .form-dimensions-weight div {
    flex-direction: column;
  }
}
@media (max-width: 360px) {
  .createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-header {
    flex-direction: column;
  }
  .createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-header .shipmentPrice-header-connection {
    align-self: center;
  }
  .createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-delivery .get-delivery-status {
    flex-direction: column;
  }
  .createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card {
    display: flex;
    flex-direction: column;
  }
  .createShipment .createShipment-wrapper .shipmentPrice .shipmentPrice-get-card .get-card-wrapper .get-card .get-card-main .get-card-main-price-wrapper .get-card-main-price {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}/*# sourceMappingURL=CreateShipment.css.map */