.dashboard {
  min-height: 100vh;
  background-color: var(--background);
}
.dashboard .dashboard-wrapper .pie {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(610px, 1fr)) !important;
  grid-template-rows: minmax(250px, 5fr) !important;
  gap: var(--gap2x);
  justify-content: center;
  max-width: 1240px;
  margin: auto;
}
@media (max-width: 1562px) {
  .dashboard .dashboard-wrapper .pie {
    grid-template-columns: repeat(2, minmax(320px, 1fr)) !important;
  }
}
@media (max-width: 980px) {
  .dashboard .dashboard-wrapper .pie {
    grid-template-columns: repeat(1, minmax(320px, 1fr)) !important;
  }
}
@media (max-width: 660px) {
  .dashboard .dashboard-wrapper .pie {
    grid-template-columns: repeat(1, minmax(320px, 1fr)) !important;
  }
}
@media (max-width: 468px) {
  .dashboard .dashboard-wrapper .pie {
    grid-template-columns: repeat(1, minmax(170px, 1fr)) !important;
  }
}
.dashboard .dashboard-wrapper .pie .pie-counts {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
}
.dashboard .dashboard-wrapper .pie .dashboard-shipment-pie {
  width: 100%;
  height: 100%;
  margin: auto;
}
.dashboard .dashboard-wrapper .pie .dashboard-shipment-pie .pie-chart {
  padding: 30px;
  background-color: var(--lighterBg);
  border-radius: 5px;
  box-shadow: var(--shadow);
  height: 100%;
  position: relative;
  transition: all 0.3s ease;
}
.dashboard .dashboard-wrapper .pie .dashboard-shipment-pie .pie-chart .pie-chart-header {
  padding-bottom: 30px;
}
.dashboard .dashboard-wrapper .pie .dashboard-shipment-pie .pie-chart .pie-chart-header .pie-text-total {
  color: var(--priceText);
  font-size: var(--normalTextSize);
  font-weight: 600;
}
.dashboard .dashboard-wrapper .pie .dashboard-shipment-pie .pie-chart .pie-chart-header .pie-text-total-detailed {
  color: var(--btnBgBlue);
  font-size: 12px;
  margin-top: 10px;
}
.dashboard .dashboard-wrapper .pie .dashboard-shipment-pie .pie-chart .pie-chart-header .pie-text-total-detailed:hover {
  cursor: pointer;
  text-decoration: underline;
}
.dashboard .dashboard-wrapper .pie .dashboard-shipment-pie .pie-chart .pie-chart-footer {
  display: flex;
  justify-content: space-between;
}
.dashboard .dashboard-wrapper .pie .dashboard-shipment-pie .pie-chart .pie-chart-footer .count-button button {
  padding: 0;
  font-size: 12px;
  color: #007fff;
  font-weight: 600;
  text-transform: capitalize;
  background: none;
  border: none;
  cursor: pointer;
}
.dashboard .dashboard-wrapper .pie .dashboard-shipment-pie .pie-chart .pie-modal-st {
  padding: 30px;
  background-color: var(--lighterBg);
  border-radius: 5px;
  box-shadow: var(--shadow);
  height: 100%;
}
.dashboard .dashboard-wrapper .pie .dashboard-shipment-pie .pie-chart.expanded {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 1000;
}
.dashboard .dashboard-wrapper .pie .dashboard-shipment-pie canvas {
  width: 100% !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}
.dashboard .dashboard-wrapper .dashboard-shipment-count {
  margin-bottom: 1rem !important;
}
.dashboard .dashboard-wrapper .dashboard-shipment-count h3 {
  color: #7c7c7a;
  font-size: 30px;
  text-align: left;
}
.dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(610px, 320px));
  grid-template-rows: minmax(95px, 1fr);
  gap: var(--gap2x);
  justify-content: center;
  margin-bottom: 20px;
}
@media (max-width: 468px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper {
    grid-template-columns: repeat(1, minmax(170px, 1fr));
  }
}
@media (max-width: 660px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper {
    grid-template-columns: repeat(1, minmax(320px, 1fr));
  }
}
@media (max-width: 1317px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper {
    grid-template-columns: repeat(2, minmax(160px, 1fr));
    margin-top: 20px;
  }
}
@media (max-width: 1600px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper {
    grid-template-columns: repeat(2, minmax(160px, 1fr));
    margin-top: 20px;
  }
}
.dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container {
  display: flex;
  background-color: var(--lighterBg);
  box-shadow: var(--shadow);
  justify-content: space-between;
  align-items: center;
  min-height: 95px;
  gap: 20px;
  padding: 23px;
  border-radius: 5px;
}
@media (max-width: 1317px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container {
    width: 100% !important;
  }
}
.dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container .shipment-count-text {
  display: grid;
  gap: 5px;
  width: 100%;
}
.dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container .shipment-count-text .shipment-count-text-total {
  color: var(--priceText);
  font-size: var(--normalTextSize);
  font-weight: 600;
}
.dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container .shipment-count-text .shipment-count-text-number {
  font-size: var(--gap4x);
  font-weight: 500;
}
@media (max-width: 468px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container .shipment-count-text .shipment-count-text-number {
    font-size: 25px;
  }
}
.dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container .shipment-count-text .shipment-count-text-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 468px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container .shipment-count-text .shipment-count-text-footer {
    flex-direction: column;
    gap: 15px;
  }
}
.dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container .shipment-count-text .shipment-count-text-footer .shipment-count-text-time {
  color: var(--grayCount);
  font-size: 10px;
}
.dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container .shipment-count-text .shipment-count-text-footer .count-button {
  padding: 0;
  font-size: 12px;
  color: #007fff;
  font-weight: 600;
  text-transform: capitalize;
  background: none;
  border: none;
  cursor: pointer;
}
.dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container .shipment-count-text .yellow {
  color: var(--shipmentYellow);
}
.dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container .shipment-count-text .blue {
  color: var(--shipmentBlue);
}
.dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container .shipment-count-text .red {
  color: var(--shipmentRed);
}
.dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container .shipment-count-text .green {
  color: #86b817;
}
.dashboard .dashboard-wrapper .dashboard-shipment-count .header {
  max-width: 1240px;
  padding: 0px 0px 20px 0px;
  margin: auto;
}
@media (max-width: 468px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-count .header {
    padding: 10px 0px;
  }
}
@media (max-width: 468px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-count .header h3 {
    font-size: 25px;
  }
}
.dashboard .dashboard-wrapper .dashboard-shipment-line-wealth {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(610px, 1fr));
  justify-content: center;
}
@media (max-width: 1570px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-line-wealth {
    grid-template-columns: repeat(2, minmax(320px, 1fr));
  }
}
@media (max-width: 660px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-line-wealth {
    grid-template-columns: repeat(1, minmax(320px, 1fr));
  }
}
@media (max-width: 468px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-line-wealth {
    grid-template-columns: repeat(1, minmax(320px, 1fr));
  }
}
.dashboard .dashboard-wrapper .dashboard-shipment-line-wealth .line-chart {
  width: 100%;
  padding: 30px;
  margin: 20px auto;
  background-color: var(--lighterBg);
  border-radius: 5px;
  box-shadow: var(--shadow);
}
.dashboard .dashboard-wrapper .dashboard-shipment-line-wealth .line-chart .line-chart-header .line-chart-header-worth {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line-wealth .line-chart .line-chart-header .shipment-count-text-total {
  color: var(--priceText);
  font-size: var(--normalTextSize);
  font-weight: 600;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line-wealth .line-chart .line-chart-header .shipment-count-text-total-detailed {
  color: var(--btnBgBlue);
  font-size: 12px;
  margin-top: 10px;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line-wealth .line-chart .line-chart-header .shipment-count-text-total-detailed:hover {
  cursor: pointer;
  text-decoration: underline;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line-wealth .line-chart .line-chart-footer {
  display: flex;
  justify-content: flex-end;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line-wealth .line-chart .line-chart-footer .count-button {
  padding: 0;
  font-size: 12px;
  color: #007fff;
  font-weight: 600;
  text-transform: capitalize;
  background: none;
  border: none;
  cursor: pointer;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line-wealth .line-chart canvas {
  width: 100% !important;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(610px, 1fr));
  justify-content: center;
  max-width: 1240px;
  margin: auto;
}
@media (max-width: 1570px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-line {
    grid-template-columns: repeat(1, minmax(320px, 1fr));
  }
}
@media (max-width: 660px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-line {
    grid-template-columns: repeat(1, minmax(320px, 1fr));
  }
}
@media (max-width: 468px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-line {
    grid-template-columns: repeat(auto-fit, minmax(345px, 1fr));
  }
}
.dashboard .dashboard-wrapper .dashboard-shipment-line .line-chart {
  padding: 30px;
  margin: 0px auto 20px;
  background-color: var(--lighterBg);
  border-radius: 5px;
  width: 100%;
  box-shadow: var(--shadow);
}
@media (max-width: 1317px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-line .line-chart {
    margin: 0px auto 0px;
  }
}
.dashboard .dashboard-wrapper .dashboard-shipment-line .line-chart .line-chart-header .line-chart-header-worth {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line .line-chart .line-chart-header .shipment-count-text-total {
  color: var(--priceText);
  font-size: var(--normalTextSize);
  font-weight: 600;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line .line-chart .line-chart-header .shipment-count-text-total-detailed {
  color: var(--btnBgBlue);
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line .line-chart .line-chart-header .shipment-count-text-total-detailed:hover {
  cursor: pointer;
  text-decoration: underline;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line .line-chart .line-chart-footer {
  display: flex;
  justify-content: flex-end;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line .line-chart .line-chart-footer .count-button {
  padding: 0;
  font-size: 12px;
  color: #007fff;
  font-weight: 600;
  text-transform: capitalize;
  background: none;
  border: none;
  cursor: pointer;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line .line-chart canvas {
  width: 100% !important;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line-close {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(610px, 2fr));
  justify-content: center;
  max-width: 1500px;
}
@media (max-width: 660px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-line-close {
    grid-template-columns: repeat(1, minmax(320px, 1fr));
  }
}
@media (max-width: 468px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-line-close {
    grid-template-columns: repeat(1, minmax(320px, 1fr));
  }
}
.dashboard .dashboard-wrapper .dashboard-shipment-line-close .line-chart {
  width: 100%;
  padding: 30px;
  margin: 0px auto 20px;
  background-color: var(--lighterBg);
  border-radius: var(--inputBorderRadius);
  box-shadow: var(--shadow);
}
.dashboard .dashboard-wrapper .dashboard-shipment-line-close .line-chart .line-chart-header .shipment-count-text-total {
  color: var(--priceText);
  font-size: var(--normalTextSize);
  font-weight: 600;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line-close .line-chart .line-chart-header .shipment-count-text-total-detailed {
  color: var(--btnBgBlue);
  font-size: 12px;
  margin-top: 10px;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line-close .line-chart .line-chart-header .shipment-count-text-total-detailed:hover {
  cursor: pointer;
  text-decoration: underline;
}
.dashboard .dashboard-wrapper .dashboard-shipment-line-close .line-chart canvas {
  width: 100% !important;
}
.dashboard .dashboard-wrapper .info {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
  max-width: 1440px;
  margin: 0 auto;
}
.dashboard .dashboard-wrapper .info .info-item {
  background-color: var(--lighterBg);
  box-shadow: var(--shadow);
  border-radius: var(--wrapperBorderRadius);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
}
.dashboard .dashboard-wrapper .info .info-item h2 {
  font-size: 20px;
  color: var(--priceText);
}
.dashboard .dashboard-wrapper .info .info-item .cargo {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-items: center;
}
.dashboard .dashboard-wrapper .info .info-item .cargo .cargo-item {
  box-shadow: var(--shadow);
  border-radius: var(--wrapperBorderRadius);
  padding: 20px;
  max-width: 317px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  align-items: center;
  justify-content: center;
}
.dashboard .dashboard-wrapper .info .info-item .bank {
  max-width: 378px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: var(--shadow);
  border-radius: var(--wrapperBorderRadius);
  align-self: center;
}
.dashboard .dashboard-wrapper .info .info-item .bank img {
  max-width: 257px;
  width: 100%;
}
.dashboard .dashboard-wrapper .info .info-item .bank .bank-text {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.dashboard .dashboard-wrapper .info .info-item .bank .bank-text span {
  text-align: center;
  font-size: 16px;
  color: var(--priceText);
}
.dashboard .dashboard-wrapper .info .info-item .bank .bank-text span svg {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.dashboard .dashboard-wrapper .info .info-item .bank .bank-text span:nth-child(3) {
  font-size: 13px;
}
.dashboard .dashboard-wrapper .info .info-item .info-address {
  flex-direction: column;
  align-items: flex-start;
}
.dashboard .dashboard-wrapper .info .info-item .info-address .info-address-iframe {
  border: 0;
  width: 100%;
  max-width: 800px;
  min-height: 300px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
  margin: 0 auto;
}
.dashboard .dashboard-wrapper .info .info-item .info-address .info-address-text {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.dashboard .dashboard-wrapper .info .info-item .info-address .info-address-text span {
  font-size: 16px;
  color: var(--priceText);
  max-width: 286px;
  width: 100%;
}
.dashboard .dashboard-wrapper .info .info-item .info-address .info-address-text span:first-child {
  font-weight: 700;
}
.dashboard .dashboard-wrapper .info .info-item .info-address .info-address-text span:last-child {
  font-size: 14px;
}
@media (max-width: 900px) {
  .dashboard .dashboard-wrapper .info .info-item .cargo {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-items: center;
  }
  .dashboard .dashboard-wrapper .info .info-item .info-address {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 400px) {
  .dashboard .dashboard-wrapper .info .info-item .cargo {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
  .dashboard .dashboard-wrapper .info .info-item .cargo img {
    width: 100%;
  }
}

@media (max-width: 360px) {
  .dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container {
    flex-direction: column;
  }
  .dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container .shipment-count-text {
    margin: auto;
  }
  .dashboard .dashboard-wrapper .dashboard-shipment-count .shipment-count-wrapper .shipment-count-container .shipment-count-image {
    margin: auto;
  }
}/*# sourceMappingURL=Dashboard.css.map */