.registered-cards-wrapper {
  background-color: var(--background);
  min-height: 100vh;
}
.registered-cards-wrapper .registered-cards,
.registered-cards-wrapper .registered-dimensions {
  background-color: var(--lighterBg);
  padding: 20px 30px;
  width: 100%;
  border-radius: var(--wrapperBorderRadius);
}
.registered-cards-wrapper .registered-cards .profile-card-wrapper,
.registered-cards-wrapper .registered-dimensions .profile-card-wrapper {
  display: flex;
  align-items: center;
  padding: 0;
}
.registered-cards-wrapper .registered-cards .profile-card-wrapper .profile-card-header,
.registered-cards-wrapper .registered-dimensions .profile-card-wrapper .profile-card-header {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.registered-cards-wrapper .registered-cards .card-adder-wrapper,
.registered-cards-wrapper .registered-dimensions .card-adder-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: var(--gap);
}
.registered-cards-wrapper .registered-cards .card-adder-wrapper .card-adder,
.registered-cards-wrapper .registered-dimensions .card-adder-wrapper .card-adder {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--info);
  display: flex;
  align-items: center;
  justify-self: flex-end;
  gap: 10px;
  font-weight: 700;
}
.registered-cards-wrapper .registered-cards .card-spring-in .default-card,
.registered-cards-wrapper .registered-dimensions .card-spring-in .default-card {
  display: grid;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: var(--gap3x) auto 0;
}
.registered-cards-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper,
.registered-cards-wrapper .registered-dimensions .card-spring-in .default-card .card-info-wrapper {
  display: flex;
  width: 100%;
  gap: var(--gap2x);
}
.registered-cards-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper .card-info-text,
.registered-cards-wrapper .registered-dimensions .card-spring-in .default-card .card-info-wrapper .card-info-text {
  display: grid;
  align-content: flex-start;
  width: 100%;
  gap: var(--gap2x);
}
.registered-cards-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper .card-info-text .card-info-text-date,
.registered-cards-wrapper .registered-dimensions .card-spring-in .default-card .card-info-wrapper .card-info-text .card-info-text-date {
  display: flex;
  gap: var(--gap);
}
.registered-cards-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper,
.registered-cards-wrapper .registered-dimensions .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper {
  display: flex;
  max-width: 330px;
  width: 100%;
}
.registered-cards-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display,
.registered-cards-wrapper .registered-dimensions .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display {
  border-radius: var(--inputBorderRadius);
  width: 330px;
  height: 200px;
  background-image: url(../../img/images/credit_card_container.svg);
  background-size: cover;
  padding: 20px;
  display: grid;
}
.registered-cards-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display.display-back,
.registered-cards-wrapper .registered-dimensions .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display.display-back {
  background-image: url(../../img/images/credit_card_back_container.svg);
  justify-content: center;
  align-items: center;
}
.registered-cards-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display.display-back .card-back,
.registered-cards-wrapper .registered-dimensions .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display.display-back .card-back {
  color: var(--subtitleDarkBlue);
  margin-left: 75px;
  margin-top: 20px;
}
.registered-cards-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display .card-top,
.registered-cards-wrapper .registered-dimensions .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display .card-top {
  display: flex;
  justify-content: flex-end;
  color: var(--lighterBg);
}
.registered-cards-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display .card-bottom,
.registered-cards-wrapper .registered-dimensions .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display .card-bottom {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  justify-content: flex-end;
  align-items: flex-start;
  color: var(--lighterBg);
  margin-bottom: 10px;
}
.registered-cards-wrapper .registered-cards .card-spring-in .default-card .card-info-register,
.registered-cards-wrapper .registered-dimensions .card-spring-in .default-card .card-info-register {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--gap3x);
  width: 100%;
  flex-wrap: wrap;
  gap: var(--gap);
}
.registered-cards-wrapper .registered-cards .card-spring-in .default-dimension,
.registered-cards-wrapper .registered-dimensions .card-spring-in .default-dimension {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: var(--gap3x) 0 0;
}
.registered-cards-wrapper .registered-cards .card-spring-in .default-dimension .dimension-info-wrapper,
.registered-cards-wrapper .registered-dimensions .card-spring-in .default-dimension .dimension-info-wrapper {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  width: 100%;
  gap: var(--gap2x);
}
.registered-cards-wrapper .registered-cards .card-spring-in .default-dimension .dimension-info-register,
.registered-cards-wrapper .registered-dimensions .card-spring-in .default-dimension .dimension-info-register {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--gap3x);
  width: 100%;
}
.registered-cards-wrapper .registered-cards .card-spring-in .default-dimension .dimension-info-register button,
.registered-cards-wrapper .registered-dimensions .card-spring-in .default-dimension .dimension-info-register button {
  padding: 7px 30px;
  background-color: var(--btnBgBlue);
  border-radius: var(--buttonBorderRadius);
  border: 1px solid var(--btnBgBlue);
  color: var(--lighterBg);
  cursor: pointer;
  transition: all 150ms linear;
  font-size: var(--smallTextSize);
}
.registered-cards-wrapper .registered-cards .card-spring-in .default-dimension .dimension-info-register button:hover,
.registered-cards-wrapper .registered-dimensions .card-spring-in .default-dimension .dimension-info-register button:hover {
  background-color: var(--blueBrand);
  border: 1px solid var(--blueBrand);
  transition: all 150ms linear;
}
.registered-cards-wrapper .registered-cards .dimensions .dimension-list .dimensions-display-wrapper,
.registered-cards-wrapper .registered-dimensions .dimensions .dimension-list .dimensions-display-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: var(--gap2x);
  margin-top: var(--gap2x);
}
.registered-cards-wrapper .registered-cards .dimensions .dimension-list .dimensions-display-wrapper .dimensions-display,
.registered-cards-wrapper .registered-dimensions .dimensions .dimension-list .dimensions-display-wrapper .dimensions-display {
  border: 1px solid var(--inputBorderColor);
  border-radius: var(--inputBorderRadius);
}
.registered-cards-wrapper .registered-cards .dimensions .dimension-list .dimensions-display-wrapper .dimensions-display .display-header,
.registered-cards-wrapper .registered-dimensions .dimensions .dimension-list .dimensions-display-wrapper .dimensions-display .display-header {
  padding: var(--padding);
  font-size: var(--smallTextSize);
  color: var(--priceText);
}
.registered-cards-wrapper .registered-cards .dimensions .dimension-list .dimensions-display-wrapper .dimensions-display .display-content,
.registered-cards-wrapper .registered-dimensions .dimensions .dimension-list .dimensions-display-wrapper .dimensions-display .display-content {
  padding: var(--padding);
  display: grid;
  gap: var(--gap2x);
}
.registered-cards-wrapper .registered-cards .dimensions .dimension-list .dimensions-display-wrapper .dimensions-display .display-content div,
.registered-cards-wrapper .registered-dimensions .dimensions .dimension-list .dimensions-display-wrapper .dimensions-display .display-content div {
  font-size: var(--smallTextSize);
  color: var(--priceText);
}

@media (max-width: 1366px) {
  .registered-cards-wrapper .registered-cards {
    padding: 20px;
  }
}
@media (max-width: 800px) {
  .registered-cards-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper {
    flex-direction: column-reverse;
  }
  .registered-cards-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper .card-info-text .card-info-text-date {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  }
}
@media (max-width: 700px) {
  .registered-cards-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper .card-info-text .card-info-text-date {
    flex-direction: column;
  }
  .registered-cards-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display {
    width: 280px;
    height: 163px;
  }
}
@media (max-width: 600px) {
  .registered-cards-wrapper .registered-cards {
    padding: 10px;
  }
  .registered-cards-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display {
    width: 280px;
    height: 163px;
  }
  .registered-cards-wrapper .registered-cards .card-spring-in .default-dimension .dimension-info-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }
}
@media (max-width: 400px) {
  .registered-cards-wrapper .registered-cards .card-spring-in .default-card .card-info-wrapper .card-info-display-wrapper .card-info-display {
    width: 200px;
    height: 120px;
  }
}
.card-info-display-wrapper {
  display: flex;
  max-width: 280px;
  width: 100%;
  font-size: 13px;
  padding: 10px;
  box-shadow: var(--shadow);
  border-radius: var(--inputBorderRadius);
}
.card-info-display-wrapper.default-card {
  box-shadow: 0px 2px 10px var(--btnBgBlue);
}
.card-info-display-wrapper .card-info-display {
  border-radius: var(--inputBorderRadius);
  width: 280px;
  height: 163px;
  background-image: url(../../img/images/credit_card_container.svg);
  background-size: cover;
  padding: 20px;
  display: grid;
  position: relative;
}
.card-info-display-wrapper .card-info-display .card-delete {
  position: absolute;
  right: 38px;
  bottom: 20px;
  color: var(--shipmentRed);
  cursor: pointer;
  transition: all 150ms linear;
  border-radius: 50%;
  box-shadow: var(--shadow);
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-info-display-wrapper .card-info-display .card-delete .card-delete-icon {
  width: 24px;
  height: 24px;
}
.card-info-display-wrapper .card-info-display .card-delete:hover {
  color: var(--notRed);
  box-shadow: 0px 0px 4px var(--notRed);
  transition: all 150ms linear;
}
.card-info-display-wrapper .card-info-display.display-back {
  background-image: url(../../img/images/credit_card_back_container.svg);
  justify-content: center;
  align-items: center;
}
.card-info-display-wrapper .card-info-display.display-back .card-back {
  color: var(--subtitleDarkBlue);
  margin-left: 75px;
  margin-top: 20px;
}
.card-info-display-wrapper .card-info-display .card-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--gap);
  color: var(--lighterBg);
}
.card-info-display-wrapper .card-info-display .card-bottom {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  justify-content: flex-end;
  align-items: flex-start;
  color: var(--lighterBg);
  margin-bottom: 10px;
}/*# sourceMappingURL=RegisteredCards.css.map */