.package-list {
  background-color: var(--lighterBg);
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
  border-radius: var(--inputBorderRadius);
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  min-height: 90vh;
  overflow-y: auto;
  position: relative;

  .package-list-info {
    font-size: 13px;
    background-color: var(--pastelBlue);
    border-radius: var(--wrapperBorderRadius);
    color: var(--btnBgBlue);
    padding: 10px;
    box-shadow: var(--shadow);
    margin-right: var(--gap2x);

    a {
      font-size: inherit;
      color: var(--btnBgBlue);
    }
  }

  .package-list-table-container {
    .package-list-table {
      min-width: 800px;
      overflow-x: auto;

      .package-list-table-body {
        .package-list-table-body-row {
          .package-list-table-body-row-cell {
            color: var(--text);
            .cell-select {
              border-radius: var(--buttonBorderRadius);

              &.disabled {
                background-color: var(--inputBorderColor);

                fieldset {
                  border-color: var(--inputBorderColor);
                }
              }
            }

            input {
              width: 100%;
              outline: none;
              border: 1px solid var(--inputBorderColor);
              border-radius: var(--buttonBorderRadius);
              padding: 5px 10px;

              &:disabled {
                background-color: var(--inputBorderColor);
                border-color: var(--inputBorderColor);
              }
            }
            
            input {
              width: 100%;
              outline: none;
              border: 1px solid var(--inputBorderColor);
              border-radius: var(--buttonBorderRadius);
              padding: 5px 10px;

              &:disabled {
                background-color: var(--inputBorderColor);
                border-color: var(--inputBorderColor);
              }
            }
          }
        }
      }
    }
  }

  .package-list-buttons {
    display: flex;
    justify-content: flex-end;
    gap: var(--gap);
  }
}

@media (max-width: 768px) {
  .package-list{
    .package-inputs{
      border-bottom: 1px solid rgb(199, 199, 199);
      padding-bottom: 10px;
    }
    .package-inputs-first, .package-inputs-second{
      display: flex;
      flex-direction: column;
      div{
        width: 100%;
      }
    }
  }
  .fast-dimension-modal {
    width: 90%;
    margin: auto;
  }
  .fast-dimension {
    width: 95%;
    max-height: 500px;
    overflow: auto;

    .fast-dimension-input {
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      div{
        width: 140px; 
        text-align: center;
      }
    }
    .form-dimensions-weight {
      flex-direction: column;
      width: 100%;
    }
  }
}