.timer-sms-modal {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .timer-sms-modal-box {
    background: white;
    max-width: 500px;
    width: 100%;
    margin: auto;
    padding: 20px;
    display: grid;
    align-items: center;
    justify-items: center;
    border-radius: 10px;
    min-height: 500px;
    outline: 0;

    .timer-sms-div {
      display: flex;
      flex-direction: column;
      gap: var(--gap2x);

      input {
        border-radius: var(--inputBorderRadius);
        width: 60px !important;
        height: 60px !important;
        border: none;
        background-color: var(--phoneDigitBg);
        outline-color: var(--info);
      }

      .timer-sms-div-button {
        background-color: var(--info);
        border: none;
        outline: none;
        padding: 10px 60px;
        width: fit-content;
        font-size: 14px;
        border-radius: var(--inputBorderRadius);
        color: white;
        cursor: pointer;
        transition: all 150ms linear;
        margin: auto;

        &:hover {
          background-color: var(--blueBrand);
          transition: all 150ms linear;
        }
      }
    }
  }
}