.orders {
  min-height: 100vh;
  background-color: var(--background);

  .order-title {
    background-color: white;
    box-shadow: var(--shadow);
    margin: -20px 0 0 -20px;

    .order-title-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 20px;
      gap: var(--gap2x);

      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      .order-title-headline {
        gap: var(--gap);
        display: flex;
        align-items: center;
        color: var(--priceText);

        h1 {
          font-size: 20px;
          line-height: 25px;
          font-weight: 500;
        }
      }

      .order-title-how-link {
        border: 1px solid var(--btnBgBlue);
        color: var(--btnBgBlue);
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        border-radius: var(--buttonBorderRadius);
        cursor: pointer;
        transition: all 150ms linear;

        &:hover {
          box-shadow: var(--shadow);
        }

        svg {
          width: 16px;
          height: 16px;
        }

        span {
          font-size: 16px;
        }
      }
    }
  }

  .orders-wrapper {
    display: grid;
    width: 100%;
    gap: var(--gap2x);
    padding-top: 20px;

    .orders-wrapper-loading {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 500px;
    }

    .order-detail-search {
      background-color: var(--lighterBg);
      padding: 20px;
      border-radius: var(--wrapperBorderRadius);
      box-shadow: var(--shadow);

      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      .order-detail-search-header {
        display: flex;
        align-items: center;
        gap: var(--gap2x);
        padding: 0;

        .order-detail-search-select-all {
          min-width: 140px;
          padding-left: 10px;
        }

        .order-detail-search-button {
          display: flex;
          gap: var(--gap);
          width: 100%;

          button {
            width: 100%;
          }
        }
      }

      .order-detail-search-collapse {
        .order-detail-search-content {
          .order-detail-search-form {
            display: flex;
            flex-direction: column;
            gap: var(--gap);

            .order-detail-search-form-children {
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax((150px, 1fr)));
              gap: var(--gap2x);
            }

            .order-detail-search-form-submit {
              display: flex;
              gap: var(--gap);
              justify-content: flex-end;
              align-items: center;
            }
          }
        }
      }
    }

    .order-list {
      display: flex;
      flex-direction: column;
      gap: var(--gap);
      max-height: 500px;
      overflow: auto;

      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      .order-list-none-item {
        display: flex;
        flex-direction: column;
        gap: var(--gap);

        .order-list-none-item-info {
          display: flex;
          align-items: center;
          gap: 5px;
          background-color: var(--pastelBlue);
          color: var(--btnBgBlue);
          padding: 5px 10px;
          border-radius: var(--wrapperBorderRadius);
        }
      }

      .order-list-item {
        color: var(--priceText);
        display: flex;
        align-items: center;
        gap: var(--gap);

        background-color: var(--lighterBg);
        padding: 5px 20px;
        border-radius: var(--wrapperBorderRadius);
        box-shadow: var(--shadow);

        .item-info-wrapper {
          display: flex;
          width: 100%;
          justify-content: space-between;
          gap: var(--gap);

          .item-info {
            display: flex;
            align-items: center;
            gap: var(--gap);
            width: 100%;
            justify-content: space-evenly;

            div {
              font-size: 12px;
            }

            .item-info-doctime {
              width: 65px;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 3px;

              .item-info-doc {
                text-align: center;
              }

              .item-info-time {
                text-align: center;
              }
            }

            .item-info-parcel {
              width: 100px;
            }

            .item-info-tracking {
              display: flex;
              flex-direction: column;
              gap: 3px;
              width: 150px;

              .tracking-container {
                background-color: var(--background);
                padding: 3px 5px;
                border-radius: var(--buttonBorderRadius);
                display: flex;
                flex-direction: column;
                gap: 2px;
                color: var(--priceText);

                .tracking-title {
                  text-align: center;
                  font-weight: 700;
                  font-size: 13px;
                }

                .tracking-text {
                  text-align: center;
                  font-weight: 500;
                  font-size: 11px;
                }
              }
            }

            .item-info-price-wrapper {
              display: flex;
              flex-direction: column;
              gap: 3px;

              .item-info-price {
                width: 100px;

                .currency-price {
                  font-size: 12px;

                  &.try {
                    font-size: 16px;
                    color: var(--btnBgBlue);
                  }
                }

                .currency {
                  font-size: 12px;
                  padding-left: 3px;

                  &.info {
                    color: var(--btnBgBlue);
                  }
                }
              }
            }

            .item-info-child {
              &.marketplace {
                width: 50px;
                display: flex;
                flex-direction: column;
                text-align: center;
                align-items: center;
                font-weight: 700;
                font-size: 10px;
                color: var(--priceText);

                .avatar {
                  width: 40px;
                  height: auto;
                }
              }

              .item-info-child-item {
                display: flex;
                align-items: center;
                gap: var(--gap);

                .item-info-name {
                  width: 85px;
                }

                .item-info-country {
                  display: flex;
                  flex-direction: column;
                  width: 50px;

                  .item-info-country-icons {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    &.land-in {
                      color: var(--shipmentGreen);
                    }

                    &.land-out {
                      color: var(--shipmentRed);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .order-count {
      background-color: var(--lighterBg);
      padding: 20px;
      border-radius: var(--wrapperBorderRadius);
      box-shadow: var(--shadow);

      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      display: flex;
      gap: var(--gap3x);

      .order-count-item {
        .order-count-item-headline {
          color: black;
          font-weight: 700;
          font-size: 14px;
        }

        .order-count-item-subline {
          color: var(--btnBgBlue);
          font-weight: 500;
          font-size: 14px;
        }
      }
    }

    .order-basket {
      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      .order-basket-wrapper {
        // display: flex;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        gap: var(--gap2x);

        .order-button {
          background-color: var(--lighterBg);
          padding: 10px 20px;
          border-radius: var(--wrapperBorderRadius);
          box-shadow: var(--shadow);
          transition: all 150ms linear;
          cursor: pointer;
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: center;
          gap: var(--gap);

          &:hover {
            box-shadow: var(--bluestShadow);
            transition: all 150ms linear;
          }

          &.selected {
            box-shadow: var(--bluestShadow);
          }

          &.disabled {
            opacity: 0.3;
            cursor: default;
            user-select: none;

            &:hover {
              box-shadow: var(--shadow);
            }
          }

          .order-button-headline {
            font-size: 16px;
            font-weight: 700;
            color: black;
            text-align: center;
          }

          .order-button-image {
            height: 100px;
          }

          p {
            font-size: 12px;
            text-align: center;
            color: var(--priceText);
            font-weight: 700;

            span {
              color: var(--btnBgBlue);
              font-size: inherit;
              font-weight: 500;
            }
          }
        }

        .order-basket-total {
          background-color: var(--lighterBg);
          padding: 20px;
          border-radius: var(--wrapperBorderRadius);
          box-shadow: var(--shadow);
          width: 100%;

          display: flex;
          flex-direction: column;
          gap: var(--gap);
          justify-content: space-between;

          .order-basket-total-item {
            display: flex;
            justify-content: space-between;

            .total-item-child {
              font-size: 13px;

              &.headline {
                color: black;
                font-weight: 700;
              }

              &.blue {
                color: var(--btnBgBlue);
              }

              .red {
                color: var(--shipmentRed);
                font-weight: 700;
              }

              .blue {
                color: var(--btnBgBlue);
                font-weight: 700;
              }
            }
          }

          .total-item-final-price {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }

    .order-domestic {
      background-color: var(--lighterBg);
      padding: 20px;
      border-radius: var(--wrapperBorderRadius);
      box-shadow: var(--shadow);

      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      .order-domestic-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(155px, 1fr));
        gap: var(--gap);

        .order-domestic-item {
          display: flex;
          flex-direction: column;
          gap: var(--gap);
          align-items: center;
          box-shadow: var(--shadow);
          padding: 5px;
          border-radius: var(--inputBorderRadius);
          transition: all 150ms linear;

          &.selected {
            box-shadow: var(--blueShadow);
            transition: all 150ms linear;
          }

          img {
            height: 31px;
          }

          .domestic-item-title {
            font-size: 12px;
            font-weight: 600;
          }

          .domestic-item-subtitle {
            font-size: 10px;
          }

          .domestic-item-price {
            font-size: 14px;
            font-weight: 600;
            color: var(--btnBgBlue);
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .orders {
    .order-title {
      .order-title-wrapper {
        gap: var(--gap);

        .order-title-headline {
          h1 {
            font-size: 16px;
            line-height: 20px;
          }
        }

        .order-title-how-link {
          svg {
            width: 13px;
            height: 13px;
          }

          span {
            font-size: 13px;
          }
        }
      }
    }
  }
}
