.registered-cards-wrapper {
  background-color: var(--background);
  min-height: 100vh;

  .registered-cards,
  .registered-dimensions {
    background-color: var(--lighterBg);
    padding: 20px 30px;
    width: 100%;
    border-radius: var(--wrapperBorderRadius);

    .profile-card-wrapper {
      display: flex;
      align-items: center;
      padding: 0;

      .profile-card-header {
        display: flex;
        align-items: center;
        gap: var(--gap);
      }
    }

    .card-adder-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-top: var(--gap);

      .card-adder {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        color: var(--info);
        display: flex;
        align-items: center;
        justify-self: flex-end;
        gap: 10px;
        font-weight: 700;
      }
    }

    .card-spring-in {
      .default-card {
        display: grid;
        justify-content: center;
        width: 100%;
        max-width: 600px;
        margin: var(--gap3x) auto 0;

        .card-info-wrapper {
          display: flex;
          width: 100%;
          gap: var(--gap2x);

          .card-info-text {
            display: grid;
            align-content: flex-start;
            width: 100%;
            gap: var(--gap2x);

            .card-info-text-date {
              display: flex;
              gap: var(--gap);
            }
          }

          .card-info-display-wrapper {
            display: flex;
            max-width: 330px;
            width: 100%;

            .card-info-display {
              border-radius: var(--inputBorderRadius);
              width: 330px;
              height: 200px;
              background-image: url(../../img/images/credit_card_container.svg);
              background-size: cover;
              padding: 20px;
              display: grid;

              &.display-back {
                background-image: url(../../img/images/credit_card_back_container.svg);
                justify-content: center;
                align-items: center;

                .card-back {
                  color: var(--subtitleDarkBlue);
                  margin-left: 75px;
                  margin-top: 20px;
                }
              }

              .card-top {
                display: flex;
                justify-content: flex-end;
                color: var(--lighterBg);
              }

              .card-bottom {
                display: flex;
                flex-direction: column;
                gap: var(--gap);
                justify-content: flex-end;
                align-items: flex-start;
                color: var(--lighterBg);
                margin-bottom: 10px;
              }
            }
          }
        }

        .card-info-register {
          display: flex;
          justify-content: flex-end;
          margin-top: var(--gap3x);
          width: 100%;
          flex-wrap: wrap;
          gap: var(--gap);
        }
      }

      .default-dimension {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: var(--gap3x) 0 0;

        .dimension-info-wrapper {
          display: grid;
          justify-content: center;
          grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
          width: 100%;
          gap: var(--gap2x);
        }

        .dimension-info-register {
          display: flex;
          justify-content: flex-end;
          margin-top: var(--gap3x);
          width: 100%;

          button {
            padding: 7px 30px;
            background-color: var(--btnBgBlue);
            border-radius: var(--buttonBorderRadius);
            border: 1px solid var(--btnBgBlue);
            color: var(--lighterBg);
            cursor: pointer;
            transition: all 150ms linear;
            font-size: var(--smallTextSize);

            &:hover {
              background-color: var(--blueBrand);
              border: 1px solid var(--blueBrand);
              transition: all 150ms linear;
            }
          }
        }
      }
    }

    .dimensions {
      .dimension-list {
        .dimensions-display-wrapper {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
          //justify-content: center;
          gap: var(--gap2x);
          margin-top: var(--gap2x);

          .dimensions-display {
            border: 1px solid var(--inputBorderColor);
            border-radius: var(--inputBorderRadius);

            .display-header {
              padding: var(--padding);
              font-size: var(--smallTextSize);
              color: var(--priceText);
            }

            .display-content {
              padding: var(--padding);
              display: grid;
              gap: var(--gap2x);

              div {
                font-size: var(--smallTextSize);
                color: var(--priceText);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .registered-cards-wrapper {
    .registered-cards {
      padding: 20px;
    }
  }
}

@media (max-width: 800px) {
  .registered-cards-wrapper {
    .registered-cards {
      .card-spring-in {
        .default-card {
          .card-info-wrapper {
            flex-direction: column-reverse;

            .card-info-text {
              .card-info-text-date {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .registered-cards-wrapper {
    .registered-cards {
      .card-spring-in {
        .default-card {
          .card-info-wrapper {
            .card-info-text {
              .card-info-text-date {
                flex-direction: column;
              }
            }

            .card-info-display-wrapper {
              .card-info-display {
                width: 280px;
                height: 163px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .registered-cards-wrapper {
    .registered-cards {
      padding: 10px;

      .card-spring-in {
        .default-card {
          .card-info-wrapper {
            .card-info-display-wrapper {
              .card-info-display {
                width: 280px;
                height: 163px;
              }
            }
          }
        }

        .default-dimension {
          .dimension-info-wrapper {
            grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .registered-cards-wrapper {
    .registered-cards {
      .card-spring-in {
        .default-card {
          .card-info-wrapper {
            .card-info-display-wrapper {
              .card-info-display {
                width: 200px;
                height: 120px;
              }
            }
          }
        }
      }
    }
  }
}

.card-info-display-wrapper {
  display: flex;
  max-width: 280px;
  width: 100%;
  font-size: 13px;
  padding: 10px;
  box-shadow: var(--shadow);
  border-radius: var(--inputBorderRadius);

  &.default-card {
    box-shadow: 0px 2px 10px var(--btnBgBlue);
  }

  .card-info-display {
    border-radius: var(--inputBorderRadius);
    width: 280px;
    height: 163px;
    background-image: url(../../img/images/credit_card_container.svg);
    background-size: cover;
    padding: 20px;
    display: grid;
    position: relative;

    .card-delete {
      position: absolute;
      right: 38px;
      bottom: 20px;
      color: var(--shipmentRed);
      cursor: pointer;
      transition: all 150ms linear;
      border-radius: 50%;
      box-shadow: var(--shadow);
      width: 33px;
      height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;

      .card-delete-icon {
        width: 24px;
        height: 24px;
      }

      &:hover {
        color: var(--notRed);
        box-shadow: 0px 0px 4px var(--notRed);
        transition: all 150ms linear;
      }
    }

    &.display-back {
      background-image: url(../../img/images/credit_card_back_container.svg);
      justify-content: center;
      align-items: center;

      .card-back {
        color: var(--subtitleDarkBlue);
        margin-left: 75px;
        margin-top: 20px;
      }
    }

    .card-top {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: var(--gap);
      color: var(--lighterBg);
    }

    .card-bottom {
      display: flex;
      flex-direction: column;
      gap: var(--gap);
      justify-content: flex-end;
      align-items: flex-start;
      color: var(--lighterBg);
      margin-bottom: 10px;
    }
  }
}
