.courier-registration {
  min-height: 100vh;
  background-color: var(--background);
}
.courier-registration .courier-registration-wrapper {
  display: grid;
  width: 100%;
  gap: var(--gap2x);
}
.courier-registration .courier-registration-wrapper .courier-district {
  background-color: var(--lighterBg);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
  border-radius: var(--inputBorderRadius);
  padding: 20px;
  display: grid;
  gap: var(--gap2x);
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.courier-registration .courier-registration-wrapper .courier-district .courier-district-header {
  display: grid;
  gap: var(--gap2x);
}
.courier-registration .courier-registration-wrapper .courier-district .courier-district-header .district-not-service {
  text-align: center;
  color: var(--priceText);
  font-weight: 700;
}
.courier-registration .courier-registration-wrapper .courier-district .courier-district-header .district-postal-search {
  display: flex;
  justify-content: center;
  gap: var(--gap);
}
.courier-registration .courier-registration-wrapper .courier-district .courier-district-header .district-postal-search .district-postal-code-button {
  background-color: var(--info);
  color: var(--lighterBg);
  text-transform: none;
  transition: all 150ms linear;
}
.courier-registration .courier-registration-wrapper .courier-district .courier-district-header .district-postal-search .district-postal-code-button:hover {
  background-color: var(--blueBrand);
  transition: all 150ms linear;
}
.courier-registration .courier-registration-wrapper .courier-district .courier-district-header .district-service {
  text-align: center;
  color: var(--priceText);
  font-weight: 700;
}
.courier-registration .courier-registration-wrapper .courier-district .courier-district-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(185px, 1fr));
  gap: var(--gap2x);
}
.courier-registration .courier-registration-wrapper .courier-district .courier-district-main .district-choose-button {
  text-transform: none;
  color: var(--priceText);
  padding: 7px 16px;
  border-radius: var(--buttonBorderRadius);
  border: 1px solid var(--inputBorderColor);
  font-size: 12px;
}
.courier-registration .courier-registration-wrapper .courier-button {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.courier-registration .courier-registration-wrapper .create-courier-registration {
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--inputBorderRadius);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
  display: grid;
  gap: var(--gap2x);
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.courier-registration .courier-registration-wrapper .create-courier-registration h6 {
  color: var(--priceText);
}
.courier-registration .courier-registration-wrapper .create-courier-registration .create-courier-registration-form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--gap2x);
}
.courier-registration .courier-registration-wrapper .create-courier-registration .create-courier-registration-form input[type=number]::-webkit-inner-spin-button,
.courier-registration .courier-registration-wrapper .create-courier-registration .create-courier-registration-form input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.courier-registration .courier-registration-wrapper .create-courier-registration .create-courier-registration-form-done {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.courier-registration .courier-registration-wrapper .create-courier-registration .create-courier-registration-form-done button {
  background-color: var(--info);
  color: var(--lighterBg);
  padding: 5px 15px;
  border-radius: var(--buttonBorderRadius);
  transition: all 150ms linear;
  text-transform: none;
  font-size: 13px;
}
.courier-registration .courier-registration-wrapper .create-courier-registration .create-courier-registration-form-done button:hover {
  background-color: var(--blueBrand);
}
.courier-registration .courier-registration-wrapper .courier-list {
  display: grid;
  justify-items: center;
  gap: var(--gap2x);
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item {
  display: flex;
  max-width: 1400px;
  width: 100%;
  justify-content: space-between;
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
  gap: var(--gap);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-detail {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-detail .delivery-top {
  display: flex;
  justify-content: space-between;
  gap: var(--gap2x);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-detail .delivery-top .delivery-code {
  color: var(--priceText);
  font-weight: 700;
  font-size: 13px;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-detail .delivery-top .delivery-code .delivery-code-span {
  color: var(--info);
  font-weight: 700;
  font-size: 13px;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-detail .delivery-top .registration-date {
  color: var(--priceText);
  font-weight: 700;
  font-size: 13px;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-detail .delivery-top .registration-date .registration-date-span {
  font-weight: 400;
  font-size: 13px;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-detail .delivery-address {
  color: var(--priceText);
  font-weight: 700;
  font-size: 13px;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-detail .delivery-address .delivery-address-span {
  font-weight: 400;
  font-size: 13px;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-confirm {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: var(--gap2x);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-confirm .delivery-status {
  font-size: 13px;
  text-align: center;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 9999px;
  font-weight: 600;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-confirm .delivery-status.active {
  background-color: var(--pastelGreen);
  color: var(--greenBrand);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-confirm .delivery-status.waiting {
  background-color: var(--pastelYellow);
  color: var(--orangeBrand);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-confirm .delivery-status.on-the-way {
  background-color: var(--pastelBlue);
  color: var(--info);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-confirm .delivery-status.closed {
  background-color: var(--pastelRed);
  color: var(--redBrand);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-confirm .registration-abort {
  text-transform: none;
  font-size: 13px;
  background-color: var(--info);
  color: var(--lighterBg);
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--redBrand);
  transition: all 150ms linear;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-confirm .registration-abort.active {
  background-color: var(--info);
  color: var(--lighterBg);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-confirm .registration-abort.active:hover {
  background-color: var(--blueBrand);
  color: var(--lighterBg);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-confirm .registration-abort:hover {
  background-color: red;
  transition: all 150ms linear;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered {
  max-width: 1400px;
  width: 100%;
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .delivery-status-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--gap2x);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .delivery-status-wrapper .delivery-status {
  font-size: 13px;
  max-width: 150px;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 9999px;
  font-weight: 600;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .delivery-status-wrapper .delivery-status.active {
  background-color: var(--pastelGreen);
  color: var(--greenBrand);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail {
  display: flex;
  gap: var(--gap);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail .courier-column {
  display: flex;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail .courier-column .delivery-column {
  width: 240px;
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail .courier-column .delivery-column .delivery-column-row {
  color: var(--priceText);
  font-weight: 700;
  font-size: 13px;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail .courier-column .delivery-column .delivery-column-row .delivery-code-span {
  color: var(--info);
  font-weight: 700;
  font-size: 13px;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail .courier-column .delivery-column .delivery-column-row .delivery-column-row-span {
  font-size: 13px;
  font-weight: 400;
  color: var(--priceText);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail .delivery-address {
  color: var(--priceText);
  font-weight: 700;
  font-size: 13px;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail .delivery-address .delivery-address-span {
  font-weight: 400;
  font-size: 13px;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail-show {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: var(--gap2x);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail-show button {
  text-transform: none;
  background-color: var(--info);
  color: var(--lighterBg);
  transition: all 150ms linear;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 35px;
  padding: 0 10px;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail-show button svg {
  width: 16px !important;
  height: 16px !important;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail-show button:hover {
  background-color: var(--blueBrand);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-details {
  max-width: 1400px;
  width: 100%;
  justify-content: space-between;
  background-color: var(--lighterBg);
  padding: 20px 0;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-details .list-item-details {
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 1fr));
  width: 100%;
  justify-items: center;
  align-items: center;
  padding: 10px;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-details .list-item-details .list-item-detail {
  color: var(--priceText);
  font-size: 13px;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-details .list-item-details .list-item-detail .list-item-detail-span {
  font-size: 13px;
  font-weight: 700;
  color: var(--info);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-details .list-item-details .list-item-status {
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  font-size: 13px;
  width: 150px;
  font-weight: 600;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-details .list-item-details .list-item-status.active {
  color: var(--greenBrand);
  background-color: var(--pastelGreen);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-details .list-item-details .list-item-status.closed {
  background-color: var(--pastelRed);
  color: var(--redBrand);
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-details .list-items-details-close {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-details .list-items-details-close button {
  background-color: var(--info);
  text-transform: none;
  color: var(--lighterBg);
  transition: all 150ms linear;
  height: 35px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.courier-registration .courier-registration-wrapper .courier-list .courier-list-item-details .list-items-details-close button:hover {
  background-color: var(--blueBrand);
  transition: all 150ms linear;
}

@media (max-width: 1100px) {
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-detail {
    max-width: 500px;
  }
}
@media (max-width: 1000px) {
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-detail {
    max-width: 400px;
  }
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail .courier-column {
    flex-direction: column;
    gap: var(--gap);
  }
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail .courier-column .delivery-column {
    gap: var(--gap);
  }
}
@media (max-width: 900px) {
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item {
    gap: var(--gap);
  }
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-detail {
    max-width: 300px;
  }
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-confirm .registration-abort {
    width: 160px;
  }
}
@media (max-width: 800px) {
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item-details .list-item-details {
    grid-template-columns: repeat(1, minmax(150px, 1fr));
    gap: var(--gap);
  }
}
@media (max-width: 700px) {
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item {
    flex-direction: column;
  }
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-confirm {
    flex-direction: row;
    margin-top: 10px;
  }
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-confirm .delivery-status {
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail {
    flex-direction: column;
  }
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail .courier-column {
    flex-direction: column;
    gap: var(--gap);
  }
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail .courier-column .delivery-column {
    gap: var(--gap);
    width: auto;
  }
}
@media (max-width: 420px) {
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-confirm {
    flex-direction: column;
    align-items: center;
  }
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-confirm .delivery-status {
    width: 100%;
  }
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item .courier-list-item-confirm .registration-abort {
    width: 100%;
  }
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .delivery-status-wrapper {
    justify-content: inherit;
  }
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .delivery-status-wrapper .delivery-status {
    max-width: 100%;
  }
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail-show {
    display: flex;
    justify-content: inherit;
    width: 100%;
    margin-top: var(--gap2x);
  }
  .courier-registration .courier-registration-wrapper .courier-list .courier-list-item-delivered .courier-list-item-detail-show button {
    width: 100%;
  }
}/*# sourceMappingURL=CourierRegistration.css.map */