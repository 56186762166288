.captcha-wrapper {
  border-radius: var(--inputBorderRadius);
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  gap: var(--gap);
  width: 100%;
}
.captcha-wrapper img {
  border: 1px solid;
  border-radius: 7px;
  border-color: var(--inputBorderColor);
}
.captcha-wrapper .captcha {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap);
}
.captcha-wrapper .captcha-field {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  width: 100%;
}

@media (max-width: 600px) {
  .captcha-wrapper {
    flex-direction: column-reverse;
  }
}/*# sourceMappingURL=Captcha.css.map */