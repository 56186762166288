*,
*::before,
*::after {
  font-size: 14px;
}

.fast-shipment {
  background-color: var(--background);
  min-height: 100vh;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container {
  display: grid;
  gap: var(--gap3x);
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
  max-width: 1400px;
  width: 100%;
  margin: auto;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-buttons {
  display: flex;
  align-items: center;
  gap: var(--gap2x);
  width: 100%;
}
@media (max-width: 768px) {
  .fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 450px;
  }
  .fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-buttons button {
    width: 100%;
  }
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: var(--lighterBg);
  border-radius: var(--inputBorderRadius);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line {
  display: flex;
  align-items: center;
  gap: 35px;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .list-item-check {
  margin-right: 0;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .list-item-header {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100px;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .w-borders {
  border-right: 1px solid rgb(207, 207, 207);
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .list-item-direction {
  display: flex;
  gap: var(--gap);
  align-items: center;
  width: 100px;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
  text-align: center;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .list-item-select-inputs {
  display: flex;
  gap: 5px;
  width: 300px;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .list-item-select-inputs div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .list-item-select-inputs div button {
  height: 25px;
  width: 130px;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .list-item-select-inputs img {
  height: 16px !important;
  margin-right: 5px;
}
@media (max-width: 1360px) {
  .fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .list-item-select-inputs {
    flex-direction: column;
    justify-content: center;
  }
  .fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .fast-shipment-buttons .fast-shipment-buttons-price {
    flex-direction: column;
    width: 100%;
  }
  .fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .fast-shipment-buttons .fast-shipment-buttons-price button {
    width: 100%;
  }
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .fast-shipment-buttons {
  display: flex;
  align-items: center;
  gap: 4px;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .fast-shipment-buttons .fast-shipment-buttons-price {
  display: flex;
  align-items: center;
  gap: 4px;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .fast-shipment-buttons .fast-address {
  background-color: blue !important;
}
@media (max-width: 1430px) {
  .fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .fast-shipment-buttons {
    flex-direction: column;
    justify-content: center;
    width: 200px;
  }
  .fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .fast-shipment-buttons .fast-address {
    width: 100%;
  }
  .fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .fast-shipment-buttons .fast-shipment-buttons-price .fast-dimension-button {
    width: 100%;
    min-width: 75px;
  }
  .fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-line .fast-shipment-buttons .fast-shipment-buttons-price .fast-price {
    min-width: 105px;
  }
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-price {
  display: flex;
  font-size: 30px;
  text-align: center;
  gap: var(--gap);
  justify-content: center;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-price .price-text {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .line .list-item-price.none {
  display: none;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .card {
  background-color: var(--lighterBg);
  border-radius: var(--inputBorderRadius);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  gap: var(--gap);
  display: none;
  align-items: center;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .card .list-item-header {
  display: flex;
  height: 100%;
  gap: var(--gap);
  position: relative;
  justify-content: center;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .card .list-item-header .list-item-check {
  position: absolute;
  left: 5px;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .card .list-item-header .list-item-select-child {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .card .fast-shipment-buttons {
  display: flex;
  gap: 5px;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .card .fast-shipment-buttons button {
  width: 100%;
}
.fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .card .list-item-direction {
  display: flex;
  gap: var(--gap);
  align-items: center;
}
@media (max-width: 1023px) {
  .fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: var(--gap);
  }
  .fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list .fast-shipment-list-item-wrapper .card {
    display: grid;
  }
}
@media (max-width: 1366px) {
  .fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list {
    max-width: 1100px;
    width: 100%;
    margin: auto;
  }
}
@media (max-width: 1200px) {
  .fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list {
    max-width: 800px;
    width: 100%;
    margin: auto;
  }
}
@media (max-width: 768px) {
  .fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list {
    max-width: 650px;
    width: 100%;
    margin: auto;
  }
}
@media (max-width: 468px) {
  .fast-shipment .fast-shipment-wrapper .fast-shipment-container .fast-shipment-list {
    max-width: 340px;
    width: 100%;
    margin: auto;
  }
}
@media (max-width: 768px) {
  .fast-shipment .fast-shipment-wrapper {
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  .fast-shipment {
    min-width: 100%;
  }
}/*# sourceMappingURL=FastShipment.css.map */