.basket-detailed {
  min-height: 100vh;
  background-color: var(--background);

  .basket-detailed-wrapper {
    display: grid;
    width: 100%;
    gap: var(--gap2x);
    padding-bottom: 20px;

    .basket-no-item {
      text-align: center;
      color: var(--priceText);
      font-weight: 700;
      width: 100%;
      font-size: 16px;
    }

    .basket-loading-wrapper {
      height: 50vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .basket-title-wrapper {
      background-color: var(--lighterBg);
      margin: -20px -20px 20px -20px;

      .basket-title {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        color: var(--priceText);
        display: flex;
        align-items: center;
        gap: var(--gap);
        padding: 20px;

        img {
          width: 30px;
          height: 30px;
        }
      }
    }

    .basket-detailed-payment {
      display: flex;
      margin-bottom: var(--gap2x);
      box-shadow: var(--shadow);
      border-radius: var(--wrapperBorderRadius);
      padding: 20px;
      max-width: 1400px;
      margin: 0 auto 10px;
      width: 100%;
      align-items: center;
      gap: var(--gap);

      &.success {
        background-color: var(--lighterBg);
        justify-content: space-between;
      }

      &.error {
        background-color: var(--shipmentRed);
        color: var(--lighterBg);
        justify-content: flex-start;
      }

      .basket-detailed-payment-checkbox {
        span {
          font-size: 11px;
          font-weight: 700;
          color: var(--priceText);
        }
      }

      .button-container {
        display: flex;
        gap: var(--gap2x);
        flex-wrap: wrap;
      }
    }

    .basket-detailed-container {
      .basket-detailed-items-wrapper {
        max-width: 1400px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: var(--gap);

        .basket-detailed-items-container {
          display: flex;
          justify-content: flex-end;

          .basket-detailed-items {
            display: flex;
            flex-direction: column;
            gap: var(--gap2x);
            justify-content: flex-end;
            align-items: flex-end;
            padding: 20px;
            background-color: var(--lighterBg);
            box-shadow: var(--shadow);
            border-radius: var(--wrapperBorderRadius);
            max-width: 350px;
            width: 100%;

            .items-price {
              display: flex;
              justify-content: space-between;
              width: 100%;
              gap: var(--gap);
              font-weight: 700;
            }

            .items-price-divider {
              height: 1px;
              width: 100%;
              background-color: var(--priceText);
            }
          }
        }

        .basket-detailed-list {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .basket-detailed-list-item {
            display: flex;
            gap: var(--gap);
            align-items: center;
            background-color: var(--lighterBg);
            box-shadow: var(--shadow);
            border-radius: var(--wrapperBorderRadius);
            padding: 5px 10px;
            justify-content: space-between;

            .list-item-marketplace {
              width: 40px;
              display: flex;
              flex-direction: column;
              text-align: center;
              align-items: center;
              font-weight: 700;
              font-size: 10px;
              color: var(--priceText);

              .avatar {
                width: 40px;
                height: auto;
              }
            }

            .list-item-flag {
              display: flex;
              align-items: center;
              gap: var(--gap);

              .list-item-flag-country {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 46px;

                .flag-country-name {
                  font-size: 10px;
                  text-align: center;
                }

                .flag-country-icon-wrapper {
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  .flag-country-icon {
                    width: 24px;
                    height: auto;

                    &.takeoff {
                      color: var(--shipmentRed);
                    }

                    &.land {
                      color: var(--shipmentGreen);
                    }
                  }
                }
              }
            }

            .list-item-avatar {
              display: flex;
              align-self: center;
              height: 40px;
              width: 40px;
            }

            .list-item-pricing-area {
              display: flex;
              gap: var(--gap);

              .pricing-area-weight {
                color: var(--priceText);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 70px;
              }

              .pricing-area-foreign-money {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 2px;

                .foreign-money {
                  color: var(--priceText);
                }

                .foreign-money-exchange-rate {
                  color: var(--btnBgBlue);
                  background-color: var(--background);
                  padding: 2px 5px;
                  border-radius: var(--buttonBorderRadius);
                  text-align: center;
                }
              }
            }

            .list-item-local-price {
              color: var(--btnBgBlue);
              background-color: var(--background);
              padding: 2px 5px;
              border-radius: var(--buttonBorderRadius);
              font-weight: 700;
              width: 100px;
              text-align: center;
            }

            .delete-progress {
              display: flex;
              justify-content: center;
              align-self: center;
              outline: none;
              width: 16px !important;
              height: 16px !important;
            }

            .list-item-delete-button {
              outline: none;
              background-color: var(--pastelRed);
              transition: all 150ms linear;

              &:hover {
                background-color: var(--shipmentRed);

                .list-item-delete-button-icon {
                  color: var(--pastelRed);
                }
              }

              .list-item-delete-button-icon {
                display: flex;
                justify-content: center;
                align-self: center;
                width: 24px;
                height: 24px;
                color: var(--shipmentRed);
                transition: all 150ms linear;
                outline: none;
              }
            }
          }
        }
      }
    }
  }
}
