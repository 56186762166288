.iyzi-header {
  position: relative;
  z-index: 2000;

  .top-bg {
    background-color: var(--btnBgBlue);
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: center;

    .top-bg-container {
      display: flex;
      justify-content: space-between;
      max-width: 1440px;
      padding: 0px 20px;
      width: 100%;

      .top-bg-content {
        display: flex;
        gap: 24px;

        .top-bg-item {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          gap: 5px;
        }
      }
    }
  }

  .iyzi-header-links-wrapper {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

    .iyzi-header-links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 1440px;
      margin: auto;
      padding: 20px;

      .header-link-group {
        display: flex;
        gap: var(--gap3x);
        align-items: center;

        .header-link {
          text-decoration: none;
          font-size: 16px;
          color: var(--priceText);
          font-weight: 500;
          color: #4f4f4f;
          font-style: normal;
          line-height: 24px;

          &.active {
            color: var(--btnBgBlue);
          }
        }
      }
      .menu-icon {
        display: none;
        cursor: pointer;
      }
    }

    .mobile-menu {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: white;
      z-index: 1000;
      max-height: 100vh;
      overflow-y: auto;
      transition: transform 0.3s ease-in-out;
      transform: translateY(38%);
      padding-bottom: 20px;
    }

    .mobile-menu.show {
      transform: translateY(0);
    }
  }
}

.iyzi-main {
  display: flex;
  flex-direction: column;
  gap: var(--gap1x);
  align-items: center;

  .get-start {
    flex-direction: column;

    .field {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 170px;

      .get-start-text {
        max-width: 570px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--gap3x);
        position: relative;
        z-index: 2;
        //justify-content: center;
        margin-top: 50px;

        h1 {
          font-size: 35px;
          line-height: normal;
          font-weight: 400;
          height: auto;
          margin-top: 50px;
          margin-bottom: 15px;

          .blog-text span {
            font-size: inherit;
            width: 100%;
            display: block;
          }

          .dynamic-text {
            height: 42px;
            display: flex;
            align-items: flex-end;
            color: #4f4f4f;
            font-size: 35px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .constant-text {
            color: var(--btnBgBlue);
            font-size: 35px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }

        p {
          font-size: 20px;
          color: #4f4f4f;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
        }

        .main-header-text-buttons {
          display: flex;
          gap: var(--gap2x);
        }
      }
    }
  }

  .integration-getStart {
    gap: 170px;
  }

  .main-header {
    display: flex;
    justify-content: space-between;
    max-width: 1440px;
    width: 100%;
    padding: 20px 20px 0;
    gap: 100px;

    .down-btn {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      margin-top: 80px;
    }

    .main-header-text {
      max-width: 500px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: var(--gap3x);
      position: relative;
      z-index: 2;
      //justify-content: center;
      margin-top: 50px;

      h1 {
        font-size: 35px;
        line-height: normal;
        font-weight: 400;
        height: auto;
        margin-top: 50px;

        .blog-text span {
          font-size: inherit;
          width: 100%;
          display: block;
        }

        .dynamic-text {
          height: 42px;
          display: flex;
          align-items: flex-end;
          color: #4f4f4f;
          font-size: 35px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .constant-text {
          color: var(--btnBgBlue);
          font-size: 35px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 15px;
        }
      }

      p {
        font-size: 20px;
        color: #4f4f4f;
        font-style: normal;
        font-weight: 400;
        line-height: 115.023%;
      }

      .main-header-text-buttons {
        display: flex;
        gap: var(--gap2x);
      }
    }

    .main-header-img {
      //max-width: 573px;
      width: 100%;

      img {
        width: 100%;
        margin-top: 40px;
        max-width: 661px;
      }
    }
  }

  .main-ishowitwork-frame {
    display: flex;
    flex-direction: column;
    gap: var(--gap2x);
    width: 100%;
    margin-bottom: 121px;
    max-width: 1440px;
    padding: 20px;

    .ishowitwork-frame {
      max-width: 1440px;
      width: 100%;

      h2 {
        color: #007fff;
        text-align: center;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 45px;
        margin-bottom: 9px;
      }

      p {
        display: flex;
        max-width: 1016px;
        width: 100%;
        justify-content: center;
        color: #4f4f4f;
        text-align: center;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 120.023%;
        margin-bottom: 30px;
      }

      .button-container {
        display: flex;
        margin-top: 115px;
        margin-bottom: -130px;
        gap: 29px;
        justify-content: center;

        .righ-btn {
          background-color: var(--lighterBg);
          text-transform: none;
          color: var(--btnBgBlue);
          border-radius: var(--wrapperBorderRadius);
          border: 1px solid var(--btnBgBlue);
        }

        .left-btn {
          background-color: var(--btnBgBlue);
          text-transform: none;
          color: var(--lighterBg);
          transition: all 150ms linear;
          border-radius: var(--wrapperBorderRadius);
          border: 1px solid #84c1ff;
        }
      }
    }

    .grid-container-wrapper {
      .background {
        position: absolute;
        width: 100%;
        height: 340px;
        background-color: #f0f2f5;
        margin-top: 70px;
        z-index: -1;
        left: 0;
      }

      .grid-container {
        display: grid;
        grid-template-columns: repeat(4, 300px);
        gap: 20px;
        max-width: 800px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        justify-content: center;
        height: 313px;

        .grid-item {
          border-radius: 20px;
          border: 1px solid var(--base-20, #e3e3e3);
          background: var(--base-0, #fff);
          padding: 20px;
          text-align: center;
          height: 384px;

          img {
            margin-bottom: 15px;
          }

          .grid-item-bottom {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h3 {
              color: #007fff;
              text-align: center;
              font-family: Inter;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 125%;
              /* 30px */
            }

            p {
              color: var(--base-80, #626262);
              text-align: center;
              font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              margin-top: 11px;
              width: 208px;
            }
          }
        }
      }
    }
  }

  .main-frame {
    display: flex;
    flex-direction: column;
    gap: var(--gap2x);
    width: 100%;
    align-items: center;
    overflow: hidden;

    .frame-wrapper {
      max-width: 1440px;
      width: 100%;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--gap3x);
      position: relative;
      padding-top: 60px;

      .frame-left {
        position: absolute;
        left: 0;
        top: -100px;
        z-index: 0;
      }

      .frame-right {
        position: absolute;
        right: 0;
        top: -200px;
        z-index: 0;
      }

      h2 {
        color: var(--btnBgBlue);
        text-align: center;
        font-size: 35px;
        max-width: 100%;
        font-weight: 600;
        margin-bottom: 15px;
        z-index: 1;
      }

      span {
        color: #4f4f4f;
        text-align: center;
        font-size: 20px;
        max-width: 1100px;
        font-weight: 400;
        z-index: 1;
      }

      p {
        color: #4f4f4f;
        text-align: center;
        font-size: 20px;
        max-width: 1100px;
        font-weight: 400;
        z-index: 1;
      }

      .iframe-wrapper {
        display: flex;
        justify-content: center;
        max-width: 613px;
        width: 100%;

        .iframe-container {
          position: relative;
          overflow: hidden;
          width: 100%;
          padding-top: 56.25%;
          /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

          .iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border: none;
            border-radius: var(--buttonBorderRadius);
          }
        }
      }

      button {
        margin-bottom: var(--gap2x);
      }
    }
  }

  .main-info {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap5x);
    padding: 40px 20px;

    h2 {
      font-size: 40px;
      line-height: 45px;
      color: var(--btnBgBlue);
      font-weight: 600;
      text-align: center;

      span {
        font-weight: 800;
        color: inherit;
        font-size: inherit;
        line-height: inherit;
      }
    }

    .info-container {
      display: flex;
      justify-content: center;
      gap: var(--gap5x);
      margin: 0 60px;

      .step-header {
        width: 100%;
        text-align: center;
        h2 {
          font-size: 30px;
        }
        p {
          text-align: center;
          font-size: 17px;
        }
      }

      &:nth-child(odd) {
        flex-direction: row;
      }

      &:nth-child(even) {
        flex-direction: row-reverse;
      }

      .info-image {
        width: 100%;
        display: flex;

        img {
          width: 100%;
          object-fit: contain;
          object-position: center;
          border-radius: var(--inputBorderRadius);
        }
      }
      .last {
        width: 60%;
      }

      .domestic-select {
        max-width: 1400px;
      }

      .info {
        max-width: 621px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0px 30px 30px 30px;
        flex-direction: column;
        margin-top: 35px;

        .info-buttons {
          display: flex;
          gap: 20px;
          margin-top: 20px;
          flex-wrap: wrap;
        }

        h2 {
          color: var(--btnBgBlue);
          text-align: left;
          font-size: 35px;
          font-weight: 600;
          font-style: normal;
          margin-bottom: var(--gap2x);
          line-height: 47.5px;
        }

        p {
          color: #4f4f4f;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 35px;

          span {
            color: var(--mavi, #007fff);
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            text-decoration-line: underline;
          }
        }
      }
    }

    .info-container-brief {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 40px;
      .info-container {
        p {
          font-size: 20px;
          color: #4f4f4f;
          @media (max-width: 468px) {
            font-size: 16px;
            line-height: 22px;
          }
          span {
            font-size: 20px;
            color: var(--btnBgBlue);
            font-weight: 600;
          }
        }
        .info-image-small {
          @media (max-width: 468px) {
            text-align: center;
            width: 100%;
          }
        }
      }
    }
  }

  .main-integration {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap5x);
    padding: 20px;

    .integration-wrapper {
      display: flex;
      flex-direction: column;
      gap: var(--gap2x);
      justify-content: center;
      margin: auto;
      width: 100%;

      h2 {
        font-size: 35px;
        line-height: 45px;
        color: var(--btnBgBlue);
        text-align: center;
        font-weight: 600;
        letter-spacing: -1.33px;
        font-style: normal;
        margin-bottom: 15px;
      }

      p {
        font-size: 20px;
        margin-bottom: var(--gap4x);
        text-align: center;
        color: #4f4f4f;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .integration-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: var(--gap4x);
        justify-items: center;
      }

      .last {
        margin-top: 25px;
        gap: var(--gap4x) !important;
      }
    }

    .info-container {
      .info {
        .info-buttons {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          margin-top: 20px;

          a {
            width: 40%;
          }
        }
      }
    }
  }

  .policies {
    display: flex;
    flex-direction: column;
    gap: var(--gap2x);
    padding: 20px;
    max-width: 1440px;
    width: 100%;

    article {
      display: flex;
      flex-direction: column;
      gap: var(--gap);

      h1 {
        font-size: 35px;
        line-height: 40px;
        color: var(--btnBgBlue);
        text-align: center;
        margin-bottom: var(--gap);
      }

      h4 {
        font-size: 20px;
        line-height: 25px;
        color: var(--btnBgBlue);
        text-align: left;
      }

      h5 {
        font-size: 16px;
        line-height: 20px;
        color: var(--btnBgBlue);
        text-align: left;
      }

      p {
        font-size: 16px;
        color: var(--priceText);
        text-align: left;
      }

      ul {
        margin-left: 20px;
        color: var(--priceText);

        li {
          margin-top: 5px;
          font-size: inherit;
          color: inherit;
        }
      }

      .company-info {
        span {
          font-weight: 700;
        }

        a {
          font-size: inherit;
          color: inherit;
          font-weight: inherit;
        }
      }
    }
  }

  .pop-up {
    position: sticky;
    display: flex;
    gap: 10px;
    z-index: 9999;
    left: 5%;
    bottom: 5%;
    background-color: #000000c2;
    height: fit-content;
    padding: 25px;
    width: 80%;
    
    .pop-up-main {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      height: fit-content;
      width: 100%;
      .pop-up-article {
        width: 70%;
        .pop-up-article-header {
          p {
            font-size: 18px;
            color: #ffff;
            margin-bottom: 5px;
          }
        }
        .pop-up-article-info {
          p {
            font-size: 16px;
            color: #ffff;
            font-weight: 200;
          }
        }
      }
      .pop-up-buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 135px;
        .pop-up-button-accept {
          padding: 8px 15px;
          background-color: #007fff;
          border: none;
          color: #ffff;
          border-radius: 10px;
          cursor: pointer;
          &:hover {
            background-color: rgb(36, 145, 255);
            transition: opacity 0.5s ease-in-out;
          }
        }
        .pop-up-button-cookies {
          padding: 8px 15px;
          background-color: none;
          border: none;
          color: #ffff;
          border-radius: 10px;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: 780px) {
    .my-masonry > div {
      align-items: center !important;
    }
    .pop-up {
      width: 100%;
      left: 0;
      bottom: 0;
      height: 20vh;
      .pop-up-main {
        .pop-up-article {
          
          .pop-up-article-header {
            p {
              font-size: 12px !important;
              color: #ffff;
              margin-bottom: 5px;
            }
          }
          .pop-up-article-info {
            p {
              font-size: 10px !important;
              color: #ffff;
              font-weight: 200;
            }
          }
        }
        .pop-up-buttons {
          display: flex;
          flex-direction: column;
          height: fit-content;
          gap: 10px;
          width: 120px;
          .pop-up-button-accept {
            padding: 8px 15px;
            background-color: #007fff;
            border: none;
            color: #ffff;
            border-radius: 10px;
            font-size: 10px;
            cursor: pointer;
            &:hover {
              background-color: rgb(36, 145, 255);
              transition: opacity 0.5s ease-in-out;
            }
          }
          .pop-up-button-cookies {
            padding: 8px 15px;
            font-size: 10px;
            background-color: none;
            border: none;
            color: #ffff;
            border-radius: 10px;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .my-masonry {
      gap: 65px !important;
    }
  }

  .main-slider {
    max-width: 1440px;
    width: 100%;
    padding: 20px;
    background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    position: relative;

    @keyframes fadeInRight {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    @keyframes fadeOutRight {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }

    .my-animation-in {
      animation: fadeInRight 0.5s forwards;
    }

    .my-animation-out {
      animation: fadeOutRight 0.3s forwards;
    }

    .my-masonry {
      height: 100% !important;
    }

    .my-masonry-closed {
      height: 660px;
      overflow: hidden;
    }

    .my-masonry > div {
      width: 282px;
    }

    .mansory-bottom {
      background-image: url(../../img//v2_logos/monsarybottom.png);
      width: 100%;
      height: 579px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .slider-box {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
      margin-top: 20px;
      width: 94%;
      padding: 16px 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .bottom-field {
        display: flex;
        flex-direction: row;

        align-items: center;
        gap: 12px;

        img {
          border-radius: 50%;
          width: 40px;
          height: 40px;
        }

        .right-field {
          display: flex;
          flex-direction: column;
          text-align: left;

          h3 {
            color: var(--base-100, #2c3131);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%;
          }

          p {
            color: var(--base-60, #a9a9a9);
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 170%;
          }
        }
      }

      .content {
        flex-grow: 1;
        text-align: left;
        align-self: stretch;
        color: var(--base-80, #626262);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
      }
    }

    .slider-headline {
      display: flex;
      justify-content: center;
      margin-bottom: var(--gap4x);
      padding: 30px 0px 50px;

      h2 {
        color: var(--btnBgBlue);
        font-size: 40px;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        line-height: 45px;
        letter-spacing: -1.33px;
      }

      .slider-buttons {
        display: flex;
        gap: var(--gap);

        button {
          background-color: var(--btnBgBlue);
          text-transform: none;
          color: var(--lighterBg);
          transition: all 150ms linear;
          border-radius: 50%;
          border: 1px solid var(--btnBgBlue);
          min-width: 0;
          width: 45px;
          height: 45px;

          svg {
            color: var(--lighterBg);
            transition: all 150ms linear;
          }

          &:hover {
            background-color: var(--lighterBg);
            color: var(--btnBgBlue);
            border: 1px solid var(--btnBgBlue);
            transition: all 150ms linear;

            svg {
              color: var(--btnBgBlue);
              transition: all 150ms linear;
            }
          }
        }

        .prev-slide-button {
          transform: rotate(180deg);
        }
      }
    }

    .mySwiper {
      padding: 5px 5px 50px;
      background-color: var(--lighterBg);

      .slider-swiper-slide {
        background-color: var(--lighterBg);

        .slider-swiper-slide-box {
          background-color: var(--lighterBg);
          padding: 10px;
          border-radius: var(--wrapperBorderRadius);
          display: flex;
          justify-content: center;
          align-items: center;
          height: 180px;
          font-size: 20px;
          font-weight: 200;
          box-shadow: var(--shadow);
        }
      }
    }
  }

  .main-blog {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap2x);
    padding: 20px;

    h2 {
      color: var(--btnBgBlue);
      text-align: center;
      font-size: 40px;
      line-height: 45px;
      font-weight: 600;
    }

    p {
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      font-weight: 300;
    }

    .blog-container {
      display: grid;
      grid-template-columns: repeat(3, 352px);
      justify-items: center;
      gap: var(--gap2x);
      height: 400px;

      .blog {
        padding: 15px;
        display: flex;
        flex-direction: column;
        border: 1px solid var(--inputBorderColor);
        border-radius: var(--wrapperBorderRadius);
        gap: var(--gap2x);

        img {
          width: 100%;
          border-radius: var(--wrapperBorderRadius);
          object-fit: contain;
          object-position: center;
        }

        .blog-text {
          display: flex;
          flex-direction: column;
          gap: var(--gap);
          height: 100px;
          justify-content: space-between;
          margin-left: 11px;

          blockquote {
            color: #959fb5;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
          }

          p {
            text-align: start;
            color: #061027;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 34px;
            /* 141.667% */
            letter-spacing: -0.9px;
          }

          a {
            text-decoration: none;
            display: flex;
            align-items: center;
            transition: all 150ms linear;
            gap: var(--gap);
            font-size: 16px;
            color: #0067ff;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;

            &:hover {
              color: var(--info);
              transition: all 150ms linear;
            }
          }
        }
      }
    }
  }

  .main-contact {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap4x);
    padding: 20px;

    .contact-header {
      display: flex;
      flex-direction: column;
      gap: var(--gap2x);

      h1 {
        font-size: 35px;
        line-height: 35px;
        font-weight: 600;
        padding-top: 55px;
        color: var(--btnBgBlue);
        text-align: center;
        margin-bottom: 15px;
      }

      span {
        font-size: 20px;
        line-height: 24.2px;
        font-weight: 400;
        text-align: center;
        color: var(--priceText);
        width: 100%;
      }

      p {
        font-size: 20px;
        line-height: 24.2px;
        font-weight: 400;
        text-align: center;
        color: var(--priceText);
        width: 100%;
      }
    }

    .contact-clock {
      display: flex;
      flex-direction: column;
      gap: var(--gap2x);
      background-color: var(--btnBgBlue);
      color: var(--lighterBg);
      width: 100%;
      box-shadow: var(--shadow);
      border-radius: var(--wrapperBorderRadius);
      padding: 30px;

      h4 {
        font-size: 32px;
        text-align: center;
      }

      .clock-items {
        display: flex;
        justify-content: center;
        gap: var(--gap2x);

        .clock {
          font-size: 16px;
          font-weight: 500;
        }

        .clock-divider {
          background-color: var(--lighterBg);
          height: 20px;
          width: 1px;
        }
      }
    }

    .contact-form {
      display: flex;
      gap: var(--gap2x);
      width: 100%;

      .form-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--gap2x);
        max-width: 600px;
        width: 100%;

        .form-header {
          display: flex;
          flex-direction: column;
          gap: var(--gap2x);

          h4 {
            font-weight: 600;
            font-size: 30px;
            line-height: 36px;
            color: var(--btnBgBlue);
          }

          span {
            color: var(--priceText);
            font-size: 20px;
            line-height: 24.2px;
            font-weight: 400;
          }
        }

        .form-container {
          display: flex;
          flex-direction: column;
          gap: var(--gap2x);

          .form-item {
            display: flex;
            gap: var(--gap2x);
          }
        }
      }

      .form-iframe {
        width: 100%;
        height: 500px;
        border: 0;
        box-shadow: var(--shadow);
        border-radius: var(--wrapperBorderRadius);
      }
    }

    .contact-info {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      align-items: flex-start;
      width: 100%;
      gap: var(--gap2x);
      margin-top: 30px;
      margin-bottom: 30px;

      a {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: var(--gap);
        box-shadow: var(--shadow);
        height: 168px;
        border-radius: var(--wrapperBorderRadius);
        padding: 20px;
        color: inherit;
        text-decoration: none;
        transition: all 150ms linear;

        &:hover {
          box-shadow: 0px 0px 10px #007fff5c;
          transition: all 150ms linear;
        }

        img {
          height: 50px;
        }

        span {
          width: 100%;
          font-size: 16px;
          color: #667085;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  .main-price {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap2x);
    padding: 20px;

    .price-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--gap2x);
      padding-top: 55px;

      h1 {
        color: var(--btnBgBlue);
        text-align: center;
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 15px;
      }

      span {
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        font-weight: 400;
        color: var(--priceText);
        width: 100%;
      }

      p {
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        font-weight: 400;
        color: var(--priceText);
        width: 100%;
      }
    }

    .price-form {
      display: flex;
      gap: var(--gap2x);
      width: 100%;

      img {
        width: 100%;
        max-width: 600px;
      }

      form {
        background-color: var(--lighterBg);
        border-radius: var(--wrapperBorderRadius);
        box-shadow: var(--shadow);
        display: flex;
        flex-direction: column;
        gap: 13px;
        height: -moz-fit-content;
        height: -webkit-fit-content;
        height: fit-content;
        margin: auto;
        padding: 20px 24px 10px 24px;
        width: 100%;
        max-width: 900px;

        .form-item {
          display: flex;
          gap: var(--gap2x);
          align-items: center;
        }
      }
    }
  }

  .main-price-get-card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(263px, 1fr));
    gap: var(--gap2x);
    margin-bottom: var(--gap2x);
    max-width: 1440px;
    border-radius: 10px;
    border: 1px solid #e0dfde;

    .get-card-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: var(--lighterBg);
      box-shadow: var(--shadow);
      padding: 10px;
      border-radius: var(--inputBorderRadius);
      transition: all 150ms linear;

      &.selected {
        box-shadow: var(--blueShadow);
      }

      &:hover {
        transition: all 150ms linear;
        box-shadow: var(--blueShadow);
      }

      .get-card {
        display: grid;
        align-content: space-between;
        color: var(--priceText);
        gap: var(--gap);

        .get-card-header {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .get-card-header-headline {
            display: flex;
            align-items: center;
            gap: var(--gap);
            font-weight: 600;
          }

          .get-card-header-subtext {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 500;

            span {
              font-weight: 700;
            }
          }
        }

        .get-card-main {
          .get-card-main-price-wrapper {
            display: flex;
            flex-direction: column;
            gap: var(--gap);

            .get-card-main-price {
              color: var(--info);

              .main-price-out {
                font-size: 36px;
                font-weight: 500;
              }

              .main-price-in {
                font-size: inherit;
                color: var(--priceText);
              }
            }
          }

          .shipmentPrice-get-insurance {
            padding: 10px 0 10px 10px;

            label {
              span {
                padding: 3px;
              }
            }
          }
        }
      }
    }
  }

  .main-get-finish {
    display: flex;
    position: relative;
    width: 100%;

    justify-content: center;

    .insider {
      display: flex;
      flex-direction: row;
      max-width: 1440px;
      position: relative;
      width: 100%;
      justify-content: center;
      margin-bottom: -70px;

      .get-finish-headline {
        max-width: 576px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--gap3x);
        justify-content: center;
        padding-bottom: 55px;

        h6 {
          font-size: 36px;
          line-height: 38px;
          color: #007fff;
          font-weight: 600;
          margin-bottom: 15px;
        }

        p {
          font-size: 20px;
          color: #4f4f4f;
          font-family: Inter;
          font-weight: 400;
          line-height: 30px;
        }

        .headline-links {
          display: flex;
          align-items: center;
          gap: var(--gap2x);
        }
      }

      .get-finish-mockup {
        display: flex;
        position: relative;
        width: 100%;
        max-width: 800px;
        padding-top: 75px;
        

        .get-finish-mockup-text-wrapper {
          display: flex;
          flex-direction: column;
          gap: var(--gap);
          max-width: 288px;
          margin-top: 40px;

          .get-finish-mockup-text {
            display: flex;
            align-items: center;
            gap: var(--gap);
            width: 100%;
            padding: 5px;
            // Figma
            border-radius: 8px;
            border: 1px solid var(--white, #fff);
            background: rgba(255, 255, 255, 0.9);

            /* Background blur/md */
            backdrop-filter: blur(8px);
          }
        }

        .get-finish-mockup-img {
          height: 315px;
          position: absolute;
          right: 150px;
          top: 146px;
        }

        .get-finish-laptop {
          width: 100%;
          height: 550px;
          z-index: -99;
        }
      }
    }
  }

  .main-iyziteam {
    max-width: 1440px;
    width: 100%;
    padding: 20px;
    color: var(--priceText);

    display: flex;
    flex-direction: column;
    gap: var(--gap);

    h1 {
      font-size: 30px;
      color: var(--btnBgBlue);
      text-align: center;
    }

    h2 {
      font-size: 35px;
      color: var(--btnBgBlue);
      text-align: center;
    }

    h5 {
      font-size: 30px;
      text-align: center;
      color: var(--btnBgBlue);
    }

    .iyziteam-article {
      display: flex;
      flex-direction: column;
      gap: var(--gap2x);

      p {
        font-weight: 500;
        font-size: 24px;
      }

      .sub-text {
        font-size: 16px;
        font-weight: 400;
      }
    }

    figure {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 650px;
        width: 100%;
      }
    }

    .iyziteam-info {
      background-color: var(--btnBgBlue);
      border-radius: var(--buttonBorderRadius);
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--gap2x);
      margin: 20px 0;

      p {
        font-size: 20px;
        color: var(--lighterBg);
      }
    }

    .iyzi-team-heros {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .iyzi-team-hero {
        display: flex;
        gap: var(--gap);
        justify-content: space-between;
        align-items: center;

        article {
          max-width: 600px;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: var(--gap);

          h6 {
            font-size: 24px;
            color: var(--btnBgBlue);
          }

          p {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .main-about-us {
    max-width: 1440px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: var(--gap);

    h1 {
      font-size: 30px;
      color: var(--btnBgBlue);
      text-align: center;
    }

    h2 {
      font-size: 24px;
      color: var(--btnBgBlue);
      text-align: center;
    }

    article {
      display: flex;
      flex-direction: column;
      gap: var(--gap);

      h3 {
        font-size: 20px;
        color: var(--btnBgBlue);
        text-align: center;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        color: var(--priceText);
        text-align: center;
      }
    }

    .about-us-iyziworld {
      display: flex;
      gap: var(--gap);
      justify-content: space-evenly;
      align-items: flex-start;

      img {
        height: 50px;
      }

      .about-us-iyziworld-here {
        display: flex;
        flex-direction: column;
        gap: var(--gap2x);
        align-items: center;

        .here {
          background-color: var(--btnBgBlue);
          border-radius: var(--wrapperBorderRadius);
          padding: 8px 10px;
          display: flex;
          align-items: center;
          gap: 5px;
          font-weight: 500;
          color: var(--lighterBg);

          div {
            width: 22px;
            height: 22px;
            background-color: var(--shipmentRed);
            border-radius: 50%;
          }
        }
      }
    }
  }

  .sss {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap4x);
    padding: 20px;
    max-width: 640px;
    width: 100%;

    .sss-headline {
      padding-top: 55px;

      h1 {
        color: var(--btnBgBlue);
        font-weight: 600;
        text-align: center;
        font-size: 35px;
        line-height: 45px;
      }
    }

    .sss-search {
      max-width: 640px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: var(--gap2x);

      .sss-search-buttons {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: var(--gap2x);

        button {
          height: 30px;
          text-transform: none;
          font-size: 16px;
          border-radius: var(--wrapperBorderRadius);
        }
      }
    }

    .sss-acciordions {
      display: flex;
      flex-direction: column;
      gap: var(--gap);

      .frame-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--gap3x);

        h2 {
          text-align: center;
          font-size: 40px;
          max-width: 613px;
        }

        span {
          font-size: 16px;
          max-width: 613px;
          font-weight: 300;

          ul {
            display: flex;
            flex-direction: column;
            gap: var(--gap);

            a {
              width: fit-content;
              display: contents;
            }

            li {
              list-style: none;
            }
          }
        }

        .iframe-wrapper {
          display: flex;
          justify-content: center;
          max-width: 613px;
          width: 100%;

          .iframe-container {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 56.25%;
            /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

            .iframe {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
              border: none;
              border-radius: var(--buttonBorderRadius);
            }
          }
        }

        button {
          margin-bottom: var(--gap2x);
        }
      }
    }
  }

  .sss-contact-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .sss-contact {
      max-width: 900px;
      width: 100%;
      background-color: var(--btnBgBlue);
      border-radius: var(--wrapperBorderRadius);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--gap2x);
      padding: 20px;
      margin-top: 30px;
      p {
        font-size: 24px;
        color: var(--lighterBg);
        font-weight: 500;
      }
    }
  }
}

.iyzi-footer {
  background-color: #334155;
  display: flex;
  justify-content: center;
  position: relative;

  .footer {
    max-width: 1440px;
    width: 100%;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    gap: var(--gap4x);

    .footer-mail {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .footer-mail-text {
        display: flex;
        flex-direction: column;

        p {
          &:first-child {
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            color: #007fff;
          }

          &:last-child {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: #fff;
          }
        }
      }

      .footer-mail-form {
        display: flex;
        align-items: center;
        gap: var(--gap2x);

        .footer-mail-form-field {
          background-color: var(--lighterBg);
          border-radius: var(--wrapperBorderRadius);
          width: 280px;
        }

        button {
          min-width: 108px;
          height: 37px;
        }
      }
    }

    .footer-divider {
      width: 100%;
      height: 1px solid var(--inputBorderColor);
    }

    .footer-links {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: var(--gap2x);
      align-items: baseline;

      .footer-gruop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: self-start;
        gap: 12px;

        h3 {
          color: #007fff;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          margin-bottom: 4px;
        }

        .bottom-links {
          color: #fff;
          text-decoration: none;
        }

        .flex {
          display: flex;
          margin-bottom: 6px;
          margin-top: 12px;
          cursor: context-menu;
        }

        .iletisim {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }

      .links-accordion {
        background-color: transparent;
        box-shadow: none;
        margin: 0;
        min-height: 30px;

        .accordion-summary {
          .MuiAccordionSummary-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            border-bottom: 1px solid var(--inputBorderColor);

            .accordion-title {
              font-size: 16px;
              font-weight: 700;
            }
          }
        }

        .links-accordion-details {
          display: flex;
          flex-direction: column;
          gap: var(--gap);

          a {
            font-size: 14px;
            font-weight: 300;
            text-decoration: none;
            color: inherit;
            cursor: pointer;

            &:hover {
              color: var(--btnBgBlue);
              transition: all 150ms linear;
            }
          }
        }
      }
    }

    .footer-copyright {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 12px;
        font-weight: 500;
        color: #fff;
      }
    }
  }
}

@media (max-width: 1405px) {
  .iyzi-main {
    .main-ishowitwork-frame {
      .grid-container-wrapper {
        .background {
          position: absolute;
          width: 100%;
          height: 435px;
          background-color: #f9f9f9;

          z-index: -1;
        }

        .grid-container {
          display: grid;
          grid-template-columns: repeat(auto-fit, 300px);
          height: auto;
        }
      }
    }

    .main-price-get-card {
      max-width: 1400px;
    }
  }
}

@media (max-width: 1420px) {
  .main-ishowitwork-frame {
    .ishowitwork-frame {
      .button-container {
        margin-top: 40px !important;
        margin-bottom: -100px !important;
      }
    }
  }
}

@media (max-width: 1320px) {
  .iyzi-main {
    .main-info {
      .info-container {
        flex-direction: column;
        align-items: flex-start;
        margin: 0 20px;

        .info {
          max-width: 529px;
          width: 100%;
          padding: 0px 20px 20px 20px;
          margin-top: 20px;
          height: 414px;

          h2 {
            font-size: 40px;
            line-height: 40px;
            text-align: left;
          }

          p {
            font-size: 20px;
            line-height: 24px;
            text-align: left;
          }
        }
      }
    }

    .main-price-get-card {
      max-width: 1300px;
    }
  }
}

@media (max-width: 1226px) {
  .iyzi-main {
    .main-header {
      .field {
        gap: 90px;
      }

      .down-btn {
        margin-top: 20px;
      }
    }

    .main-price-get-card {
      max-width: 1100px;
    }

    .main-get-finish {
      .insider {
        padding: 20px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .iyzi-main {
    .main-price-get-card {
      max-width: 900px;
    }

    .main-price {
      .price-header {
        span {
          font-size: 14px;
          line-height: 15px;
        }
      }
    }

    .main-get-finish {
      margin-bottom: -90px;

      .insider {
        align-items: center !important;
        justify-content: center;
        flex-direction: column !important;
        margin-bottom: 0px !important;

        .get-finish-headline {
          margin-bottom: 20px;
          padding-bottom: 0;

          .headline-links {
            justify-content: center;
          }
        }

        .get-finish-mockup {
          .get-finish-laptop {
            height: 550px;
          }
          
        }
      }
    }

    .main-iyziteam {
      .iyzi-team-heros {
        .iyzi-team-hero {
          flex-direction: column;

          &:nth-child(even) {
            flex-direction: column-reverse;
          }

          article {
            max-width: 100%;
          }

          img {
            height: 300px;
          }
        }
      }
    }
  }
}

@media (max-width: 1150px) {
  .iyzi-main {
    .main-blog {
      .blog-container {
        gap: var(--gap2x);
        grid-template-columns: repeat(3, 200px);
        height: 258px;

        .blog {
          .blog-text {
            blockquote {
              font-size: 10px;
            }

            p {
              font-size: 13px;
              line-height: 15px;
            }

            a {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1135px) {
  .iyzi-main {
    .main-header {
      .field {
        .main-header-text {
          h1 {
            font-size: 30px;

            .constant-text {
              font-size: 30px;
            }
          }

          p {
            font-size: 18px;
          }
        }
      }
    }
  }

  .main-info {
    gap: 0;

    .info-container {
      display: flex;
      margin: 0 20px;
      align-items: flex-start;

      .info {
        max-width: none;
        width: 100%;
        padding: 0px 20px 55px 20px;
        height: 314px;

        h2 {
          font-size: 30px;
          line-height: 37px;
          text-align: left;
        }

        p {
          text-align: left;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }
      }
    }
  }
}

@media (max-width: 1076px) {
  .iyzi-header {
    .iyzi-header-links-wrapper {
      .iyzi-header-links {
        .header-link-group {
          gap: var(--gap2x);
        }
        
      }
    }
  }

  .iyzi-main {
    .main-get-finish {
      .insider {
        .get-finish-headline {
          gap: 10px !important;

          h6 {
            font-size: 33px;
          }

          p {
            font-size: 19px;
          }
        }
      }
    }
  }
}

@media (max-width: 1070px) {
  .iyzi-main {
    .main-contact {
      .contact-clock {
        .clock-items {
          flex-direction: column;

          .clock-divider {
            width: 100%;
            height: 1px;
          }
        }
      }
    }

    .main-header {
      .field {
        gap: 70px;
      }

      .down-btn {
        margin-top: 66px;
      }
    }
  }
}

@media (min-width: 1000px) {
  .iyzi-header {
    .mobile-menu {
      display: none;
    }
  }
}

@media (max-width: 1000px) {
  .iyzi-header {
    .iyzi-header-links-wrapper {
      .iyzi-header-links {
        max-width: 640px;

        .header-link-group {
          display: none;

          &.sign {
            display: none;
          }
        }
        .menu-icon {
          display: flex;
          color: #0067ff;
        }
      }
    }
  }
  .main-info {
    .info-container {
      .last {
        width: 100% !important;
      }
    }
  }

  .iyzi-main {
    .integrationGetStartGap {
      gap: 0;
    }

    .integration-getStart {
      gap: 50px;
    }

    .main-header {
      max-width: 640px;
      flex-direction: column;
      align-items: center;

      .field {
        flex-direction: column;

        .main-header-text {
          max-width: 640px;

          h1 {
            font-size: 30px;
            line-height: 40px;
          }

          p {
            font-size: 14px;
          }

          .main-header-text-buttons {
            justify-content: center;
          }
        }
      }

      .main-header-text {
        p {
          font-size: 14px;
        }
      }

      .down-btn {
        margin-top: 86px;
      }

      .main-header-img {
        max-width: 640px;
      }
    }

    .main-frame {
      .frame-wrapper {
        max-width: 640px;

        .frame-left {
          display: none;
        }

        .frame-right {
          display: none;
        }

        h2 {
          max-width: 640px;
          font-size: 30px;
          line-height: 40px;
        }

        span {
          max-width: 640px;
          font-size: 16px;
          font-weight: 300;
        }

        .iframe-wrapper {
          max-width: 640px;
        }
      }
    }

    .main-info {
      max-width: 640px;

      .info-container {
        &:nth-child(odd) {
          flex-direction: column;
        }

        &:nth-child(even) {
          flex-direction: column;
        }

        .info {
          h2 {
            font-size: 30px;
            line-height: 40px;
            line-height: 35px;
          }

          p {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }

    .main-integration {
      max-width: 572px;

      .integration-wrapper {
        h2 {
          font-size: 30px;
          line-height: 35px;
        }

        p {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: var(--gap2x);
        }
      }
    }

    .main-slider {
      max-width: 640px;

      .slider-headline {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: var(--gap2x);
        padding: 0 20px;
        gap: var(--gap2x);

        h2 {
          text-align: center;
          font-size: 30px;
          line-height: 35px;
        }

        .slider-buttons {
          display: flex;
          justify-content: center;
          gap: var(--gap2x);
          width: 100%;
        }
      }

      .mySwiper {
        padding: 5px 5px 30px;

        .slider-swiper-slide {
          background-color: var(--lighterBg);

          .slider-swiper-slide-box {
            height: 240px;
            font-size: 16px;
          }
        }
      }
    }

    .main-blog {
      max-width: 640px;
      width: 100%;

      h2 {
        font-size: 30px;
        line-height: 35px;
      }

      p {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .sss {
      .sss-headline {
        h1 {
          font-size: 30px;
          line-height: 35px;
        }
      }
    }

    .policies {
      max-width: 640px;
    }
  }

  .iyzi-footer {
    .footer {
      max-width: 640px;

      .footer-mail {
        flex-direction: column;
        gap: var(--gap3x);
        align-items: flex-start;

        .footer-mail-text {
          gap: var(--gap);
        }

        .footer-mail-form {
          gap: var(--gap);
          justify-content: space-between;
        }
      }
    }
  }
}

@media (max-width: 995px) {
  .iyzi-main {
    .main-header {
      .down-btn {
        margin-top: 120px;
      }
    }
  }
}

@media (max-width: 947px) {
  .iyzi-main {
    .main-blog {
      .blog-container {
        grid-template-columns: repeat(3, 270px);
      }
    }
  }
}

@media (max-width: 940px) {
  .iyzi-main {
    .main-contact {
      .contact-info {
        display: flex;
        flex-direction: column;

        a {
          flex-direction: row;
          height: 100px;
          width: 100%;
        }
      }

      .contact-form {
        flex-direction: column;

        .form-wrapper {
          max-width: none;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .main-price {
    .price-form {
      flex-direction: column;
      align-items: center;
    }
  }
}

@media (max-width: 800px) {
  .iyzi-main {
    .main-price-get-card {
      max-width: 600px;
    }
  }
}

@media (max-width: 670px) {
  .iyzi-main {
    .main-price-get-card {
      max-width: 400px;
    }

    .main-iyziteam {
      .iyziteam-info {
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .iyzi-main {
    .main-blog {
      .blog-container {
        grid-template-columns: repeat(1, 1fr);
        height: auto;
      }
    }
  }
}

@media (max-width: 635px) {
  .iyzi-header {
    .top-bg {
      .top-bg-container {
        width: 100%;
        padding: 0px 17px;

        .top-bg-content {
          gap: 13px;
          width: 100%;

          .top-bg-item {
            a {
              display: block;
              font-size: 12px;
            }

            img {
              width: 20px;
              height: auto;
            }
            span {
              display: none;
            }
          }
        }
      }
    }
  }

  .iyzi-main {
    .main-get-finish {
      margin-bottom: 0;

      .insider {
        .get-finish-headline {
          max-width: 450px;

          h6 {
            font-size: 33px;
          }

          p {
            font-size: 19px;
          }
        }
      }
    }

    .main-about-us {
      .about-us-iyziworld {
        flex-direction: column-reverse;
        align-items: center;
        gap: var(--gap2x);

        img {
          height: auto;
          width: 150px;
        }
      }
    }
  }
}

@media (max-width: 615px) {
  .iyzi-main {
    .main-ishowitwork-frame {
      .ishowitwork-frame {
        .grid-container-wrapper {
          .background {
            height: 1400px;
          }
        }
      }
    }

    .main-info {
      gap: var(--gap2x);
      padding-bottom: 50px;

      .info-container {
        // gap: 40px;
        .info {
          max-width: 368px;
          padding-top: 0px;
          margin-top: 0px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .iyzi-main {
    .main-header {
      .main-header-text {
        .main-header-text-buttons {
          flex-direction: column;
        }
      }

      .field {
        .main-header-text {
          .main-header-text-buttons {
            flex-direction: column;
          }
        }
      }
    }

    .main-blog {
      .blog-container {
        .blog {
          width: 100%;
        }
      }
    }

    .main-contact {
      .contact-form {
        .form-wrapper {
          .form-container {
            .form-item {
              flex-direction: column;
            }
          }
        }
      }
    }

    .main-ishowitwork-frame {
      .ishowitwork-frame {
        .button-container {
          flex-direction: column;
        }
      }
    }

    .main-get-finish {
      .insider {
        .get-finish-headline {
          .headline-links {
            flex-direction: column !important;
            align-items: inherit;
          }
        }
      }
    }

    .main-iyziteam {
      .iyzi-team-heros {
        .iyzi-team-hero {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  .iyzi-footer {
    .footer {
      .footer-copyright {
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        gap: var(--gap2x);
      }

      .footer-mail {
        .footer-mail-form {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .iyzi-header {
    .iyzi-header-links-wrapper {
      .iyzi-header-links {
        .header-link-group {
          &.sign {
            display: none;
          }
        }
      }
    }
  }

  .iyzi-footer {
    .footer {
      .footer-mail {
        .footer-mail-form {
          .footer-mail-form-field {
            width: 180px;
          }
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .iyzi-main {
    .main-header {
      .main-header-text {
        h1 {
          .integration-text {
            font-size: 31px;
          }
        }
      }
    }

    .main-info {
      align-items: center;

      .info-container {
        gap: 0;

        .info-image {
          margin: auto;
          max-width: 292px;
        }

        .info {
          max-width: 320px;
          margin-top: 20px;
          height: 414px;

          h2 {
            font-size: 20px;
            line-height: 40px;
            line-height: 35px;
          }

          p {
            font-size: 12px;
            line-height: 22px;

            span {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  .main-ishowitwork-frame {
    .ishowitwork-frame {
      max-width: 320px;

      h2 {
        font-size: 30px;
      }
    }

    .grid-container-wrapper {
      .background {
        height: 1108px !important;
        max-width: 320px;
      }

      .grid-container {
        grid-template-columns: repeat(auto-fit, 250px) !important;

        .grid-item {
          img {
            margin-bottom: 15px;
          }

          .grid-item-bottom {
            h3 {
              font-size: 18px;
            }

            p {
              width: 220px !important;
              font-size: 10px;
            }
          }
        }
      }
    }
  }

  .iyzi-footer {
    .footer {
      .footer-mail {
        .footer-mail-form {
          flex-direction: column;
        }
      }
    }
  }
}
