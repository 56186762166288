.country-restrict-info-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .country-restrict-info {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    color: var(--priceText);
    background-color: var(--lighterBg);
    max-width: 800px;
    padding: 20px;
    border-radius: var(--wrapperBorderRadius);
    max-height: 90vh;
    overflow-y: auto;

    h1 {
      font-size: 24px;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: var(--gap2x);
    }

    .country-restrict-areas {
      display: flex;
      flex-direction: column;
      gap: var(--gap);

      figure {
        display: flex;
        align-items: center;
        gap: var(--gap);

        img {
          width: 40px;
          height: 40px;
        }

        .country {
          display: flex;
          align-items: center;

          .title {
            font-weight: 600;
            font-size: 16px;
            width: 240px;
          }

          p {
            font-size: 16px;
          }
        }

        @media (max-width: 650px) {
          .country {
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;
          }
        }

        .others {
          font-size: 16px;

          strong {
            font-size: inherit;
          }
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-top: 10px;
      background-color: var(--pastelBlue);
      border-radius: var(--buttonBorderRadius);
      padding: 10px;

      h5 {
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 5px;

        strong {
          font-size: inherit;
        }
      }

      p {
        font-size: 14px;

        strong {
          font-size: inherit;
        }
      }

      .info-question {
        margin-top: var(--gap2x);
      }
    }
  }
}
