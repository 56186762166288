.general-info-wrapper {
  background-color: var(--background);
  min-height: 100vh;

  .general-info-container {
    padding-bottom: 20px;

    .general-infos {
      display: flex;
      flex-direction: column;
      gap: var(--gap2x);

      .general-info {
        background-color: var(--lighterBg);
        padding: 20px;
        width: 100%;
        border-radius: var(--wrapperBorderRadius);

        .profile-card-wrapper {
          display: flex;
          align-items: center;
          padding: 0;

          .profile-card-header {
            display: flex;
            align-items: center;
            gap: var(--gap);

            .profile-card-header-icon {
              width: 20px;
              height: 20px;
              color: var(--priceText);
            }
          }
        }

        .general-info-collapse {
          display: grid;
          gap: var(--gap3x);
          padding: 0 0 24px 0;
          margin-top: 40px;

          .profile-user-information {
            display: flex;
            flex-direction: column;
            gap: var(--gap3x);

            .profile-user-information-perms {
              display: flex;
              justify-content: space-between;
              gap: var(--gap2x);

              .photo-upload-wrapper {
                display: flex;
                align-items: flex-end;
                gap: var(--gap2x);

                .photo-upload {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  gap: var(--gap2x);

                  .photo-upload-buttonbase {
                    position: relative;
                    width: 130px;
                    height: 130px;
                    cursor: pointer;
                    border-radius: 50%;

                    .photo-upload-avatar {
                      width: 100%;
                      height: 100%;
                    }

                    .photo-edit-icon-wrapper {
                      position: absolute;
                      bottom: 5px;
                      right: 5px;
                      background-color: var(--btnBgBlue);
                      border-radius: 50%;
                      width: 29px;
                      height: 29px;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      .photo-edit-icon {
                        width: 17px;
                        height: 17px;
                        color: var(--lighterBg);
                      }
                    }

                    .photo-upload-icon-wrapper {
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      background-color: rgba(0, 0, 0, 0.2);
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      opacity: 0;
                      border-radius: 50%;
                      transition: opacity 0.3s;

                      &:hover {
                        opacity: 1;
                      }

                      .photo-upload-icon {
                        color: var(--btnBgBlue);
                      }
                    }
                  }
                }

                .photo-upload-buttons {
                  display: flex;
                  align-items: center;
                  gap: var(--gap);
                }
              }

              @media (max-width: 420px) {
                .photo-upload-wrapper {
                  flex-direction: column;
                  align-items: center;
                }
              }

              .profile-options {
                display: flex;
                flex-direction: column;
                gap: var(--gap);

                .profile-id {
                  display: flex;
                  align-items: center;
                  gap: var(--gap);
                  color: var(--btnBgBlue);
                  font-weight: 600;

                  strong {
                    color: var(--darkerTextColor);
                  }
                }
              }
            }

            @media (max-width: 700px) {
              .profile-user-information-perms {
                flex-direction: column;

                .photo-upload-wrapper {
                  justify-content: center;
                }
              }
            }

            .profile-user-inputs-form {
              display: flex;
              flex-direction: column;
              gap: var(--gap2x);

              .profile-user-inputs {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                gap: var(--gap2x);
              }

              @media (max-width: 800px) {
                .profile-user-inputs {
                  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                }
              }
            }

            .profile-user-button {
              display: flex;
              justify-content: flex-end;
              gap: var(--gap);

              span {
                font-size: 13px;
              }
            }
          }

          .info-collapse-checkboxes {
            display: flex;
            flex-direction: row;
            gap: var(--gap);
          }

          .info-collapse-individual {
            .info-collapse-individual-form-wrapper {
              .info-collapse-individual-form {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
                gap: var(--gap3x);

                .individual-form-phone-wrapper {
                  display: grid;
                  align-items: baseline;
                  position: relative;
                  gap: var(--gap);

                  .phone-checked {
                    color: var(--success);
                    text-align: center;
                    position: absolute;
                    right: 15px;
                    top: 10px;
                  }

                  .phone-not-checked {
                    color: var(--error);
                    text-align: center;
                    position: absolute;
                    right: 15px;
                    top: 10px;
                    cursor: pointer;
                  }
                }
              }

              .info-vkn-form {
                box-shadow: var(--shadow);
                padding: 20px;
                border-radius: 10px;
                margin-bottom: 20px;

                .info-vkn-form-inputs {
                  display: grid;
                  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
                  gap: var(--gap3x);
                }

                .info-vkn-form-requst {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                }
              }

              .individual-form-register {
                display: flex;
                justify-content: flex-end;
                margin-top: var(--gap3x);
                width: 100%;

                button {
                  padding: 7px 30px;
                  background-color: var(--btnBgBlue);
                  border-radius: var(--buttonBorderRadius);
                  border: 1px solid var(--btnBgBlue);
                  color: var(--lighterBg);
                  cursor: pointer;
                  transition: all 150ms linear;
                  font-size: var(--smallTextSize);

                  &:hover {
                    background-color: var(--blueBrand);
                    border: 1px solid var(--blueBrand);
                    transition: all 150ms linear;
                  }
                }
              }

              .company-info-wrapper {
                display: grid;
                gap: var(--gap);
                width: 100%;

                .company-info-inputs {
                  display: flex;
                  gap: var(--gap);
                  width: 100%;
                }

                .company-info-dropzone {
                  display: flex;
                  align-items: center;
                  gap: 5px;

                  .info-bold {
                    font-weight: 700;
                    font-size: 11px;
                  }

                  .info-text {
                    color: var(--subtitleDarkBlue);
                    font-weight: 300;
                    font-size: 11px;
                  }
                }
              }
            }
          }
        }

        .general-info-membership {
          display: flex;
          flex-direction: column;
          gap: var(--gap2x);
          margin-top: var(--gap2x);

          .membership-types {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: var(--gap2x);
          }

          @media (max-width: 800px) {
            .membership-types {
              grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .general-info-wrapper {
    .general-info-container {
      .general-infos {
        .general-info {
          padding: 20px;

          .general-info-collapse {
            .info-collapse-individual {
              .info-collapse-individual-form-wrapper {
                .info-collapse-individual-form {
                  gap: var(--gap2x);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .general-info-wrapper {
    .general-info-container {
      .general-infos {
        .general-info {
          .general-info-collapse {
            .info-collapse-individual {
              .info-collapse-individual-form-wrapper {
                .info-collapse-individual-form {
                  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));

                  .individual-form-phone {
                    flex-direction: column;
                    gap: var(--gap2x);

                    .individual-form-phone-area-code {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
