.proforma-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap);

  .details-columns {
    display: grid;
    width: 100%;
    gap: var(--gap2x);

    .details-column {
      display: flex;
      align-items: center;

      .column {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: var(--gap);
      }

      .display {
        margin-left: 10px;
        color: var(--notRed);
        cursor: pointer;
        transition: all 150ms linear;

        &:hover {
          color: var(--error);
          transition: all 150ms linear;
        }
      }

      .displayHide {
        display: none;
      }
    }
  }



  .proforma-add {
    color: var(--info);
    cursor: pointer;
  }
}