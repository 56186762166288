.package-list {
  background-color: var(--lighterBg);
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
  border-radius: var(--inputBorderRadius);
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  min-height: 90vh;
  overflow-y: auto;
  position: relative;
}
.package-list .package-list-info {
  font-size: 13px;
  background-color: var(--pastelBlue);
  border-radius: var(--wrapperBorderRadius);
  color: var(--btnBgBlue);
  padding: 10px;
  box-shadow: var(--shadow);
  margin-right: var(--gap2x);
}
.package-list .package-list-info a {
  font-size: inherit;
  color: var(--btnBgBlue);
}
.package-list .package-list-table-container .package-list-table {
  min-width: 800px;
  overflow-x: auto;
}
.package-list .package-list-table-container .package-list-table .package-list-table-body .package-list-table-body-row .package-list-table-body-row-cell {
  color: var(--text);
}
.package-list .package-list-table-container .package-list-table .package-list-table-body .package-list-table-body-row .package-list-table-body-row-cell .cell-select {
  border-radius: var(--buttonBorderRadius);
}
.package-list .package-list-table-container .package-list-table .package-list-table-body .package-list-table-body-row .package-list-table-body-row-cell .cell-select.disabled {
  background-color: var(--inputBorderColor);
}
.package-list .package-list-table-container .package-list-table .package-list-table-body .package-list-table-body-row .package-list-table-body-row-cell .cell-select.disabled fieldset {
  border-color: var(--inputBorderColor);
}
.package-list .package-list-table-container .package-list-table .package-list-table-body .package-list-table-body-row .package-list-table-body-row-cell input {
  width: 100%;
  outline: none;
  border: 1px solid var(--inputBorderColor);
  border-radius: var(--buttonBorderRadius);
  padding: 5px 10px;
}
.package-list .package-list-table-container .package-list-table .package-list-table-body .package-list-table-body-row .package-list-table-body-row-cell input:disabled {
  background-color: var(--inputBorderColor);
  border-color: var(--inputBorderColor);
}
.package-list .package-list-table-container .package-list-table .package-list-table-body .package-list-table-body-row .package-list-table-body-row-cell input {
  width: 100%;
  outline: none;
  border: 1px solid var(--inputBorderColor);
  border-radius: var(--buttonBorderRadius);
  padding: 5px 10px;
}
.package-list .package-list-table-container .package-list-table .package-list-table-body .package-list-table-body-row .package-list-table-body-row-cell input:disabled {
  background-color: var(--inputBorderColor);
  border-color: var(--inputBorderColor);
}
.package-list .package-list-buttons {
  display: flex;
  justify-content: flex-end;
  gap: var(--gap);
}

@media (max-width: 768px) {
  .package-list .package-inputs {
    border-bottom: 1px solid rgb(199, 199, 199);
    padding-bottom: 10px;
  }
  .package-list .package-inputs-first, .package-list .package-inputs-second {
    display: flex;
    flex-direction: column;
  }
  .package-list .package-inputs-first div, .package-list .package-inputs-second div {
    width: 100%;
  }
  .fast-dimension-modal {
    width: 90%;
    margin: auto;
  }
  .fast-dimension {
    width: 95%;
    max-height: 500px;
    overflow: auto;
  }
  .fast-dimension .fast-dimension-input {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .fast-dimension .fast-dimension-input div {
    width: 140px;
    text-align: center;
  }
  .fast-dimension .form-dimensions-weight {
    flex-direction: column;
    width: 100%;
  }
}/*# sourceMappingURL=PackageList.css.map */