.myShipments {
  min-height: 100vh;
  background-color: var(--background);
}
.myShipments .myShipments-wrapper {
  display: grid;
  width: 100%;
  gap: var(--gap2x);
}
.myShipments .myShipments-wrapper .myShipments-search {
  border-radius: var(--inputBorderRadius);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.myShipments .myShipments-wrapper .myShipments-search .search-card {
  width: 100%;
}
.myShipments .myShipments-wrapper .myShipments-search .search-card .myShipments-detailed-search-wrapper .myShipments-detailed-search-title {
  color: var(--priceText);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.myShipments .myShipments-wrapper .myShipments-search .search-card .myShipments-detailed-search-wrapper .myShipments-detailed-search {
  display: grid;
  grid-template-columns: repeat(5, minmax(200px, 1fr));
  gap: var(--gap3x) var(--gap);
}
.myShipments .myShipments-wrapper .myShipments-search .search-card .myShipments-detailed-search-wrapper .myShipments-detailed-search-done {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: var(--gap2x);
}
.myShipments .myShipments-wrapper .myShipments-search .search-card .myShipments-detailed-search-wrapper .myShipments-detailed-search-done button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: var(--info);
  color: var(--lighterBg);
  transition: all 150ms linear;
  text-transform: none;
  padding: 5px 20px;
}
.myShipments .myShipments-wrapper .myShipments-search .search-card .myShipments-detailed-search-wrapper .myShipments-detailed-search-done button svg {
  width: 20px;
  height: 20px;
}
.myShipments .myShipments-wrapper .myShipments-search .search-card .myShipments-detailed-search-wrapper .myShipments-detailed-search-done button:hover {
  background-color: var(--blueBrand);
  transition: all 150ms linear;
}
@media (max-width: 1375px) {
  .myShipments .myShipments-wrapper .myShipments-search .search-card .myShipments-detailed-search {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
    gap: var(--gap2x) var(--gap) !important;
  }
}
@media (max-width: 1150px) {
  .myShipments .myShipments-wrapper .myShipments-search .search-card .search-card-content {
    flex-direction: column;
  }
  .myShipments .myShipments-wrapper .myShipments-search .search-card .search-card-content .search-wrapper .search-card-content-status {
    width: 100%;
  }
}
@media (max-width: 460px) {
  .myShipments .myShipments-wrapper .myShipments-search .search-card .search-card-content .search-wrapper {
    flex-direction: column;
  }
}
.myShipments .myShipments-wrapper .myShipments-search .search-card .search-card-content {
  display: flex;
  gap: var(--gap);
  padding: 20px;
}
.myShipments .myShipments-wrapper .myShipments-search .search-card .search-card-content .search-card-content-search {
  width: 100%;
}
.myShipments .myShipments-wrapper .myShipments-search .search-card .search-card-content .search-card-content-search label {
  color: var(--priceText);
}
.myShipments .myShipments-wrapper .myShipments-search .search-card .search-card-content .search-card-content-handler-button {
  text-transform: none;
  background-color: var(--info);
  color: var(--lighterBg);
  font-size: 14px;
  width: 200px;
  height: 40px;
}
.myShipments .myShipments-wrapper .myShipments-list {
  display: grid;
  width: 100%;
  gap: var(--gap2x);
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors {
  display: flex;
  justify-content: space-between;
  gap: var(--gap);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  background: var(--lighterBg);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors .list-selectors-position {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors .list-selectors-position .selectors-button {
  background-color: var(--info);
  text-transform: none;
  transition: all 150ms linear;
  color: var(--lighterBg);
  height: 40px;
  border-radius: var(--buttonBorderRadius);
  gap: 5px;
  font-size: 12px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors .list-selectors-position .selectors-button:hover {
  background-color: var(--blueBrand);
  transition: all 150ms linear;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors .list-selectors-position .selector-limit label {
  color: var(--priceText);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors .list-selectors-position .selector-limit svg {
  color: var(--priceText);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors .list-selectors-position .MuiFormGroup-root {
  width: 155px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors .list-selectors-position .MuiFormGroup-root .selector-switch-all .MuiTypography-root {
  font-size: 12px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors .list-selectors-position-wrapper {
  display: flex;
  gap: var(--gap);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors .list-selectors-position-wrapper .list-selectors-position {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors .list-selectors-position-wrapper .list-selectors-position .selectors-button {
  background-color: var(--info);
  text-transform: none;
  transition: all 150ms linear;
  color: var(--lighterBg);
  height: 40px;
  border-radius: var(--buttonBorderRadius);
  gap: 5px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors .list-selectors-position-wrapper .list-selectors-position .selectors-button:hover {
  background-color: var(--blueBrand);
  transition: all 150ms linear;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors .list-selectors-position-wrapper .list-selectors-position .selector-limit label {
  color: var(--priceText);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors .list-selectors-position-wrapper .list-selectors-position .selector-limit svg {
  color: var(--priceText);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper {
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--inputBorderRadius);
  display: flex;
  position: relative;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-picker-wrapper {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-picker-wrapper .list-options {
  border-radius: 50%;
  width: 33px;
  height: 33px;
}
@media (min-width: 1150px) {
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-picker-wrapper .list-options {
    display: none;
  }
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps {
  width: 100%;
  display: grid;
  align-content: space-around;
  gap: var(--gap);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .support-desktop-wrapper {
  display: flex;
  gap: var(--gap2x);
  align-items: center;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .support-desktop-wrapper .support-desktop {
  padding: 10px;
  border-radius: var(--wrapperBorderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: var(--shipmentYellow);
  color: var(--lighterBg);
  gap: 5px;
  width: 100%;
  height: 35px;
}
@media (min-width: 1150px) {
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .support-desktop-wrapper .support-desktop {
    display: none;
  }
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .list-steps-headline {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (min-width: 1150px) {
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .list-steps-headline .list-steps-parcel-ref-no {
    display: none;
  }
}
@media (max-width: 450px) {
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .list-steps-headline {
    padding-top: 30px;
  }
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .to-tracking {
  background-color: var(--background);
  padding: 5px 30px;
  border-radius: var(--buttonBorderRadius);
  font-size: 12px;
  color: var(--priceText);
  font-weight: 700;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-where-to {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: var(--gap);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-where-to .to-integration {
  text-align: center;
  width: 100px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-where-to .to-integration .to-integration-platform {
  height: inherit;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-where-to .to-plane {
  display: grid;
  gap: 5px;
  text-align: center;
  font-size: 13px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-where-to .to-plane .plane-flag {
  display: flex;
  gap: var(--gap);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-where-to .to-plane .plane-flag #land {
  color: var(--shipmentGreen);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-where-to .to-plane .plane-flag #take-off {
  color: var(--error);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .stepper-wrapper-css {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .stepper-wrapper-css .step-wrapper-css {
  z-index: 2;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .stepper-wrapper-css .step-wrapper-css .step-css {
  font-size: 10px;
  padding: 20px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .stepper-wrapper-css .step-wrapper-css .step-css .step-logo-css {
  width: 34px;
  height: 34px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .stepper-wrapper-css .step-wrapper-css .step-css .step-shipment-logo-css {
  width: 54px;
  height: 54px;
}
@media (max-width: 500px) {
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .stepper-wrapper-css .step-css {
    width: 30px !important;
    height: 30px !important;
  }
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .stepper-wrapper-css .step-css .step-logo-css {
    width: 21px !important;
    height: 21px !important;
  }
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .stepper-wrapper-css .step-css .step-shipment-logo-css {
    width: 30px !important;
    height: 30px !important;
  }
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info {
  display: flex;
  justify-content: space-between;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-wrapper {
  display: grid;
  gap: var(--gap);
  max-width: 280px;
  width: 100%;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-wrapper .info-top {
  color: var(--priceText);
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  height: 40px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-wrapper .info-top-mobil {
  display: none;
  color: var(--priceText);
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  height: 40px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-wrapper .info-bottom {
  display: flex;
  gap: var(--gap);
  justify-content: space-between;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-wrapper .info-bottom .info-bottom-subline {
  color: var(--priceText);
  font-size: 12px;
  display: grid;
  text-align: center;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-wrapper .info-bottom .info-bottom-subline .info-bottom-subline-number {
  background-color: var(--background);
  padding: 3px 7px;
  border-radius: var(--buttonBorderRadius);
  font-weight: 700;
  font-size: 12px;
}
@media (max-width: 550px) {
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-wrapper .info-top {
    display: none;
  }
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-wrapper .info-top-mobil {
    display: block;
  }
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-wrapper .info-bottom {
    flex-direction: column;
  }
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-done {
  display: flex;
  color: var(--notRed);
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-done.hidden {
  display: none;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-done .steps-info-done-price {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 12px;
  border: 1px solid;
  padding: 10px 5px;
  border-radius: var(--buttonBorderRadius);
  font-weight: 500;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-done .steps-info-done-price.paid {
  border: 1px solid var(--notGreen);
  color: var(--notGreen);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-done .steps-info-done-price.refund {
  border: 1px solid var(--shipmentYellow);
  color: var(--shipmentYellow);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-done .steps-info-done-button {
  font-weight: 700;
  text-transform: none;
  transition: all 150ms linear;
  display: flex;
  gap: 5px;
  font-size: 12px;
  border-radius: var(--buttonBorderRadius);
  background-color: var(--notRed);
  color: var(--lighterBg);
  padding: 8px 16px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-done .steps-info-done-button.paid {
  background-color: var(--notGreen);
  color: var(--lighterBg);
  padding: 10px 16px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-done .steps-info-done-button.paid:hover {
  background-color: var(--notGreen);
  color: var(--lighterBg);
  padding: 10px 16px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-done .steps-info-done-button.refund {
  border: 1px solid var(--shipmentYellow);
  color: var(--shipmentYellow);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-done .steps-info-done-button:hover {
  background-color: var(--error);
  transition: all 150ms linear;
}
@media (max-width: 1400px) {
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info .steps-info-done {
    display: none;
  }
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info-done {
  display: none;
  color: var(--notRed);
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info-done .steps-info-done-price {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 12px;
  border: 1px solid;
  padding: 10px 5px;
  border-radius: var(--buttonBorderRadius);
  font-weight: 500;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info-done .steps-info-done-price.unpaid {
  border: 1px solid var(--notRed);
  color: var(--notRed);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info-done .steps-info-done-price.paid {
  border: 1px solid var(--notGreen);
  color: var(--notGreen);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info-done .steps-info-done-price.refund {
  border: 1px solid var(--shipmentYellow);
  color: var(--shipmentYellow);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info-done .steps-info-done-button {
  font-weight: 700;
  text-transform: none;
  transition: all 150ms linear;
  display: flex;
  gap: 5px;
  font-size: 12px;
  border-radius: var(--buttonBorderRadius);
  background-color: var(--notRed);
  color: var(--lighterBg);
  padding: 8px 16px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info-done .steps-info-done-button.paid {
  background-color: var(--notGreen);
  color: var(--lighterBg);
  padding: 10px 16px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info-done .steps-info-done-button.paid:hover {
  background-color: var(--notGreen);
  color: var(--lighterBg);
  padding: 10px 16px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info-done .steps-info-done-button.refund {
  border: 1px solid var(--shipmentYellow);
  color: #fff;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info-done .steps-info-done-button:hover {
  background-color: var(--error);
  transition: all 150ms linear;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-note {
  background: var(--background);
  color: var(--priceText);
  padding: 5px 7px;
  font-size: 12px;
  border-radius: 7px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-note span {
  font-size: 12px;
  font-weight: 700;
  color: var(--priceText);
}
@media (max-width: 1400px) {
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-steps .steps-info-done {
    display: flex;
  }
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-links-wrapper {
  display: flex;
  align-items: center;
  min-width: 270px;
  margin: auto;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-links-wrapper .list-links-container {
  display: grid;
  gap: var(--gap2x);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-links-wrapper .list-links-container .list-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-links-wrapper .list-links-container .list-links .passive-link {
  cursor: default;
  opacity: 0.4;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-links-wrapper .list-links-container .list-links .passive-link:hover {
  color: var(--priceText);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-links-wrapper .list-links-container .list-links div {
  font-size: 12px;
  display: flex;
  align-items: center;
  text-transform: none;
  cursor: pointer;
  color: var(--priceText);
  transition: all 150ms linear;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-links-wrapper .list-links-container .list-links div:hover {
  color: var(--info);
  transition: all 150ms linear;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-links-wrapper .list-links-container .list-links-help {
  background-color: var(--shipmentYellow);
  gap: var(--gap);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-links-wrapper .list-links-container .list-links-help svg {
  width: 20px;
  height: 20px;
  color: var(--lighterBg);
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-links-wrapper .list-links-container .list-links-help span {
  color: var(--lighterBg);
  font-weight: 700;
  font-size: 13px;
}
.myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-links-wrapper .list-links-container .list-links-disabled {
  display: none !important;
}
.myShipments .myShipments-wrapper .myShipments-list .list-item-checkbox {
  display: flex;
  align-items: center;
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item {
  display: flex;
  align-items: center;
  gap: var(--gap);
  width: 100%;
  justify-content: space-evenly;
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-supp-message-countries {
  display: flex;
  gap: var(--gap);
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-supp-message-countries .list-item-supp-message {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-supp-message-countries .list-item-supp-message .list-item-supplier .list-item-supplier-img {
  width: 24px;
  height: 24px;
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-supp-message-countries .list-item-supp-message .list-item-message {
  display: flex;
  align-items: center;
  font-size: 13px;
  width: 200px;
  gap: 4px;
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-supp-message-countries .list-item-countries {
  display: flex;
  align-items: center;
}
@media (max-width: 500px) {
  .myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-supp-message-countries {
    flex-direction: column;
    align-items: center;
  }
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons {
  display: flex;
  gap: var(--gap);
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons .list-item-buttons-wrapper {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons .list-item-buttons-wrapper div {
  width: 100px !important;
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons .list-item-buttons-wrapper button {
  width: 120px;
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons .list-item-buttons-navlink {
  width: 160px;
  border-radius: var(--inputBorderRadius);
  background-color: var(--pastelRed);
  color: var(--shipmentRed);
  box-shadow: var(--shadow);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12.25px;
  text-align: center;
  padding: 0 5px;
  transition: all 150ms linear;
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons .list-item-buttons-navlink:hover {
  color: var(--lighterBg);
  background-color: var(--shipmentRed);
  transition: all 150ms linear;
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons .steps-info-done {
  display: flex;
  color: var(--notRed);
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--shadow);
  border-radius: var(--inputBorderRadius);
  padding: 5px 10px;
  width: 255px;
  gap: 5px;
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons .steps-info-done.paid {
  color: var(--shipmentGreen) !important;
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons .steps-info-done.paid button {
  margin-left: 17px;
  width: 181px !important;
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons .steps-info-done.refund {
  color: var(--shipmentYellow);
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons .steps-info-done .steps-info-done-price {
  display: flex;
  align-items: center;
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons .steps-info-done .steps-info-done-price.paid-price {
  color: var(--shipmentGreen);
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons .steps-info-done .steps-info-done-price.refund-price {
  color: var(--shipmentYellow);
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons .steps-info-done .paid {
  font-weight: 700;
  display: flex;
  gap: 5px;
  font-size: 12px;
  border-top-right-radius: var(--wrapperBorderRadius);
  border-bottom-right-radius: var(--wrapperBorderRadius);
  background-color: var(--shipmentGreen) !important;
  color: var(--lighterBg);
  width: 91.25px;
  height: 100% !important;
  align-items: center;
  justify-content: center;
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons .steps-info-done .steps-info-done-button {
  display: flex;
  gap: 5px;
  border-radius: var(--wrapperBorderRadius);
  background-color: var(--notRed);
  color: var(--lighterBg);
  font-weight: 700;
  text-transform: none;
  transition: all 150ms linear;
  width: 150px;
}
.myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons .steps-info-done .steps-info-done-button:hover {
  background-color: var(--shipmentRed);
  transition: all 150ms linear;
}
@media (max-width: 960px) {
  .myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item .list-item-buttons {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 450px) {
  .myShipments .myShipments-wrapper .myShipments-list .wrapper-list-item {
    position: relative;
  }
  .myShipments .myShipments-wrapper .myShipments-list .wrapper-list-item .iyzi-button {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 20;
  }
  .myShipments .myShipments-wrapper .myShipments-list .wrapper-list-item .list-item-checkbox {
    position: absolute;
    top: 5px;
    left: 50px;
    z-index: 20;
  }
  .myShipments .myShipments-wrapper .myShipments-list .wrapper-list-item .listStyle-list-item {
    margin-top: 30px;
  }
  .myShipments .myShipments-wrapper .myShipments-list .wrapper-list-item .list-items-options {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 20;
  }
}
@media (max-width: 940px) {
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors {
    flex-direction: column-reverse;
    gap: var(--gap2x);
  }
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors .list-selectors-position-wrapper {
    justify-content: space-between;
  }
}
@media (max-width: 1270px) {
  .myShipments .myShipments-wrapper .myShipments-list .list-item-checkbox {
    align-items: flex-start;
    padding-top: 5px;
  }
  .myShipments .myShipments-wrapper .myShipments-list .listStyle-list-item {
    flex-direction: column;
  }
}
@media (max-width: 1275px) {
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors .list-selectors-position-wrapper {
    flex-wrap: wrap;
  }
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-selectors .list-selectors-position-wrapper .list-selectors-position {
    flex-wrap: wrap;
  }
}
@media (max-width: 1150px) {
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper {
    flex-direction: column;
  }
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .steps-divider {
    display: none;
  }
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-links-wrapper {
    padding-top: 20px;
  }
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-links-wrapper .list-links-container {
    display: none;
  }
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-links-wrapper .show {
    display: flex !important;
    flex-direction: column;
    width: 100%;
  }
  .myShipments .myShipments-wrapper .myShipments-list .myShipments-list-wrapper .list-links-wrapper .show .list-links {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--gap);
  }
}
.myShipments .myShipments-wrapper .myShipments-list-none {
  margin: 15vh auto;
  font-size: 30px;
  font-style: italic;
}/*# sourceMappingURL=MyShipments.css.map */