.login {
  display: flex;
  background-color: var(--background);
  font-size: var(--smallTextSize);

  .login-sign {
    width: 100%;
    min-height: 100vh;
    padding: 30px 70px;
    display: flex;
    flex-direction: column;
    gap: var(--gap3x);
    max-width: 640px;
    margin: 0 auto;

    .login-sign-container {
      display: flex;
      flex-direction: column;
      gap: var(--gap3x);

      .sign-logo {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 250px;
          width: 100%;
        }
      }

      .sign-choice {
        display: flex;
        justify-content: center;

        .sign-choice-wrapper {
          display: flex;
          gap: calc(var(--gap) / 2);
          max-width: 370px;
          width: 100%;
          padding: 5.5px;
          background-color: var(--lighterBg);
          border-radius: var(--wrapperBorderRadius);
          box-shadow: var(--whiteShadow);
          height: 50px;

          .navLink {
            width: 100%;
            border-radius: var(--buttonBorderRadius);
            background-color: var(--lighterBg);
            outline: 0;
            border: 1px solid transparent;
            color: var(--darkerTextColor);
            font-weight: 700;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            transition: all 150ms linear;
          }

          .active {
            color: var(--lighterBg);
            border-radius: var(--buttonBorderRadius);
            background-color: var(--btnBgBlue);
            transition: all 150ms linear;
          }
        }
      }
    }

    .sign-mail-wrapper {
      width: 100%;

      .sign-mail {
        display: grid;
        gap: var(--gap4x);
        justify-items: end;
        max-width: 554px;
        width: 100%;
        margin: 0 auto;

        .sign-mail-links {
          display: flex;
          justify-content: space-between;
          gap: var(--gap);
          align-items: center;
          margin-top: var(--gap);
          height: 40px;

          &.mail-active {
            justify-content: flex-end;
          }

          .sign-mail-confirmation {
            text-transform: none;
            font-size: 14px;
            border-radius: var(--wrapperBorderRadius);
          }

          .sign-mail-forgot {
            text-align: end;
            color: var(--blueBrand);
            transition: all 150ms linear;

            &:hover {
              color: var(--darkerTextColor);
              transition: all 150ms linear;
            }
          }
        }

        .others-log {
          width: 100%;
          padding: 20px 10px;
          display: flex;
          justify-content: center;
          margin: 0 auto;
          background-color: var(--blueBrand);
          border-radius: var(--inputBorderRadius);
          border: 1px solid transparent;
          color: var(--lighterBg);
          font-weight: 700;
          cursor: pointer;
        }
      }
    }

    .register-wrapper {
      width: 100%;

      .register {
        display: grid;
        gap: var(--gap2x);
        max-width: 554px;
        width: 100%;
        margin: 0 auto;

        .register-item {
          display: flex;
          gap: var(--gap2x);

          .others-log {
            width: 100%;
            padding: 20px 10px;
            display: flex;
            justify-content: center;
            margin: 0 auto;
            background-color: var(--blueBrand);
            border-radius: var(--inputBorderRadius);
            border: 1px solid transparent;
            color: var(--lighterBg);
            font-weight: 700;
            cursor: pointer;

            &:disabled {
              opacity: 0.2;
              cursor: auto;
            }
          }
        }
      }

      @media (max-width: 440px) {
        .register {
          .register-item {
            flex-direction: column;
          }
        }
      }
    }

    .sign-others {
      display: flex;
      flex-direction: column;
      gap: var(--gap3x);
      max-width: 488px;
      width: 100%;
      margin: 0 auto;

      .others-divider {
        display: flex;
        align-items: center;
        gap: var(--gap);

        .others-divider-line {
          background-color: var(--lighterTextColor);
          height: 1px;
          width: 100%;
        }

        .others-divider-text {
          color: var(--lighterTextColor);
          font-size: 12px;
          min-width: 90px;
        }
      }

      .others-sign-in {
        border: 1px solid var(--lighterTextColor);
        border-radius: var(--inputBorderRadius);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 100%;
        gap: var(--gap2x);
        background-color: var(--lighterBg);
        transition: background-color 150ms linear;
        cursor: pointer;

        div {
          width: 220px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: var(--gap);
        }

        &:hover {
          background-color: var(--background);
          transition: background-color 150ms linear;
        }

        span {
          color: var(--darkerTextColor);
          font-weight: 700;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .login {
    flex-direction: column-reverse;

    .login-sign {
      padding: 20px;
    }
  }
}
