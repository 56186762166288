.fast-shipment-search {
  background-color: var(--lighterBg);
  padding: 20px;
  box-shadow: var(--shadow);
  border-radius: var(--wrapperBorderRadius);
  display: flex;
  flex-direction: column;
  gap: var(--gap);

  .fast-shipment-basic-search {
    display: flex;
    gap: var(--gap);
    align-items: center;
    width: 100%;
  }

  .fast-shipment-detailed-search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--gap2x);

    .search-inputs {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      gap: var(--gap2x);
    }
  }
}

@media (max-width: 768px) {
  .fast-shipment-search {
    max-width: 100% !important;
  }
  .fast-shipment-pagination{
    max-width: 100% !important;
    div{
      width: 100%;
      div{
        button{
          width: 100%;
        }
      }
    }
  }

  .fast-shipment-table {
    max-width: 100% !important;
    overflow: auto;
  }
}