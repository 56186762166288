@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter" !important;
  font-size: 16px;
}

:root {
  /** Values */
  --inputBorderRadius: 10px;
  --wrapperBorderRadius: 8px;
  --buttonBorderRadius: 5px;
  --gap: 10px;
  --gap2x: 20px;
  --gap3x: 30px;
  --gap4x: 40px;
  --gap5x: 50px;
  --gap6x: 60px;
  --padding: 10px;
  --padding2x: 20px;
  --largeTextSize: 20px;
  --largeTextHeight: 28px;
  --mediumTextSize: 18px;
  --mediumTextHeight: 25.2px;
  --normalTextSize: 16px;
  --normalTextHeight: 22.4px;
  --smallTextSize: 14px;
  --smallTextHeight: 19.6px;

  /** Light Mode Colors */

  /* System Colors */
  --lighterBg: #fff;
  --background: #f3f6f9;
  --lighterTextColor: #8d93a1;
  --darkerTextColor: #07122f;
  --navActiveLink: #107be6;
  --inputBorderColor: #cacaca;

  /* Shadows */
  --shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  --whiteShadow: 0px 0px 10px rgba(175, 175, 175, 0.3);
  --blueShadow: 0 0 12px 4px #2f81eda6;
  --bluestShadow: 0 0 12px 4px #007fff;

  /* Brand Colors */
  --blueBrand: #003399;
  --redBrand: #e53238;
  --greenBrand: #86b817;
  --orangeBrand: #fca203;

  /* Pastel Colors */
  --pastelBlue: #e5f6fd;
  --pastelGreen: #ecfdf3;
  --pastelRed: #fef3f2;
  --pastelYellow: #fdfd96;

  /* Alert Colors */
  --info: #2f80ed;
  --success: #27ae60;
  --warning: #e2b93b;
  --error: #eb5757;

  /* Font */
  --shipmentLight: #abafb3;
  --shipmentGreen: #71d875;
  --shipmentRed: #e53238;
  --shipmentBlue: #1d8ff7;
  --shipmentYellow: #f5af02;
  --subtitleDarkBlue: #014361;
  --subtitle: #676767;
  --priceText: #6a707e;
  --grayText: #8c8c8c;
  --grayCount: #4f4f4f;
  --text: #58555e;

  /* Button */
  --btnBgBlue: #007fff;
  --adderBtn: #bdbdbd;

  /* Notification Bar Colors */
  --notRed: #eb7062;
  --notPurple: rgb(255, 179, 221);
  --notGreen: #86b817;

  /* Input Background */
  --phoneDigitBg: #e8e8e8;

  /* Other Colors */
  --lightRosy: #fadde5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif;
}

h1 {
  font-size: 56px;
  line-height: 62px;
}

h2 {
  font-size: 48px;
  line-height: 53px;
}

h3 {
  font-size: 40px;
  line-height: 44px;
}

h4 {
  font-size: 32px;
  line-height: 35px;
}

h5 {
  font-size: 24px;
  line-height: 26px;
}

h6 {
  font-size: 20px;
  line-height: 22px;
}

.slider {
  width: 100%;
  background-color: var(--blueBrand);
  height: 100vh;
}

.nav-under div {
  padding: 20px;
  font-size: 16px;
  color: var(--priceText);
  display: flex;
  gap: var(--gap);
}

.member-table tr:last-child td {
  border-bottom: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: spin infinite 20s linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Passive to Active for Members Page */
.passiveToActiveButton {
  position: relative;
  overflow: hidden;
  color: var(--lighterBg) !important;
  background: var(--notRed) !important;
  font-size: 13px;
  padding: 6px 8px;
  text-decoration: none;
  cursor: pointer;
  background: #fff;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border: none;
  border-radius: var(--wrapperBorderRadius);
}

.passiveToActiveButton .text {
  display: flex;
  gap: var(--gap);
  align-items: center;
  font-size: 13px;
}

.passiveToActiveButton span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
}

.passiveToActiveButton span:last-child {
  color: white;
  display: block;
  position: absolute;
  bottom: 0;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translateY(225%) translateX(-50%);
  height: 14px;
  line-height: 13px;
}

.passiveToActiveButton:after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--shipmentGreen);
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  transform: skewY(9.3deg) scaleY(0);
  z-index: 50;
}

.passiveToActiveButton:hover:after {
  transform-origin: bottom center;
  transform: skewY(9.3deg) scaleY(2);
}

.passiveToActiveButton:hover span:last-child {
  transform: translateX(-50%) translateY(-50%);
  opacity: 1;
  transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}

/* Active to Passive for Members Page */
.activeToPassiveButton {
  position: relative;
  overflow: hidden;
  color: var(--lighterBg) !important;
  background: var(--shipmentGreen) !important;
  font-size: 13px;
  padding: 6px 8px;
  text-decoration: none;
  cursor: pointer;
  background: #fff;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border: none;
  border-radius: var(--wrapperBorderRadius);
}

.activeToPassiveButton .text {
  display: flex;
  gap: var(--gap);
  align-items: center;
  font-size: 13px;
}

.activeToPassiveButton span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
}

.activeToPassiveButton span:last-child {
  color: white;
  display: block;
  position: absolute;
  bottom: 0;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translateY(225%) translateX(-50%);
  height: 14px;
  line-height: 13px;
}

.activeToPassiveButton:after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--notRed);
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  transform: skewY(9.3deg) scaleY(0);
  z-index: 50;
}

.activeToPassiveButton:hover:after {
  transform-origin: bottom center;
  transform: skewY(9.3deg) scaleY(2);
}

.activeToPassiveButton:hover span:last-child {
  transform: translateX(-50%) translateY(-50%);
  opacity: 1;
  transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}

/* Current to Passive for Members Page */
.currentToActive {
  position: relative;
  overflow: hidden;
  color: var(--lighterBg) !important;
  background: var(--shipmentYellow) !important;
  font-size: 13px;
  padding: 6px 8px;
  text-decoration: none;
  cursor: pointer;
  background: #fff;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border: none;
  border-radius: var(--wrapperBorderRadius);
}

.currentToActive .text {
  display: flex;
  gap: var(--gap);
  align-items: center;
  font-size: 13px;
}

.currentToActive span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
}

.currentToActive span:last-child {
  color: white;
  display: block;
  position: absolute;
  bottom: 0;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translateY(225%) translateX(-50%);
  height: 14px;
  line-height: 13px;
  font-size: 13px;
  min-width: 150px;
}

.currentToActive:after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--shipmentGreen);
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  transform: skewY(9.3deg) scaleY(0);
  z-index: 50;
}

.currentToActive:hover:after {
  transform-origin: bottom center;
  transform: skewY(9.3deg) scaleY(2);
}

.currentToActive:hover span:last-child {
  transform: translateX(-50%) translateY(-50%);
  opacity: 1;
  transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}

/* Current to Passive for Members Page */
.activeToCurrent {
  position: relative;
  overflow: hidden;
  color: var(--lighterBg) !important;
  background: var(--shipmentGreen) !important;
  font-size: 13px;
  padding: 6px 8px;
  text-decoration: none;
  cursor: pointer;
  background: #fff;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border: none;
  border-radius: var(--wrapperBorderRadius);
}

.activeToCurrent .text {
  display: flex;
  gap: var(--gap);
  align-items: center;
  font-size: 13px;
}

.activeToCurrent span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
}

.activeToCurrent span:last-child {
  color: white;
  display: block;
  position: absolute;
  bottom: 0;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translateY(225%) translateX(-50%);
  height: 14px;
  line-height: 13px;
  font-size: 13px;
  min-width: 150px;
}

.activeToCurrent:after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--shipmentYellow);
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  transform: skewY(9.3deg) scaleY(0);
  z-index: 50;
}

.activeToCurrent:hover:after {
  transform-origin: bottom center;
  transform: skewY(9.3deg) scaleY(2);
}

.activeToCurrent:hover span:last-child {
  transform: translateX(-50%) translateY(-50%);
  opacity: 1;
  transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}
