.address-info {
  background-color: var(--background);
  min-height: 100vh;
  width: 100%;
  border-radius: var(--wrapperBorderRadius);
}
.address-info .profile-card-wrapper {
  display: flex;
  align-items: center;
  padding: 0;
}
.address-info .profile-card-wrapper .profile-card-header {
  display: flex;
  align-items: center;
  gap: var(--gap2x);
}
.address-info .new-address {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
}
.address-info .new-address .new-address-adder-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: var(--gap);
}
.address-info .new-address .new-address-adder-wrapper .new-address-adder {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--info);
  display: flex;
  align-items: center;
  justify-self: flex-end;
  gap: 10px;
  font-weight: 700;
}
.address-info .new-address .new-address-modal {
  opacity: 0;
  transform: translateX(-105%);
}
.address-info .new-address .new-address-modal .new-address-register {
  display: flex;
  gap: var(--gap);
}
.address-info .new-address .address-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
  max-width: 1100px;
  width: 100%;
  margin: auto;
}
.address-info .new-address .address-list .address-list-defaults {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--gap2x);
}
.address-info .new-address .address-list .address-list-defaults .address-list-default {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  box-shadow: var(--shadow);
  border-radius: var(--inputBorderRadius);
  padding: 10px;
}
.address-info .new-address .address-list .address-list-defaults .address-list-default .bill-address {
  border: 1px solid var(--inputBorderColor);
  border-radius: var(--inputBorderRadius);
  padding: var(--gap);
}
.address-info .new-address .address-list .address-list-defaults .address-list-default .bill-address .bill-address-wrapper .address-medium-text {
  font-size: var(--smallTextSize);
  font-weight: 700;
  color: var(--priceText);
  font-size: 12px;
}
.address-info .new-address .address-list .address-list-defaults .address-list-default .bill-address .bill-address-wrapper .address-medium-text span {
  font-weight: 600;
  color: var(--btnBgBlue);
  font-size: 12px;
}
.address-info .new-address .address-list .address-list-defaults .address-list-default .bill-address .bill-address-wrapper .address-medium-text.none {
  display: none;
}
.address-info .new-address .address-list .address-list-defaults .address-list-default .bill-address .bill-address-wrapper .address-small-text {
  font-size: 11px;
  color: var(--priceText);
}
.address-info .new-address .address-list .address-list-defaults .address-list-default .bill-address .bill-address-wrapper .address-small-text span {
  font-weight: 600;
  color: var(--btnBgBlue);
  font-size: 12px;
}
.address-info .new-address .address-list .address-list-defaults .address-list-default .bill-address .bill-address-wrapper .address-small-text.none {
  display: none;
}
.address-info .new-address .address-list .address-list-tables {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
  box-shadow: var(--shadow);
  border-radius: var(--inputBorderRadius);
  padding: 10px;
}
.address-info .new-address .address-list .sender-address {
  width: 100%;
}
.address-info .new-address .address-list .sender-address .sender-address-container {
  border: 1px solid var(--inputBorderColor);
  border-radius: var(--inputBorderRadius);
  padding: var(--gap);
  width: 100%;
}
.address-info .new-address .address-list .sender-address .sender-address-container.default {
  background: rgba(16, 123, 230, 0.04);
}
.address-info .new-address .address-list .sender-address .sender-address-container .address-medium-text {
  font-size: var(--smallTextSize);
  font-weight: 700;
  color: var(--priceText);
  font-size: 13px;
}
.address-info .new-address .address-list .sender-address .sender-address-container .address-medium-text span {
  font-weight: 600;
  font-size: 13px;
  color: var(--btnBgBlue);
}
.address-info .new-address .address-list .sender-address .sender-address-container .address-small-text {
  font-size: 12px;
  color: var(--priceText);
}
.address-info .new-address .address-list .sender-address .sender-address-container .address-small-text span {
  font-weight: 600;
  font-size: 13px;
  color: var(--btnBgBlue);
}
.address-info .new-address .address-list .sender-address .sender-address-container .address-small-text.none {
  display: none;
}
.address-info .new-address .address-list .address-delete {
  color: var(--notRed);
  transition: all 150ms linear;
  cursor: pointer;
}
.address-info .new-address .address-list .address-delete:hover {
  color: var(--error);
  transition: all 150ms linear;
}
.address-info .new-address .address-list .address-delete-disabled {
  color: var(--parcelText);
  opacity: 0.6;
  cursor: auto;
}

.basic-menu-list li {
  display: flex !important;
  justify-content: space-between !important;
  gap: 5px !important;
}
.basic-menu-list li svg {
  height: 22px;
}/*# sourceMappingURL=AddressInfo.css.map */