.new-address-modal {
  display: grid;
  gap: var(--gap3x);
  border: 0 !important;
  outline: 0;
  border-radius: var(--wrapperBorderRadius);

  .new-address-register {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      padding: 7px 30px;
      background-color: var(--btnBgBlue);
      border-radius: var(--buttonBorderRadius);
      border: 1px solid var(--btnBgBlue);
      color: var(--lighterBg);
      cursor: pointer;
      transition: all 150ms linear;
      font-size: var(--smallTextSize);

      &:hover {
        background-color: var(--blueBrand);
        border: 1px solid var(--blueBrand);
        transition: all 150ms linear;
      }
    }
  }

  .new-address-checkboxes {
    display: flex;
    flex-direction: row;
  }

  .new-address-header {
    width: fit-content;
    max-width: 200px;
  }

  .new-address-modal-wrapper {
    display: grid;
    gap: var(--gap2x);
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
