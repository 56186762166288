.company-info-wrapper {
  display: grid;
  gap: var(--gap);
  width: 100%;
}
.company-info-wrapper .company-info-inputs {
  display: flex;
  gap: var(--gap);
  width: 100%;
}
.company-info-wrapper .company-info-dropzone {
  display: flex;
  align-items: center;
  gap: 5px;
}
.company-info-wrapper .company-info-dropzone .info-bold {
  font-weight: 700;
  font-size: 11px;
}
.company-info-wrapper .company-info-dropzone .info-text {
  color: var(--subtitleDarkBlue);
  font-weight: 300;
  font-size: 11px;
}/*# sourceMappingURL=CompanyInfoWrapper.css.map */