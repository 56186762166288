.country-restrict-info-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.country-restrict-info-wrapper .country-restrict-info {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  color: var(--priceText);
  background-color: var(--lighterBg);
  max-width: 800px;
  padding: 20px;
  border-radius: var(--wrapperBorderRadius);
  max-height: 90vh;
  overflow-y: auto;
}
.country-restrict-info-wrapper .country-restrict-info h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: var(--gap2x);
}
.country-restrict-info-wrapper .country-restrict-info .country-restrict-areas {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.country-restrict-info-wrapper .country-restrict-info .country-restrict-areas figure {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.country-restrict-info-wrapper .country-restrict-info .country-restrict-areas figure img {
  width: 40px;
  height: 40px;
}
.country-restrict-info-wrapper .country-restrict-info .country-restrict-areas figure .country {
  display: flex;
  align-items: center;
}
.country-restrict-info-wrapper .country-restrict-info .country-restrict-areas figure .country .title {
  font-weight: 600;
  font-size: 16px;
  width: 240px;
}
.country-restrict-info-wrapper .country-restrict-info .country-restrict-areas figure .country p {
  font-size: 16px;
}
@media (max-width: 650px) {
  .country-restrict-info-wrapper .country-restrict-info .country-restrict-areas figure .country {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
}
.country-restrict-info-wrapper .country-restrict-info .country-restrict-areas figure .others {
  font-size: 16px;
}
.country-restrict-info-wrapper .country-restrict-info .country-restrict-areas figure .others strong {
  font-size: inherit;
}
.country-restrict-info-wrapper .country-restrict-info .info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  background-color: var(--pastelBlue);
  border-radius: var(--buttonBorderRadius);
  padding: 10px;
}
.country-restrict-info-wrapper .country-restrict-info .info h5 {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.country-restrict-info-wrapper .country-restrict-info .info h5 strong {
  font-size: inherit;
}
.country-restrict-info-wrapper .country-restrict-info .info p {
  font-size: 14px;
}
.country-restrict-info-wrapper .country-restrict-info .info p strong {
  font-size: inherit;
}
.country-restrict-info-wrapper .country-restrict-info .info .info-question {
  margin-top: var(--gap2x);
}/*# sourceMappingURL=CountryRestrictInfo.css.map */